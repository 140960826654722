import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Input from "../../components/custom/Input";
import RangeSlider from "../../components/custom/RangeSlider";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine";
import StyledSelect from "../../components/custom/StyledSelect";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { createMultiPartFormBody, isObjectEmpty } from "../../util/Util";
import SweetAlert from "react-bootstrap-sweetalert";

/// <summary>
/// Author: -
/// </summary>
const EditShareholder = (props) => {
  var { t } = useTranslation();
  var _history = useHistory();
  var _dispatch = useDispatch();
  var _location = useLocation();
  var _userData = useSelector((state) => state.authState.userData);

  const [member, setMember] = useState([]);
  const [share, setShare] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [hideSummary, setHideSummary] = useState(true);

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});

  const { register, handleSubmit, errors, setValue, watch, control, trigger } =
    useForm();

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (_location["state"]) {
      init();
    } else {
      _history.goBack();
    }
  }, [_location["state"]]);

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    _dispatch(setBusy());
    try {
      var responseJson = await ApiEngine.get(
        `${ApiUrl._API_GET_MEMBER_DETAILS_BY_ID}?memberId=${_location["state"]["id"]}&shareholder=true`
      );

      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
      setMember(responseJson[ApiKey._API_DATA_KEY]);
      setHideSummary(responseJson[ApiKey._API_DATA_KEY]["hideSummary"]);
      setShare(
        responseJson[ApiKey._API_DATA_KEY]["isShareHolder"] == 1
          ? responseJson[ApiKey._API_DATA_KEY]["sharePercent"]
          : responseJson[ApiKey._API_DATA_KEY]["maxShare"]
      );
    } catch (errorMessage) {
      _dispatch(showResponseMessage(false, errorMessage));
      _history.goBack();
    } finally {
      _dispatch(setIdle());
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  function preSubmit() {
    if (member["isShareHolder"] != 1) {
      setShowConfirmation(true);
    } else {
      handleSubmit(submitForm)();
    }
  }

  /// <summary>
  /// Author: -
  /// </summary>
  async function submitForm() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_SHAREHOLDER,
      createMultiPartFormBody({
        memberId: member["id"],
        share: share,
        hideSummary: hideSummary,
      })
    );

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _history.push({
        pathname: WebUrl._URL_SHAREHOLDER_DETAIL,
        state: {
          id: _location["state"]["id"],
          hideSummary: _location["state"]["memberHideSummary"],
        },
      });
    }
  }

  return (
    <>
      <Header
        history={_history}
        url={
          !window.ReactNativeWebView ? WebUrl._URL_SHAREHOLDER_DETAILS : null
        }
        state={{
          id: _location["state"]["id"],
          hideSummary: _location["state"]["memberHideSummary"],
        }}
        title={
          member["isShareHolder"] == 1
            ? t("EDITSHAREHOLDER")
            : t("ADDSHAREHOLDER")
        }
      />
      <div
        id={
          _userData.selectedTheme === 0
            ? "shareholder-detail"
            : "shareholder-detail-modern"
        }
        className="container mt-3"
        style={{ paddingBottom: "30px" }}
      >
        <div>
          <div className="form-group m-b-0">
            <label className="pl-4 font-weight-bold">{t("USERNAME")}</label>
            <div className="pb-2">
              <span className="pl-4">{member["username"]}</span>
            </div>
          </div>
          <div className="form-group m-b-0">
            <label className="pl-4 font-weight-bold">{t("FULLNAME")}</label>
            <div className="pb-2">
              <span className="pl-4">{member["userFullName"]}</span>
            </div>
          </div>
          <div className="form-group m-b-0">
            <label className="pl-4 font-weight-bold">{t("NICKNAME")}</label>
            <div className="pb-2">
              <span className="pl-4">
                {member["nickname"] == "" ? "-" : member["nickname"]}
              </span>
            </div>
          </div>
        </div>
        <span style={{ display: "none" }}>
          <div className="pl-4">{t("ALIAS")}</div>
          <Input placeholder={t("NO_ALIAS")} />
        </span>

        <form className="margin-bottom-0">
          {showConfirmation && (
            <SweetAlert
              custom={true}
              customIcon={require("../../assets/img/sweetalert_warning.png")}
              showCancel
              confirmBtnText={t("CONFIRM")}
              cancelBtnText={t("CANCEL")}
              cancelBtnCssClass="custom-cancel-btn"
              confirmBtnCssClass="custom-btn"
              confirmBtnStyle={{ boxShadow: "0px 5px 30px #3369ffb3" }}
              title={t(
               "CONFIRM_APPOINT_AS_SHAREHOLDER", { username: member["username"]} 
              )}
              onConfirm={() => {
                setShowConfirmation(false);
                handleSubmit(submitForm)();
              }}
              onCancel={() => {
                setShowConfirmation(false);
              }}
            ></SweetAlert>
          )}
          <div>
            <div className="card-body">
              <RangeSlider
                step={5}
                max={member["maxShare"]}
                min={member["minShare"]}
                value={share}
                label={`${t("SHAREPERCENT")} (%)`}
                onChange={(value) => {
                  setShare(value);
                }}
              />
            </div>
          </div>
          {_userData.hideSummary === false && (
            <div style={{ display: "flex", margin: "10px 20px" }}>
              <div style={{ marginTop: "2px" }}>
                <input
                  type="checkbox"
                  id={"check-for-hideSummary"}
                  className="input-checkbox-custom"
                  defaultChecked={member["hideSummary"]}
                  onClick={(e) => setHideSummary(e.target.checked)}
                />
                {!isObjectEmpty(member) && (
                  <label
                    htmlFor={"check-for-hideSummary"}
                    className="downline-group-checkbox"
                  >
                    <span style={{ position: "relative" }}>
                      <img
                        className="checked-icon"
                        src={
                          _userData.selectedTheme === 0
                            ? require("../../assets/img/icon/custom-checkbox-1-checked.svg")
                            : require("../../assets/img/icon/custom-checkbox-modern-1-checked.svg")
                        }
                      />
                      <img
                        className="unchecked-icon"
                        src={
                          _userData.selectedTheme === 0
                            ? require("../../assets/img/icon/custom-checkbox-1-unchecked.svg")
                            : require("../../assets/img/icon/custom-checkbox-modern-1-unchecked.svg")
                        }
                      />
                    </span>
                  </label>
                )}
              </div>
              <div
                style={{
                  marginLeft: "10px",
                  color: _userData.selectedTheme === 0 ? "#002e6c" : "white",
                }}
              >
                {t("HIDE_SUMMARY")}
              </div>
            </div>
          )}
          <button
            type="button"
            className="btn btn-block btn-brand-gradient"
            onClick={() => preSubmit()}
          >
            {t("SAVE")}
          </button>
        </form>
      </div>
    </>
  );
};

export default EditShareholder;
