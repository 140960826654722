import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import { isNull, map } from "lodash";
import ApiCms from "util/ApiCms";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ApiKey, ApiUrl, WebUrl, SessionKey, LanguageOption } from "../../util/Constant";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine";

const HelpCentre = (props) => {
  const { t, i18n } = useTranslation();
  const { params } = useRouteMatch(WebUrl._URL_HELP_CENTRE_FAQ);
  const { category: Parent_Category } = params;
  const { sub_category: Sub_Category } = params;
  const { faq: Param_faq } = params;

  const [categoryID, setCategoryID] = useState("");
  const [subCategoryID, setSubCategoryID] = useState("");
  const [faqID, setFaqID] = useState("");
  const [scrollTop, setScrollTop] = useState(0);
  const [isShowHelpCentreCategory, setIsShowHelpCentreCategory] =
    useState(false);
  const [breadcrumbs, updateBreadcrumbs] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [faqList, setFaqList] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [parentCategory, setParentCategory] = useState("");
  const [faq, setFaq] = useState("");
  var _dispatch = useDispatch();
  let _history = useHistory();
  const [title, setTitle] = useState("");
  var _userData = useSelector(state => state.authState.userData);
  const [channels, setChannels] = useState([]);

  const onClick = (breadcrumbItems) => {
    updateBreadcrumbs((arr) => [...arr, breadcrumbItems]);
  };

  useEffect(() => {
    const scrollEvent = () => {
      window.addEventListener("scroll", (e) => {
        setScrollTop(e.target.documentElement.scrollTop);
      });
    };
    scrollEvent();

    return () => {
      // window.removeEventListener("scroll");
    };
  }, []);

  useEffect(() => {
    fetchData();
    init();
  }, []);

  async function fetchData() {
    _dispatch(setBusy());

    const { data } = await ApiCms.get(
      "/sub-categories" +
        "?locale=" +
        localStorage.getItem(SessionKey._LANGUAGE)
        +
        "&populate=*"
    );
    const { data: categoriesList } = data;
    setSubCategories(categoriesList);

    {
      categoriesList.length &&
        categoriesList.map((item, index) => {
          if (item.attributes.slug === Sub_Category)
            setSubCategoryID(item.attributes.sub_category_id);
        });
    }

    _dispatch(setIdle());
  }

  useEffect(() => {
    const fetchParent = async () => {
      const { data } = await ApiCms.get(
        "/categories" +
          "?locale=" +
          localStorage.getItem(SessionKey._LANGUAGE)
          +
          "&populate=*"
      );
      const { data: parentCategoriesList } = data;
      setParentCategories(parentCategoriesList);

      {
        parentCategoriesList.length &&
          parentCategoriesList.map((item, index) => {
            if (item.attributes.slug === Parent_Category)
              setCategoryID(item.attributes.category_id);
          });
      }
    };
    fetchParent();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
  localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  useEffect(() => {
    const fetchParent = async () => {
      const { data } = await ApiCms.get(
        "/faqs" +
          "?locale=" +
          localStorage.getItem(SessionKey._LANGUAGE)
          +
          "&populate=*"
      );
      const { data: dataFaq } = data;
      setFaqList(dataFaq);

      {
        dataFaq.length &&
          dataFaq.map((item, index) => {
            if (item.attributes.slug === Param_faq)
            {
              setTitle(item.attributes.Title);
              setFaqID(item.attributes.faq_id);
            }              
          });
      }
    };
    fetchParent();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
  localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  useEffect(() => {
    setSubCategory(subCategoryID);
    setParentCategory(categoryID);
    setFaq(faqID);
  }, [params]);

  async function init() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_COMMUNICATION_CHANNELS + "?all=true&languageCode=" + 
      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en')
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setChannelData(responseJson[ApiKey._API_DATA_KEY]);
    } 

    _dispatch(setIdle());
  }

  function setChannelData(data) {
    var channels = [];
    data.filter((i) => i.channelName.match(/Livechat/i)).map((d, index) => {
      var channel = {
        channelName: d.channelName,
        name: d.channelDescription,
        description: "",
        actionUrl: d.cta,
        image: d.channelImage,
        attachment: d.attachment,
      };

      channels.push(channel);
    });
    setChannels(channels);
  }

  async function GoToCategory(slug, category_id) {
    if (slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_CATEGORY.replace(":category", slug),
        state: {
          category_id: category_id,
        },
      });
    }
  }

  async function GoToSubCategory(slug, sub_slug, category_id, sub_category_id) {
    if (slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_SUB_CATEGORY
          .replace(":category", slug)
          .replace(":sub_category", sub_slug),
        state: {
          category_id: category_id,
          sub_category_id: sub_category_id,
        },
      });
    }
  }

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_HELP_CENTRE}
        title={t(title)}
      />
      <section className="help-centre-section">
        <div className="container" id="help-centre-banner">
          <div className="help-centre-sub-sub-list-container">
            <div className="template-box mt-3 d-flex">
              <div className="col-md-8 col-lg-8 sub-sub-list-template-box bg-darkblue">
                {faqList &&
                  faqList.length &&
                  faqList.map((item, index) => {
                    return (
                      !isNull(item.attributes.publishedAt) &&
                      item.attributes.slug === Param_faq && (
                        <div
                        key={index}
                          className="mt-4"
                          dangerouslySetInnerHTML={{
                            __html: item.attributes.Content,
                          }}
                          style={{ color: _userData.selectedTheme === 0 ? "#002e6c" : "white" }}
                        ></div>
                      )
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="helpcentre-contact container">
          <div className="help-centre-other-ways-container" style={{ color: _userData.selectedTheme === 0 ? "#002e6c" : "white" }}>
            {t("STILL_NEED_HELP")}
          </div>
          <div className="help-centre-other-ways-list-container d-flex justify-content-around">
            {channels.map((channel, index) => {
              return (
                <div key={index} className="help-button" style={{ color: _userData.selectedTheme === 0 ? "#002e6c" : "white" }}
                  onClick={() => { 
                    if (window.ReactNativeWebView) {
                      window.ReactNativeWebView.postMessage(
                        JSON.stringify({
                          action: "chat",
                          url: `${channel.actionUrl}`
                        })
                      );
                    }
                    else {
                      window.open(channel.actionUrl, "_blank", "live-chat");
                    }
                  }}
                >
                  <div className="d-flex justify-content-between help-centre-other-ways-list">
                    <div>
                      <div
                        className="help-centre-other-ways-title"
                        // onClick={() =>
                        //   _history.push({
                        //     pathname: WebUrl._URL_CONTACT_US,
                        //   })
                        // }
                      >
                        {t("LIVE_CHAT")}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}

            <div className="help-button" style={{ color: _userData.selectedTheme === 0 ? "#002e6c" : "white" }}>
              <div
                className="d-flex justify-content-between help-centre-other-ways-list"
                onClick={() =>
                  _history.push({
                    pathname: WebUrl._URL_CONTACT_US,
                  })
                }
              >
                <div>
                  <div className="help-centre-other-ways-title">
                    {t("CONTACT")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HelpCentre;
