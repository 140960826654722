import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { PageSettings } from "../../config/page-settings.js";
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import { map } from "lodash";
import { Modal, ModalBody } from "reactstrap";
import ApiEngine from "../../util/ApiEngine.js";
import { ApiKey, ApiUrl, SessionKey, WebUrl } from "../../util/Constant.js";
import DynamicLoadTable2 from '../../components/custom/DynamicLoadTable2';
import moment from "moment";
import 'moment/locale/en-gb';
import 'moment/locale/th';
import 'moment/locale/zh-cn';
import 'moment/locale/bm';
import 'moment/locale/id';
import 'moment/locale/vi';
import { useTranslation } from "react-i18next";
import Header from '../../components/custom/Header';
import parse from 'react-html-parser';
/// <summary>
/// Author : 
/// </summary>
const Notification = props => {
  const { t, i18n } = useTranslation();
  const _history = useHistory();
  const [updatedNotificationCheck, setUpdatedNotificationCheck] = useState(false);

  useEffect(() => {
    updateMemberNotification();
  }, []);

  const updateMemberNotification = async () => {
    let apiUrl = ApiUrl._API_UPDATE_MEMBER_NOTIFICATION;
    var responseJson = await ApiEngine.get(apiUrl);
    // make sure we run update first before loading table
    setUpdatedNotificationCheck(true);
  }

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_MAIN}
        title={t("NOTIFICATION")}
        hasBackButton={true}
      />
      <div className="notification-list-container pb-5">
        {updatedNotificationCheck && (
        <DynamicLoadTable2
          pageSize={24}
          apiQuery={ApiUrl._API_GET_NOTIFICATION_LIST + "?languageCode=" + localStorage.getItem(SessionKey._LANGUAGE)} //"en"}
          searchEnabled={true}
          autoscrollEnabled={true}
          render={(news, index) => {
            var localLocale = moment(news.date);
            var langCode = localStorage.getItem(SessionKey._LANGUAGE);
            if (langCode === "en") { langCode = "en-gb"; } else if (langCode === "zh") { langCode = "zh-cn"; }
            localLocale.locale(langCode);
              return (
                  <Link
                      to={{
                          pathname: WebUrl._URL_NOTIFICATION_DETAIL,
                          state: { data: JSON.stringify(news) },
                      }}
                  >
              <div className="notification-list-item box-list-with-shadow">
                <div className="first">
                  <div className="custom-icon-box">
                    {!news.isRead ? (
                      <img src={require("../../assets/img/v3/unread.png")} alt="unread" />
                    ) : (
                      <img src={require("../../assets/img/v3/read.png")} alt="read" />
                    )}
                  </div>
                  <div className="notification-content-box">
                    <div className="notification-title mb-2">
                      {parse(news.title)}
                    </div>
                    <div className="notification-details">
                      {parse(news.description)}
                    </div>
                  </div>
                </div>
                <div className="second">
                  <div className="notification-date mb-3">
                    {localLocale.format("DD MMM")}
                  </div>
                  {news.isRead ? (
                    <div className="notification-read"></div>
                  ) : (
                    <div className="notification-unread"></div>
                  )}

                </div>
                      </div>
                  </Link>
            )
          }}
          />
        )}
      </div>
    </>
  );

}

export default Notification;