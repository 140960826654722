import { AlertTypes } from '../util/Constant.js';
import i18n from '../localization/i18n';
import ApiEngine from "../util/ApiEngine.js";
import { ApiUrl, ApiKey } from "../util/Constant";
import { numberWithCurrencyFormat } from "../util/Util";

export const _BIND_MEMBER = "bindMember";
export const _BUSY = 'busy';
export const _IDLE = 'idle';
export const _API_CALLED = 'setApiCalled';
export const _DOWNLINE_SEARCH = 'setDownlineSearchTextCalled';
export const _DOWNLINE_QUERY = 'setDownlineQueryCalled';
export const _GAME_SEARCH = 'setGameSearchTextCalled';
export const _GAME_QUERY = 'setGameQueryCalled';
export const _SET_Y = "setY";
export const _SET_PAGESIZE = "setPageSize";
export const _SET_ACTIVE_TAB = "setActiveTab";
export const _IS_KEEPED_LOGIN = "userKeepLogin";
export const _BANNER_POPUP_MODAL = "bannerPopupModal";
export const _BANNER_POPUP_IMAGE = "bannerPopupImage";


export const _ALERT_TYPES = {
    SET_ALERT: 'SetAlert',
    NONE: 'AlertNone',
}

export const setY = (y) => {
    return (dispatch) => {
        dispatch({
            type: _SET_Y,
            y,
        });
    };
};

export const bindMember = async (dispatch) => {
	let member = [];
	let balance = 0;

	let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
	if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
		dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
		throw responseJson[ApiKey._API_MESSAGE_KEY];
	}

	member = responseJson[ApiKey._API_DATA_KEY];
	balance = isNaN(parseFloat(member["primaryCredit"]), 3) ? "0.00" : numberWithCurrencyFormat(parseFloat(member["primaryCredit"]), 2, true);
	
	dispatch({
		type: _BIND_MEMBER,
		member: member,
		walletBalance: balance,
	});
};

export const setPageSize = (pagesize) => {
    return (dispatch) => {
        dispatch({
            type: _SET_PAGESIZE,
            data: pagesize,
        });
    };
};

export const setActiveTab = (activeTab) => {
    return (dispatch) => {
        dispatch({
            type: _SET_ACTIVE_TAB,
            data: activeTab,
        });
    };
};

export const userKeepLogin = (isKeepedLogin) => {
    return (dispatch) => {
        dispatch({
            type: _IS_KEEPED_LOGIN,
            data: isKeepedLogin,
        });
    };
};

export const setDownlineSearchText = (e) => {
    return async dispatch => {
        dispatch({
            type: _DOWNLINE_SEARCH,
            data: e
        });
    }
}

export const setDownlineQuery = (e) => {
    return async dispatch => {
        dispatch({
            type: _DOWNLINE_QUERY,
            data: e
        });
    }
}

export const setGameSearchText = (e) => {
    return async dispatch => {
        dispatch({
            type: _GAME_SEARCH,
            data: e
        });
    }
}

export const setGameQuery = (e) => {
    return async dispatch => {
        dispatch({
            type: _GAME_QUERY,
            data: e
        });
    }
}

/// <summary>
/// Author : 
/// </summary>
export const setBusy = (iconEnabled, message) => {
    return async dispatch => {
        dispatch({
            type: _BUSY,
            message: message,
            iconEnabled: iconEnabled
        });
    }
}

/// <summary>
/// Author : 
/// this function is to only update user status to logout
/// </summary>
export const setIdle = () => {
    return async dispatch => {
        dispatch({
            type: _IDLE
        });
    }
}

/// <summary>
/// Author : 
/// </summary>
export const setPerformApiCall = (status, url) => {
    return async dispatch => {
        dispatch({
            type: _API_CALLED,
            data: { success: status, url: url }
        });
    }
}

/// <summary>
/// Author: -
/// </summary>
export const showResponseMessage = (status, message, translateRequired = true) => {
    return dispatch => {
        var messageToShow = message;
        if (translateRequired) {
            messageToShow = i18n.t(message);
        }
        dispatch(showMessage({ type: (status ? AlertTypes._SUCCESS : AlertTypes._ERROR), content: messageToShow }));
    }
}

/// <summary>
/// Author: -
/// </summary>
export const showResponseMessageWithoutT = (status, message, translateRequired = true) => {
  return dispatch => {
    var messageToShow = message;
    dispatch(showMessage({ type: (status ? AlertTypes._SUCCESS : AlertTypes._ERROR), content: messageToShow }));
  }
}


/// <summary>
/// Author: -
/// </summary>
export const showMessage = (alertProps) => {
    return dispatch => {
        const customConfirm = alertProps.onConfirm;
        alertProps.onConfirm = async (...args) => {
            disposeMessage()(await dispatch);
            if (customConfirm) {
                customConfirm(...args);
            }
        }

        const customCancel = alertProps.onCancel;
        alertProps.onCancel = async (...args) => {
            disposeMessage()(await dispatch);
            if (customCancel) {
                customCancel(...args);
            }
        }
        alertProps.cancelBtnBsStyle = 'danger';
        alertProps.title = alertProps.title ?? "";
        alertProps.allowEscape = false;
        alertProps.reverseButtons = true;
        dispatch({ type: _ALERT_TYPES.SET_ALERT, alert: alertProps });
    }
}

/// <summary>
/// Author: -
/// </summary>
export const disposeMessage = () => {
    return dispatch => {
        dispatch({ type: _ALERT_TYPES.NONE });
    }
}

export const setBannerPopupModal = (e) => {
  return async dispatch => {
    dispatch({
      type: _BANNER_POPUP_MODAL,
      data: e
    });
  }
}

export const setBannerPopupImage = (e) => {
  return async dispatch => {
    dispatch({
      type: _BANNER_POPUP_IMAGE,
      data: e
    });
  }
}