import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ApiEngine from "../../util/ApiEngine";
import { createFormBody } from "../../util/Util";
import Header from "../../components/custom/Header";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useForm, Controller } from "react-hook-form";
import PinInput from 'react-pin-input';
import { Alert } from 'reactstrap';

const ForgetPin = () => {
    const _ALERT_TIMEOUT = 5000;
    const _COUNTDOWN_PERIOD = 60;
    const _SECONDS_IN_MS = 1000;
    let _history = useHistory();
    const { t, i18n } = useTranslation();
    const _dispatch = useDispatch();
    const { handleSubmit } = useForm();
    const [countdownPeriod, setCountdownPeriod] = useState(0);
    const [sentCode, setSentCode] = useState(false);
    const [pinNumber, setPinNumber] = useState("");
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [failMessage, setFailMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (countdownPeriod > 0) {
            setTimeout(() => {
                let tempPeriod = countdownPeriod - 1;
                setCountdownPeriod(tempPeriod);
            }, _SECONDS_IN_MS);
        }
    }, [countdownPeriod]);

    async function sendPinCodeTest() {
        console.log("test");
        setSentCode(true);
        setCountdownPeriod(_COUNTDOWN_PERIOD);
    }

    async function sendPinCode() {
        let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_IF_PHONE_VERIFIED);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var hpNum = responseJson[ApiKey._API_DATA_KEY];
            let params = {
                "phoneNumber": hpNum
            };
            let smsJson = await ApiEngine.post(ApiUrl._API_SEND_PIN_CODE, createFormBody(params));
            if (smsJson[ApiKey._API_SUCCESS_KEY]) {
                setSentCode(true);
                setCountdownPeriod(_COUNTDOWN_PERIOD);
            }
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    async function onSubmit() {
        _dispatch(setBusy());
        let params = {
            "pinNumber": pinNumber
        };
        let responseJson = await ApiEngine.post(ApiUrl._API_RESET_MEMBER_PIN_NUMBER, createFormBody(params));
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT])
            setTimeout(() => _history.push(WebUrl._URL_RELOAD_TRANSACTION), [_ALERT_TIMEOUT])
        }
        else {
            setFailMessage(responseJson[ApiKey._API_MESSAGE_KEY]);
            setShowFailAlert(true);
            setTimeout(() => setShowFailAlert(false), [_ALERT_TIMEOUT])
            setTimeout(() => _history.push(WebUrl._URL_RELOAD_TRANSACTION), [_ALERT_TIMEOUT])
        }
        _dispatch(setIdle());
    }

    return (
        <>
            {sentCode && <p style={{ textAlign: "center", marginTop: "20px" }}>{t("PIN_SENT")} {t("RESEND_IN")} {countdownPeriod}s</p>}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div id="reset-pin" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", marginTop: sentCode ? "0" : "56px" }}>
                    <PinInput style={{ textAlign: "center" }}
                        length={4}
                        initialValue="----"
                        secret
                        disabled={sentCode ? false : true}
                        type="numeric"
                        onChange={(value) => setPinNumber(value)}
                        inputMode="number"
                        inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                        inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                        regexCriteria={/^[ 0-9_@./#&+-]*$/}
                    />
                </div>
                {sentCode &&
                    <div style={{ width: "100%", marginTop: "30px" }}>
                        <button type="submit" className="btn btn-block btn-brand-gradient btn-done" style={{ margin: "15px auto 0px auto", width: "60%" }}>{t("SUBMIT")}</button>
                    </div>
                }
            </form>
            {!sentCode &&
                <div style={{ width: "100%", marginTop: "30px"  }}>
                    <button type="button" className="btn btn-block btn-brand-gradient btn-done" style={{ margin: "15px auto 0px auto", width: "60%" }} onClick={() => sendPinCode()}>{t("SEND_VERIFICATION_CODE")}</button>
                </div>
            }
            <p style={{ marginTop: "30px", textAlign: "center" }}>{t("PLEASE_CHANGE_PIN_AFTER_RESET")}</p>
            {showAlert && <div className="content content-full-width" style={{ position: "fixed", top: "10px", width: "100%" }}>
                <div className="container">
                    <Alert color="green">{t("REGISTERED_REDIRECTING")}</Alert>
                </div>
            </div>}
            {showFailAlert && <div className="content content-full-width" style={{ position: "fixed", top: "10px", width: "100%" }}>
                <div className="container">
                    <Alert color="red">{failMessage}</Alert>
                </div>
            </div>}
        </>
    );
};

export default ForgetPin;