import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/custom/Header";
import { ApiKey, ApiUrl, WebUrl, LanguageKey, SessionKey, TransactionType, PanelType } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiEngine from "../../util/ApiEngine.js";
import { createMultiPartFormBody, numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  ModalBody,
  ModalHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledCarousel,
  Carousel as ReactCarousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
  CardSubtitle
} from "reactstrap";
import { Card as BTCard, Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Spinner from "react-bootstrap/Spinner";
import { keys, first, last } from 'lodash';
import { updateUserData } from "../../redux/AuthAction";


const LoyaltyRedemption = (props) => {
  var _history = useHistory();
  var _dispatch = useDispatch();
  var _location = useLocation();
  var { t } = useTranslation(LanguageKey._PRIMARY);


  const { handleSubmit, control, watch, setValue } = useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showClaimConfirmation, setShowClaimConfirmation] = useState(false);
  const [redeemModal, setRedeemModal] = useState(false);
  const [loyaltyItemData, setLoyaltyItemData] = useState([]);
  const [memberPoint, setMemberPoint] = useState(0);
  const [getPointsDone, setGetPointsDone] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [unclaimLoyaltyPoints, setUnclaimLoyaltyPoints] = useState(0);
  const activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );
  const userId = useSelector(
    (state) => state.authState.userData.userId
  );
  const loyaltyPoints = useSelector(
    (state) => state.authState.userData.loyaltyPoints
  );

  const rightMenuList = [
    {
      title: "REDEMPTIONS_RECORD",
      url: WebUrl._URL_LOYALTY_REDEMPTION_HISTORY,
      from: window.location.pathname
    },
    {
      title: "GAME_POINTS_STATEMENT_SHORT",
      url: WebUrl._URL_LOYALTY_POINTS_HISTORY,
      from: window.location.pathname
    }
  ];

  async function init() {
    _dispatch(setBusy());
    let languageCode = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ? localStorage.getItem(SessionKey._LANGUAGE) : 'th';
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_ALL_LOYALTY_ITEM + "?languageCode=" + languageCode);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setLoyaltyItemData(responseJson[ApiKey._API_DATA_KEY]);
    }
    getMemberLoyaltyPoints();
    //getUnclaimLoyaltyPoints();
    _dispatch(setIdle());
  };

  const getMemberLoyaltyPoints = async () => {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_LOYALTY_POINTS_BY_ID);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMemberPoint(responseJson[ApiKey._API_DATA_KEY]);
    }
    _dispatch(setIdle());
    setGetPointsDone(true);
  }

  useEffect(() => {
    init();
  }, [])

  const getUnclaimLoyaltyPoints = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UNCLAIM_LOYALTY_POINTS
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["totalClaimable"];
      setUnclaimLoyaltyPoints(data);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  function preRedeem(e, item) {
    e.stopPropagation();
    setModalData(item);
    setShowConfirmation(true);
  }

  function preSubmit() {
    setShowClaimConfirmation(true);
  }

  const handleRedemption = async (data) => {
    _dispatch(setBusy());

    if (data["productType"] === "Voucher") {
      let transactionJson = await ApiEngine.post(
        ApiUrl._API_REDEEM_VOUCHER + "?itemId=" + data.id
      );

      if (transactionJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(updateUserData());
        init();
        _dispatch(
          showResponseMessage(
            transactionJson[ApiKey._API_SUCCESS_KEY],
            transactionJson[ApiKey._API_MESSAGE_KEY]
          )
        );
      }
      else {
        _dispatch(
          showResponseMessage(
            transactionJson[ApiKey._API_SUCCESS_KEY],
            transactionJson[ApiKey._API_MESSAGE_KEY]
          )
        );
      }
    }
    else {
      let responseJson = await ApiEngine.post(
        ApiUrl._API_REDEEM_LOYALTY_ITEM + "?itemId=" + data.id
      );

      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        init();
      }
    }
    _dispatch(setIdle());
    setRedeemModal(false);
  };

  async function submitForm() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CLAIM_LOYALTY_POINTS,
      createMultiPartFormBody({})
    );

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
  }

  const closeModal = () => {
    setRedeemModal(false);
    setModalData([]);
  }

  return (
    <>
      {showConfirmation && (
        <SweetAlert
          custom={true}
          customIcon={require("../../assets/img/sweetalert_warning.png")}
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          cancelBtnCssClass="custom-cancel-btn"
          confirmBtnCssClass="custom-btn"
          confirmBtnStyle={{ boxShadow: "0px 5px 30px #3369ffb3" }}
          title={t("CONFIRM_REDEEM")}
          onConfirm={() => {
            setShowConfirmation(false);
            handleRedemption(modalData);
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        ></SweetAlert>
      )}
      <Header
        history={_history}
        url={WebUrl._URL_MAIN}
        title={t("GAME_POINTS_REDEMPTION")}
        hasRightMenu={true}
        rightMenuList={rightMenuList}
      />
      <div id="loyalty-redemption-homepage">
        <div className="hr-right mb-3"></div>
        <div className="game-points-container">
          <div className="first">
            <div className="custom-icon-box">
              <img src={require("../../assets/img/v3/points.svg")} alt="game-points" />
            </div>
            <div className="font12">
              {t("GAME_POINTS_BALANCE")}
            </div>
          </div>

          <div className="amount">
            {getPointsDone
              ? " " +
              numberWithCurrencyFormat(
                parseFloat(memberPoint),
                2
              )
              : t("LOADING") + "... "}
          </div>
        </div>
        <div className="hr-left mt-3 mb-3"></div>
        <div>
          <h4 className="mb-3">{t("LATEST_ITEM")}</h4>
          <Row xs={2} md={2} className="g2 mb-1">
            {loyaltyItemData &&
              loyaltyItemData.length > 0 &&
              loyaltyItemData.map((item, index) => {
                return (
                  <>
                    <Col style={{ padding: "0 7px" }}>
                      <Card className="text-center my-1 game-pts-item-card" border="primary" onClick={() => { _history.push({ pathname: WebUrl._URL_LOYALTY_VOUCHER_DETAILS, state: { id: item.id } }) }}>
                        <img variant="middle" src={item.image} className="game-pts-item-img" />
                        <CardBody className="game-pts-item-card-body">
                          <CardTitle className="game-pts-item-title">{item.name}</CardTitle>
                          <CardText className="game-pts-item-text">{numberWithCurrencyFormat(item.pointsNeeded, 2, true)} {t("POINTS")}</CardText>
                          <div>
                            {item.redeemable === true && item.stock > 0 && memberPoint >= item.pointsNeeded ?
                              <button type="submit" className="btn btn-block btn-brand-gradient" onClick={(e) => preRedeem(e, item)}>{t("REDEEM")}</button>
                              : item.redeemable === false && item.stock > 0 && (memberPoint > item.pointsNeeded || memberPoint < item.pointsNeeded) ? // Claim limit reached
                                <button type="submit" className="btn btn-block btn-brand-gradient" disabled>{t("REDEEMED")}</button>
                                : item.redeemable === true && item.stock > 0 && memberPoint < item.pointsNeeded ? // Points not enough
                                  <button type="submit" className="btn btn-block btn-brand-gradient" disabled>{t("REDEEM")}</button>
                                  :
                                  <button type="submit" className="btn btn-block btn-brand-gradient" disabled>{t("OUT_OF_STOCK")}</button>
                            }
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </>
                );
              })}
          </Row>
        </div>
        <Modal
          id="custom-modal"
          contentClassName="modal-brand modal-bottom redeem-modal"
          fade={true}
          isOpen={redeemModal}
          toggle={() => {
            closeModal();
          }}
          centered
        >
          <ModalBody>
            <div className="redeem-modal-body">
              <div className="redeem-modal-image">
                <img src={modalData.image} alt="test" />
              </div>
              <div className="redeem-content">
                <div className="title">
                  {modalData.name}
                </div>
                <div className="points mt-3">
                  <div>
                    <img src={require("../../assets/img/v3/points.svg")} alt="game-points" />
                  </div>
                  <div>
                    {numberWithCurrencyFormat(modalData.pointsNeeded)} {t("POINTS")}
                  </div>
                </div>
                {/*<div className="details mt-3">
                  Enjoy your 100THB cash voucher by playing your favorite game under i8.live*min spend 1,000THB. 
                </div>*/}
                {/*<div className="redeem-tc mt-2">
                  <a>T&C</a>
                </div>*/}
                <div className="">
                  {modalData.redeemable === true && modalData.stock > 0 && memberPoint >= modalData.pointsNeeded ?
                    <button type="submit" className="btn btn-block btn-brand-gradient mt-4" onClick={() => preRedeem()}>{t("REDEEM")}</button>
                    : modalData.redeemable === false && modalData.stock > 0 && (memberPoint > modalData.pointsNeeded || memberPoint < modalData.pointsNeeded) ? // Claim limit reached
                      <button type="submit" className="btn btn-block btn-brand-gradient mt-4" disabled>{t("REDEEMED")}</button>
                      : modalData.redeemable === true && modalData.stock > 0 && memberPoint < modalData.pointsNeeded ? // Points not enough
                        <button type="submit" className="btn btn-block btn-brand-gradient mt-4" disabled>{t("REDEEM")}</button>
                        :
                        <button type="submit" className="btn btn-block btn-brand-gradient mt-4" disabled>{t("OUT_OF_STOCK")}</button>
                  }
                </div>
              </div>

            </div>

          </ModalBody>
        </Modal>
      </div>
    </>
  )
}


export default LoyaltyRedemption;
