import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage, setBusy, setIdle } from "../../redux/AppAction.js";
import { ApiKey, ApiUrl, WebUrl, Role, SessionKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine.js";
import { stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat } from "../../util/Util";
import StyledSelect from "../../components/custom/StyledSelect";
import Select from 'react-select';
import moment from 'moment';

const Kpi = () => {
  let _history = useHistory();
  const { t, i18n } = useTranslation();
  var _dispatch = useDispatch();
  var _userData = useSelector(state => state.authState.userData);

  const [monthOptions, setMonthOptions] = useState([]);
  const [kpiData, setKpiData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState('');

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    init();
  }, []);


  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedMonth)) {
      setSelectedIndex(kpiData.bonusDetail.findIndex(bd => bd.month == selectedMonth));
    }
  }, [selectedMonth]);

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAIL_KPI);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {

      let data = responseJson[ApiKey._API_DATA_KEY];
      setKpiData(data);
      setSelectedMonth(data["bonusDetail"][0]["month"]);

      let _locale = "";
      switch (localStorage.getItem(SessionKey._LANGUAGE)) {
        case "en":
          _locale = "en-gb";
          break;
        case "zh":
          _locale = "zh-cn";
          break;
        case "th":
          _locale = "th";
          break;
        case "id":
          _locale = "id";
          break;
        case "vi":
          _locale = "vi";
          break;
        case "ms":
          _locale = "ms";
          break;
        default:
          _locale = "en-gb";
      }
      moment.locale(_locale);
      setMonthOptions(data.bonusDetail.map((bd) => { return { label: moment(bd.month).format('MMM YY'), value: bd.month } }));
    }
    else {
      _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
    }
    moment.locale("en-gb");
    _dispatch(setIdle());
  }

  const clickMonth = async () => {
    if (!stringIsNullOrEmpty(selectedMonth)) {
      setSelectedIndex(kpiData.bonusDetail.findIndex(bd => bd.month == selectedMonth));
    }
  }
  if (isObjectEmpty(kpiData)) {
    return (null);
  }
  else {
    return (
      <>
        <Header history={_history} url={WebUrl._URL_MAIN} title={t('KPI')} />
        <div style={{ paddingBottom: "10px" }}>
          {/* <div className="card kpi-header-widget mb-2">
                        <div className="card-body">
                            <div className="user-detail">
                                <div>{kpiData["username"]}</div>
                                <div>{kpiData["phone"]}</div>
                            </div>
                            <div>
                                {t("DOWNLINE")}: {kpiData["totalDownline"]}
                            </div>
                        </div>
                    </div> */}
          <div className="kpi-total-container">
            <div className="first">
              <div className="title-kpi">{t("TOTAL_MONTHLY_BONUS")}</div>
              <div>
                <table className={"text-brand table-kpi" + (_userData.selectedTheme === 0 ? " classic" : " modern")} style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>{t("TOTAL_BONUS")}</td>
                      <th>{numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["bonus"]), 2, true)}</th>
                    </tr>
                    <tr>
                      <td>{t("DOWNLINE")}</td>
                      <th>{numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["downlineBonus"]), 2, true)}</th>
                    </tr>
                    <tr>
                      <td>{t("OWN_BONUS")}</td>
                      <th>{numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["ownBonus"]), 2, true)}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="second">
              <div className="kpi-second-child">
                <div>{t("TOTAL_SALES")}</div>
                <div>{numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["sales"]), 2, true)}</div>
              </div>
              <div className="kpi-second-child">
                <div>{t("TOTAL_COMMISSION")}</div>
                <div>{numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["commission"]), 2, true)}</div>
              </div>
            </div>
          </div>
          <div className="hr-right mb-3 mt-3"></div>
          <div className="kpi-select custom-select-box" style={{ margin: '1rem 0' }}>
            <StyledSelect
              options={monthOptions}
              value={selectedMonth}
              onChange={(e) => { setSelectedMonth(e.value) }}
            />
          </div>

          {/*<div className="kpi-select-search">*/}
          {/*    <div className="kip-select-backgroud">*/}
          {/*    <div>*/}
          {/*        <select*/}
          {/*                    onChange={(e) => setSelectedMonth(e.target.value)}*/}
          {/*                    className="custom-select-kpi-container"*/}
          {/*                    value={selectedMonth}*/}
          {/*                >*/}
          {/*                    {monthOptions.map((month, index) => {*/}
          {/*                        return (*/}
          {/*                            <option*/}
          {/*                                key={index}*/}
          {/*                                className={"kpi-prefix"}*/}
          {/*                                value={month.value}*/}
          {/*                            >*/}
          {/*                                {month.label}*/}
          {/*                            </option>*/}
          {/*                        );*/}
          {/*                    })}*/}
          {/*            </select>*/}
          {/*        </div>*/}
          {/*        <div className="kip-select-icon"><img src={require("../../assets/img/v3/VectorSearch.svg")} onClick={() => clickMonth() } ></img></div>*/}
          {/*    </div>*/}
          {/*</div>*/}
          <div className="hr-left mb-3 mt-3"></div>
          {/* <div className="title-md text-yellow">{t("TOTAL_MONTHLY_BONUS")}</div>
                    <div>
                        <table className={"text-brand table-kpi" + (_userData.selectedTheme === 0 ? " classic" : " modern")} style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td>{t("TOTAL_BONUS")}</td>
                                    <th>{numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["bonus"]), 3, true)}</th>
                                </tr>
                                <tr>
                                    <td>{t("DOWNLINE")}</td>
                                    <th>{numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["downlineBonus"]), 3, true)}</th>
                                </tr>
                                <tr>
                                    <td>{t("OWN_BONUS")}</td>
                                    <th>{numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["ownBonus"]), 3, true)}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="title-md text-yellow">{t("TOTAL_MONTHLY_COMMISSION")}</div>
                    <div>
                        <table className={"text-brand table-kpi" + (_userData.selectedTheme === 0 ? " classic" : " modern")} style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td>{t("TOTAL_COMMISSION")}</td>
                                    <th>{numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["commission"]), 3, true)}</th>
                                </tr>
                                <tr>
                                    <td>{t("DOWNLINE")}</td>
                                    <th>{numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["downlineCommission"]), 3, true)}</th>
                                </tr>
                                <tr>
                                    <td>{t("OWN_COMMISSION")}</td>
                                    <th>{numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["ownCommission"]), 3, true)}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="title-md text-yellow">{t("TOTAL_SALES")}</div>
                    <div>
                        <table className={"text-brand table-kpi" + (_userData.selectedTheme === 0 ? " classic" : " modern")} style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td>{t("TOTAL_SALES")}</td>
                                    <th>{numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["sales"]), 3, true)}</th>
                                </tr>
                                <tr>
                                    <td>{t("DOWNLINE")}</td>
                                    <th>{numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["downlineSales"]), 3, true)}</th>
                                </tr>
                                <tr>
                                    <td>{t("OWN_SALES")}</td>
                                    <th>{numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["ownSales"]), 3, true)}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
          {kpiData["dailySummary"].length != 0 &&
            <div className="card card-package">
              <div className="card-body p-0">
                <div className="card-table table-responsive">
                  <table className="table table-kpi-summary">
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left" }}>{t("DATE")}</th>
                        <th style={{ textAlign: "right" }}>{t("SALES")}</th>
                        <th style={{ textAlign: "right" }}>{t("COMMISSION_SHORT")}</th>
                        <th style={{ textAlign: "right" }}>{t("DOWNLINE")}</th>
                        <th style={{ textAlign: "right" }}>{t("YOURS")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        kpiData["dailySummary"].filter(i => i.month == moment(selectedMonth).format('MMM YY')).map((summary, i) => {
                          return (
                            <tr key={i}>
                              <td style={{ textAlign: "left" }}>{moment(summary["date"]).format(moment().startOf('year').format("YYYY") + "-MM-DD")}</td>
                              <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(summary["sales"]), 2, true)}</td>
                              <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(summary["commission"]), 2, true)}</td>
                              <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(summary["downlineCommission"]), 2, true)}</td>
                              <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(summary["ownCommission"]), 2, true)}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                    <tfoot>
                      <tr>
                        <td style={{ textAlign: "left" }}><b>{t("TOTAL")}</b></td>
                        <td style={{ textAlign: "right" }}><b>{numberWithCurrencyFormat(parseFloat(kpiData["dailySummary"].filter(i => i.month == moment(selectedMonth).format('MMM YY')).reduce((total, data) => total + data.sales, 0)), 2, true)}</b></td>
                        <td style={{ textAlign: "right" }}><b>{numberWithCurrencyFormat(parseFloat(kpiData["dailySummary"].filter(i => i.month == moment(selectedMonth).format('MMM YY')).reduce((total, data) => total + data.commission, 0)), 2, true)}</b></td>
                        <td style={{ textAlign: "right" }}><b>{numberWithCurrencyFormat(parseFloat(kpiData["dailySummary"].filter(i => i.month == moment(selectedMonth).format('MMM YY')).reduce((total, data) => total + data.downlineCommission, 0)), 2, true)}</b></td>
                        <td style={{ textAlign: "right" }}><b>{numberWithCurrencyFormat(parseFloat(kpiData["dailySummary"].filter(i => i.month == moment(selectedMonth).format('MMM YY')).reduce((total, data) => total + data.ownCommission, 0)), 2, true)}</b></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          }
        </div>
      </>
    );
  }
};

export default Kpi;