import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { PageSettings } from "../../config/page-settings.js";
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import { map } from "lodash";
import { Modal, ModalBody } from "reactstrap";
import Header from '../../components/custom/Header';
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant.js";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine.js";
import { numberWithCurrencyFormat } from "../../util/Util.js";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

/// <summary>
/// Author : 
/// </summary>
const MainWallet = props => {
  var _userData = useSelector((state) => state.authState.userData);
  const { t, i18n } = useTranslation();
  const _history = useHistory();
  const [walletList, setWalletList] = useState([]);
  const [loadingWalletData, setLoadingWalletData] = useState(true);

  const rightMenuList = [
    {
      title: "TRANSACTION_HISTORY",
      url: WebUrl._URL_TRANSACTION_HISTORY,
      from: window.location.pathname
    }
  ];

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    getWalletData();
  }

  const getWalletData = async () => {
    let walletJson = await ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE);
    if (walletJson[ApiKey._API_SUCCESS_KEY]) {
      let data = walletJson[ApiKey._API_DATA_KEY];
      setWalletList(data);
    }
    setLoadingWalletData(false);
  }

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_MAIN}
        title={t("WALLET")}
        hasBackButton={true}
        hasRightMenu={true}
        rightMenuList={rightMenuList}
      />
      <div className="main-wallet-methods-container">
        <div className="first" onClick={() =>
          _history.push({
            pathname: WebUrl._URL_DEPOSIT,
            state: {
              "url": WebUrl._URL_MAIN_WALLET,
              "from": window.location.pathname,
            }
          })}
        >
          <div className="custom-icon-box mr-0 ml-auto">
            <img src={require("../../assets/img/v3/reload-icon.svg")} alt={t("RELOAD")} />
          </div>
          <div>
            {t("RELOAD")}
          </div>
        </div>
        {_userData.isBlockWithdraw ?
          <div className="first" onClick={() =>
            _history.push({
              pathname: WebUrl._URL_TRANSFER,
              state: {
                "url": WebUrl._URL_MAIN_WALLET,
                "from": window.location.pathname,
              }
            }) }
          >
            <div className="custom-icon-box mr-0 ml-auto">
              <img src={require("../../assets/img/v3/transfer.svg")} alt={t("TRANSFER")} />
            </div>
            <div>
              {t("TRANSFER")}
            </div>
          </div>
          :
          <div className="second">
            <div className="second-first-child" onClick={() =>
              _history.push({
                pathname: WebUrl._URL_TRANSFER,
                state: {
                  "url": WebUrl._URL_MAIN_WALLET,
                  "from": window.location.pathname,
                }
              })}
            >
              <div className="custom-icon-box">
                <img src={require("../../assets/img/v3/transfer.svg")} alt={t("TRANSFER")} />
              </div>
              <div>
                {t("TRANSFER")}
              </div>
            </div>
            <div className="second-second-child" onClick={() =>
              _history.push({
                pathname: WebUrl._URL_WITHDRAWAL,
                state: {
                  "url": WebUrl._URL_MAIN_WALLET,
                  "from": window.location.pathname,
                }
              })}
            >
              <div className="custom-icon-box">
                <img src={require("../../assets/img/v3/withdraw.svg")} alt={t("WITHDRAW")} />
              </div>
              <div>
                {t("WITHDRAW")}
              </div>
            </div>
          </div>
        }
      </div>
      <h3 className="mt-4 pt-2">{t("WALLET_INFO")}</h3>
      {!loadingWalletData ? <div className="main-wallet-list-container pb-5">
        {walletList &&
          walletList.length &&
          map(walletList, (item, index) => {
            return (
              <div key={index} className="main-wallet-list-item">
                <div>
                  {item.productName === "Main Wallet" ? t("MAIN_WALLET") : item.productName}
                </div>
                <div>
                  {numberWithCurrencyFormat(parseFloat(item.balance), 2, true)}
                </div>
              </div>
            );
          })
        }
      </div> :
        <div style={{ textAlign: "center" }}>
          <Spinner animation="border" role="status"></Spinner>
        </div>
      }
    </>
  );

}

export default MainWallet;