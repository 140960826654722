import React, { useEffect, useRef, useState } from "react";
import useScreenOrientation from 'react-hook-screen-orientation';
import ApiEngine from "../../util/ApiEngine.js";
import { ApiKey, ApiUrl, LanguageKey, WebUrl } from "../../util/Constant.js";
import {
    showResponseMessage,
    setGameSearchText,
    setGameQuery,
    setPageSize,
    setY,
    setBusy,
    setIdle
} from "../../redux/AppAction.js";
import {
    createFormBody,
    stringIsNullOrEmpty,
} from "../../util/Util.js"; 
import { performLogout } from "../../redux/AuthAction.js";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Keyboard from "react-simple-keyboard";
import PinInput from "react-pin-input";
import { Modal, ModalBody, Alert } from "reactstrap";
import NavigationButton from "./../../components/custom/NavigationButton";
import Icon from "@mdi/react";
import { mdiArrowUpDropCircle, mdiArrowDownDropCircle } from '@mdi/js';
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import "../../assets/css/gamepage.css";
import { relativeTimeRounding } from "moment";

const GamePage = (props) => {
    const _dispatch = useDispatch();
    const _history = useHistory();
    const _location = useLocation();
    let _savedQuery = useSelector(state => state.appState.gameQuery);
    const { t, i18n } = useTranslation(LanguageKey._PRIMARY); 
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [activeGameId, setActiveGameId] = useState(params.get("gameId"));
    const [sourceLink, setSourceLink] = useState("");
    const [memberData, setMemberData] = useState([]);
    const [initCheck, setInitCheck] = useState(false);
    const [showRightMenu, setShowRightMenu] = useState(false);
    const [showPinEntry, setShowPinEntry] = useState(false);
    const [theInterval, setTheInterval] = useState(false);
    const [invalidPin, setInvalidPin] = useState(false);
    const [pinNumber, setPinNumber] = useState("");
    const [enteredPinNumber, setEnteredPinNumber] = useState("");
    const [pinNumberFocused, setPinNumberFocused] = useState(0);
    const [pinNumberConfirm, setPinNumberConfirm] = useState("");
    const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
    const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
    const [pinNumberFinal, setPinNumberFinal] = useState("");
    const [enteredPinNumberFinal, setEnteredPinNumberFinal] = useState("");
    const [pinNumberFinalFocused, setPinNumberFinalFocused] = useState(0);
    const [forgetPin, setForgetPin] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [smsSent, setSmsSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showMoreGames, setShowMoreGames] = useState(false);
    const [moreGamesLoaded, setMoreGamesLoaded] = useState(true);
    const [getTextFlag, setGetTextFlag] = useState(false);
    const [moreGamesList, setMoreGamesList] = useState([]);
    const [moreGamesToDisplay, setMoreGamesToDisplay] = useState([]);
    const [providerLogo, setProviderLogo] = useState("");
    const [activeTab, setActiveTab] = useState(0);
    const [IsScreenOrientation, setIsScreenOrientation] = useState(false);
    const [currentX, setCurrentX] = useState(
        Math.max(document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        ) - 21
    );
    const [currentY, setCurrentY] = useState(
        Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
        ) - 21
    );
    const [dragging, setDragging] = useState(false);

    const [currentWindowWidth, setCurrentWindowWidth] = useState(
        Math.max(document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        )
    );
    const [currentWindowHeight, setCurrentWindowHeight] = useState(
        Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
        )
    );
    var pageToGoBack = "";

    const _COUNTDOWN_PERIOD = 60;
    const _SECONDS_IN_MS = 1000;
    const _ALERT_TIMEOUT = 3000;
    const [countdownPeriod, setCountdownPeriod] = useState(0);

    const elePin = useRef(null);
    const eleConfirm = useRef(null);
    const eleFinalPin = useRef(null);
    const keyboard = useRef();

    var style = {};

    const RIGHT_MENU_SELECTIONS = [
        {
            name: "NOTIFICATION",
            imageUrl: require("../../assets/img/icon/right-menu-notification-icon.svg"),
            redirectUrl: WebUrl._URL_NEWS,
        },
        {
            name: "VIDEO_TUTORIAL",
            imageUrl: require("../../assets/img/icon/right-menu-video-tutorial-icon.svg"),
            redirectUrl: WebUrl._URL_VIDEO_TUTORIAL,
        },
    ];

    memberData["isShareHolder"] &&
        RIGHT_MENU_SELECTIONS.push({
            name: "VIP_EVENT",
            imageUrl: require("../../assets/img/icon/right-menu-vip-icon.svg"),
            redirectUrl: WebUrl._URL_SHAREHOLDER,
        });

    RIGHT_MENU_SELECTIONS.push(
        {
            name: "LIVE_CHAT",
            imageUrl: require("../../assets/img/icon/right-menu-contact-us-icon.svg"),
            redirectUrl: WebUrl._URL_CONTACT_US,
        },
        {
            name: "TNC_SHORT",
            imageUrl: require("../../assets/img/icon/right-menu-tnc-icon.svg"),
            redirectUrl: WebUrl._URL_TNC,
        },
        {
            name: "SETTINGS",
            imageUrl: require("../../assets/img/icon/right-menu-settings-icon.svg"),
            redirectUrl: WebUrl._URL_SETTINGS,
        },
        {
            name: "RANKING_EVENT",
            imageUrl: require("../../assets/img/icon/right-menu-ranking-event-icon.svg"),
            redirectUrl: "#comingsoon",
        },
        {
            name: "PROMOTION",
            imageUrl: require("../../assets/img/icon/right-menu-promotion-icon.svg"),
            redirectUrl: WebUrl._URL_REWARDS,
        },
        {
            name: "LOGOUT",
            imageUrl: require("../../assets/img/icon/right-menu-logout-icon.svg"),
        }
    );

    useEffect(() => {
        //let draggableDiv = document.getElementsByClassName("bottom-drawer");
        //enableDraggable(draggableDiv);
        _dispatch(setGameQuery(ApiUrl._API_GET_MASTER_PRODUCT_GROUP_GAME_BY_GAME_ID + "?id=" + activeGameId));
        init(activeGameId);
        window.onresize = _adjust_iframe_size;
        setInitCheck(true);
    }, []);
    
    useEffect(() => {
        if (_location.state) {
            pageToGoBack = _location.state.url;
        }
    }, [_location.state]);

    useEffect(() => {
        setCurrentX(currentWindowWidth - 25);
        setCurrentY(50);
    }, []);
    const screenOrientation = useScreenOrientation();
    useEffect(() => {
        setTimeout(() => {
          if (screenOrientation === "portrait-primary") {
            if (IsScreenOrientation == true) {
              setIsScreenOrientation(false);
              setCurrentX(currentWindowWidth - 25);
              setCurrentY(50);
            //   console.log(currentWindowWidth);
            }
          } else {
            if (IsScreenOrientation == false) {
              setIsScreenOrientation(true);
              setCurrentX(currentWindowWidth - 25);
              setCurrentY(50);
            //   console.log(currentWindowWidth);
            }
          }
        }, _SECONDS_IN_MS);
      });
    async function init(parsedGameId) {
        _dispatch(setBusy());
        setShowMoreGames(false);
        try {
            if (parsedGameId !== params.get("gameId")) { _history.push(WebUrl._URL_GAME_PAGE + "?gameId=" + parsedGameId); } //this is only just to change the url on top
            setActiveGameId(parsedGameId);

            setMoreGamesLoaded(false);
            let responseJson = await ApiEngine.get(
                ApiUrl._API_GET_MASTER_PRODUCT_GROUP_GAME_BY_GAME_ID + "?id=" + parsedGameId
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let gameData = responseJson[ApiKey._API_DATA_KEY]['data'];
                setMoreGamesList(gameData);
                let masterProductJson = await ApiEngine.get(ApiUrl._API_GET_MASTER_PRODUCT_BY_ID + "?id=" + gameData[0]['productId']);
                if (masterProductJson[ApiKey._API_SUCCESS_KEY]) {
                    let masterProductData = masterProductJson[ApiKey._API_DATA_KEY];
                    setProviderLogo(masterProductData['productImage']);
                }
            }
            setMoreGamesLoaded(true);

            responseJson = await ApiEngine.get(
                ApiUrl._API_START_GAME + "?gameId=" + parsedGameId + "&isApp=0&device=m"
            );
            setSourceLink(responseJson);
        } catch (errorMessage) {
            _dispatch(showResponseMessage(false, errorMessage));
        }
        _dispatch(setIdle());
    }

    function onVerifyPinKeyPress(key) {
        let currentIndex = 0;
        let pinStr = enteredPinNumberFinal;
        if (key !== "{bksp}") {
            if (pinNumberFinal === "") {
                currentIndex = pinNumberFinalFocused;
                eleFinalPin["current"]["elements"][currentIndex].state.value = key;
                pinStr += key;
                setEnteredPinNumberFinal(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setPinNumberFinalFocused(currentIndex);
                    eleFinalPin["current"]["elements"][currentIndex].focus();
                } else {
                    verifyPin(pinStr);
                }
            }
        } else {
            eleFinalPin["current"].clear();
            setPinNumberFinal("");
            setEnteredPinNumberFinal("");
            setPinNumberFinalFocused(currentIndex);
            eleFinalPin["current"]["elements"][currentIndex].focus();
        }
    }

    function getText(e) {
        _dispatch(setGameSearchText(e.target.value));
        _dispatch(setGameQuery(""));
        setGetTextFlag(true);
        _dispatch(setGameQuery(ApiUrl._API_GET_MASTER_PRODUCT_GROUP_GAME + "?keyword=" + e.target.value + "&id=" + props.id));
        _dispatch(setPageSize(10));
        _dispatch(setY(0));
    }

    useEffect(() => {
        if (countdownPeriod > 0) {
            setTimeout(() => {
                let tempPeriod = countdownPeriod - 1;
                setCountdownPeriod(tempPeriod);
            }, _SECONDS_IN_MS);
        }
    }, [countdownPeriod]);

   

    async function sendPinCode() {
        if (countdownPeriod < 1) {
            let responseJson = await ApiEngine.get(
                ApiUrl._API_CHECK_IF_PHONE_VERIFIED
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var hpNum = responseJson[ApiKey._API_DATA_KEY];
                let params = {
                    phoneNumber: hpNum,
                    isShareholder: "true",
                };
                let smsJson = await ApiEngine.post(
                    ApiUrl._API_SEND_PIN_CODE,
                    createFormBody(params)
                );
                if (smsJson[ApiKey._API_SUCCESS_KEY]) {
                    setForgetPin(false);
                    setSmsSent(true);
                    setCountdownPeriod(_COUNTDOWN_PERIOD);
                }
            }
        } else {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
        }
    }

    const verifyPin = async (pin) => {
        if (pin === "0000") {
            _history.push(WebUrl._URL_SETTINGS); // need to change
            setInvalidPin(false);
        } else {
            let params = {
                pinNumber: pin,
            };
            let responseJson = await ApiEngine.post(
                ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
                createFormBody(params)
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setInvalidPin(false);
                _history.push(
                    WebUrl._URL_SHAREHOLDER +
                    "?GAzobE73Vse4nr8WpufA=" +
                    memberData["hideSummary"]
                );
            } else {
                setInvalidPin(true);
                setErrorMessage(responseJson[ApiKey._API_MESSAGE_KEY]);
                eleFinalPin["current"].clear();
                setPinNumberFinal("");
                setEnteredPinNumberFinal("");
                setPinNumberFinalFocused(0);
                eleFinalPin["current"]["elements"][0].focus();
            }
        }
    };

    function _adjust_iframe_size() {
        let tempH = Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
        );
        setCurrentWindowHeight(tempH);
        let tempW = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        );
        setCurrentWindowWidth(tempW);
    }

    async function handleShowMoreGames() {
        setShowMoreGames(true);
    }
    
    const setMousePosition = (e, platform) => {
        // const currentWidthWithButton = currentWindowWidth - 25;
        if (platform === 'desktop') {
            // console.log(e.clientX);
            setCurrentX(e.clientX - 13.5);
            setCurrentY(e.clientY - 25);
            if(e.clientX > currentWindowWidth || e.clientX < 0 || e.clientY > currentWindowHeight || e.clientY < 0){
                setCurrentX(currentWindowWidth - 25);
                setCurrentY(50);
              }
        }
        else if (platform === 'mobile') {
            setCurrentX(e.changedTouches[0].clientX - 13.5);
            setCurrentY(e.changedTouches[0].clientY - 25);
            //setCurrentX(e.touches[0].clientX - 10.5);
            //setCurrentY(e.touches[0].clientY - 21);
            if(e.changedTouches[0].clientX > currentWindowWidth || e.changedTouches[0].clientX < 0 || e.changedTouches[0].clientY > currentWindowHeight || e.changedTouches[0].clientY < 0){
              setCurrentX(currentWindowWidth - 25);
              setCurrentY(50);
            }
        }
        // console.log(e);
        // console.log(e.changedTouches[0].clientX);
        // console.log(e.changedTouches[0].clientY);
    }

   

    const handleDragging = (event, platform) => {
        console.log('drag start');
        setMousePosition(event, platform);
    }

    const handleDraggingStart = (e, platform) => {
        e.target.style.opacity = 0.5;
        
    }

    const handleMouseMove = (e) => {
        if (dragging) {
            setMousePosition(e);
        }
    }

    const stopDragging = (e) => {
        console.log('stop');
        window.removeEventListener("mousemove", setMousePosition(e), true);
        window.removeEventListener("mouseup", setMousePosition(e), true);
    }

    const closeDragList = () => {
        setShowMoreGames(false);
        setCurrentX(currentWindowWidth - 25);
        setCurrentY(50);
        console.log('dragcloseclicked');
    };

    function extractSourceLink(sourceLink){
        const matches = sourceLink.match(/'(.*?)'/);
        return matches
          ? matches[1] 
          : sourceLink;
      }
      
    return (<>
        <div id="game-page">
            <div className="user-info">
                <div className="user-info-left">
                    <NavigationButton
                        className="btn btn-icon"
                        icon="fa fa-arrow-left"
                        history={_history}
                    // url={pageToGoBack}
                    />
                </div>
                {/*<div className="user-info-right">
                    <div
                        className="kpi-and-settings settings"
                        onClick={() => setShowRightMenu(true)}
                    >
                        <i
                            className="fas fa-regular fa-bars"
                            style={{ fontSize: "20px", color: "white" }}
                        ></i>
                    </div>
                </div>*/}
            </div>
            <div>
                {!stringIsNullOrEmpty(sourceLink) ? <>
                    <iframe
                        style={{
                            border: "none",
                            //height: "calc(100% - 50px)",
                            height: currentWindowWidth > currentWindowHeight ? "100vh" : "calc(100vh - 50px)",
                            //width: "100%",
                            width: currentWindowWidth > currentWindowHeight ? "100vw" : "100%",
                            //position: "absolute",
                            position: currentWindowWidth > currentWindowHeight ? "fixed" : "absolute",
                            //top: "50px",
                            top: currentWindowWidth > currentWindowHeight ? "0" : "50px",
                            left: "0",
                            zIndex: "2",
                            backgroundColor:"black"
                        }} width="100%" height="100%"
                        src={extractSourceLink(sourceLink)}
                    />
                    {!showMoreGames && moreGamesList.length > 1 &&
                        <div
                            draggable={true}
                            className="bottom-drawer"
                            //onDrag={(e) => handleDragging(e, 'desktop')}
                            //onTouchMove={(e) => handleDragging(e, 'mobile')}
                            onDragStart={(e) => handleDraggingStart(e, 'desktop')}
                            onTouchStart={(e) => handleDraggingStart(e, 'mobile')}
                            onDragEnd={(e) => { e.target.style.opacity = 1; setMousePosition(e,'desktop'); }}
                            onTouchEnd={(e) => { e.target.style.opacity = 1; setMousePosition(e, 'mobile'); }}
                            onClick={() => handleShowMoreGames()}
                            style={{ position: "fixed", left: currentX, top: currentY, transition: "0.3s all", zIndex: "999",backgroundColor:"unset" }}
                        >
                            <img className="item-icon" src={require("../../assets/img/icon/draggable-btn.png")} />
                        </div>
                    }
                    
                </> : <></>
                }

                <div className="gameBG"></div>

                {showRightMenu &&
                    <>
                        <div>
                            <div
                                className="right-menu-wrapper pin-wrapper"
                                onClick={() => setShowRightMenu(false)}
                            ></div>
                            <div className="right-menu">
                                <div
                                    className="right-menu-arrow-wrapper"
                                    onClick={() => setShowRightMenu(false)}
                                >
                                    <i className="right-menu-arrow fa fa-chevron-right" />
                                </div>
                                <div className="right-menu-main">
                                    {RIGHT_MENU_SELECTIONS.map((menu, index) => {
                                        if (menu["name"] === "LOGOUT") {
                                            return (
                                                <div
                                                    className={"right-menu-item item-" + (index + 1)}
                                                    onClick={() => _dispatch(performLogout())}
                                                >
                                                    <img className="item-icon" src={menu["imageUrl"]} />
                                                    <div className="item-name">{t(menu["name"])}</div>
                                                </div>
                                            );
                                        } else if (menu["redirectUrl"] === "/shareholder") {
                                            return (
                                                <>
                                                    <div
                                                        className={"right-menu-item item-" + (index + 1)}
                                                        onClick={() => setShowPinEntry(true)}
                                                    >
                                                        <img className="item-icon" src={menu["imageUrl"]} />
                                                        <div className="item-name">{t(menu["name"])}</div>
                                                    </div>
                                                    <hr className="right-menu-hr" />
                                                </>
                                            );
                                        } else if (
                                            menu["name"] ===
                                            "RANKING_EVENT" /* || menu['name'] === "PROMOTION"*/
                                        ) {
                                            return (
                                                <>
                                                    <div
                                                        className={"right-menu-item item-" + (index + 1)}
                                                        style={{
                                                            cursor: "not-allowed",
                                                            paddingTop:
                                                                menu["name"] === "PROMOTION" ? "0.25px" : "0",
                                                        }}
                                                    >
                                                        <div className="coming-soon-label">
                                                            {t("COMING_SOON_LABEL")}
                                                        </div>
                                                        <img className="item-icon" src={menu["imageUrl"]} />
                                                        <div className="item-name">{t(menu["name"])}</div>
                                                    </div>
                                                    <hr className="right-menu-hr" />
                                                </>
                                            );
                                        } else {
                                            return (
                                                <>
                                                    <div
                                                        className={"right-menu-item item-" + (index + 1)}
                                                        onClick={() => _history.push(menu["redirectUrl"])}
                                                    >
                                                        <img className="item-icon" src={menu["imageUrl"]} />
                                                        <div className="item-name">{t(menu["name"])}</div>
                                                    </div>
                                                    <hr className="right-menu-hr" />
                                                </>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
                }
                {showMoreGames && <>
                    <div className="game-drawer-dark-bg"></div>
                    <div className="more-games" style={{ position: currentWindowWidth > currentWindowHeight ? "fixed" : "absolute" }}>
                        <div className="more-games-drawer">
                            <div className="top-drawer" onClick={closeDragList}>
                                <div style={{fontWeight: 700,color: "#002e6c"}}>
                                    {t("PLAY_MORE_GAMES")}
                                    <img className="item-icon" src={require("../../assets/img/icon/drag-click.png")}  style={{marginLeft:"10px"}}/>
                                </div>
                            </div>
                            <div className="more-games-content">
                                {
                                    moreGamesLoaded ?
                                        <>
                                            <div className="game-row" style={{ marginTop: "20px" }}>
                                                <DynamicLoadTable
                                                    pageSize={24}
                                                    apiQuery={_savedQuery}
                                                    searchEnabled={true}
                                                    autoscrollEnabled={true}
                                                    render={(game, i) => {
                                                        return (
                                                            <div className="hot-games-tile" key={i}>
                                                                <div className="game-tile-background">
                                                                    <div className="lobby-games-img" style={{ backgroundImage: game.image !== null ? `url(${game.image})` : `url(${providerLogo})` }}></div>
                                                                    {game.id === parseInt(activeGameId) && <div className="currently-playing-label">{t("CURRENTLY_PLAYING")}</div>}
                                                                    <div className="hot-games-name">
                                                                        <div className="hot-games-text">{game.gameName}</div>
                                                                        <button
                                                                            className="btn btn-block btn-brand"
                                                                            disabled={game.id === parseInt(activeGameId)}
                                                                            style={{ cursor: game.id === parseInt(activeGameId) ? "not-allowed" : "pointer", opacity: game.id === parseInt(activeGameId) ? "0.5":"1", textAlign: "center" }}
                                                                            onClick={() => { if (game.id !== parseInt(activeGameId)) { init(game.id); } }}
                                                                        >
                                                                            {t("PLAY_NOW")}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </>}
                <Modal
                    fade={false}
                    contentClassName="modal-brand modal-bottom modal-numpad popup-transfer-numpad"
                    backdrop={true} 
                    isOpen={showPinEntry}
                    centered
                >
                    <ModalBody>
                        <div className="display-wrapper">
                            <button
                                type="button"
                                style={{
                                    alignSelf: "flex-end",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    marginRight: "15px",
                                    fontSize: "2rem",
                                    color: "#002e6c",
                                    marginTop: "-15px",
                                    float: "right",
                                    fontWeight: "bold",
                                }}
                                onClick={() => {
                                    clearInterval(theInterval);
                                    setShowPinEntry(false);
                                }}
                                data-dismiss="modal"
                                aria-hidden="true"
                            >
                                &times;
                            </button>
                            <strong className="text-brand display-value" style={{ fontSize: "20px" }}>
                                {t("ENTER_YOUR_PIN")}
                            </strong>
                            <PinInput
                                style={{ marginTop: "37px" }}
                                ref={eleFinalPin}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={showPinEntry}
                                autoSelect={false}
                                disabled={showPinEntry}
                                onChange={(value, index) => {
                                    setPinNumberFinalFocused(index);
                                }}
                                type="numeric"
                                inputMode="number"
                                inputStyle={{
                                    border: "0",
                                    margin: "0px 10px",
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: "transparent",
                                    borderBottom: "2px solid #002e6c",
                                }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            {/*<strong className="text-brand display-value" style={{ fontSize: "10px", letterSpacing: "0.33px" }} onClick={() => _history.push(WebUrl._URL_FORGET_PIN)}>Forgot PIN?</strong>*/}

                            <div
                                className="text-brand text-center" 
                                style={{ marginTop: "10px" }} 
                                onClick={() => {
                                    setForgetPin(true);
                                    sendPinCode();
                                }}
                            >
                                {t("RESET_PIN")}
                            </div>
                            {smsSent && (
                                <p
                                    className="text-brand"
                                    style={{ textAlign: "center", marginTop: "5px" }}
                                >
                                    {t("PIN_SENT")} {t("RESEND_IN")} {countdownPeriod} {t("SECOND")}
                                </p>
                            )}
                            {showAlert && (
                                <div
                                    className="content content-full-width"
                                    style={{
                                        position: "fixed",
                                        top: "10px",
                                        left: "0",
                                        width: "100%",
                                    }}
                                >
                                    <div className="container">
                                        <Alert color="danger">{t("WAIT_TO_RESEND")}</Alert>
                                    </div>
                                </div>
                            )}
                            <div
                                className="invalid-feedback"
                                style={{ fontSize: "12px", marginTop: "1rem" }}
                            >
                                {invalidPin && t(errorMessage)}
                                {!invalidPin && <span>&nbsp;</span>}
                            </div>
                        </div>
                        <Keyboard
                            layout={{
                                default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
                            }}
                            display={{
                                "{bksp}": "<i class ='fas fa-backspace'></i>",
                            }}
                            theme={`keyboard keyboard-numpad keyboard-numpad-v3`}
                            keyboardRef={(r) => (keyboard.current = r)}
                            onKeyPress={onVerifyPinKeyPress}
                            disableButtonHold
                            disableCaretPositioning
                        />
                        
                    </ModalBody>
                </Modal>
            </div>
        </div>
    </>);
};

export default GamePage;