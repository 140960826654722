import { createStore, applyMiddleware, combineReducers } from "redux";
import appReducer from "./redux/AppReducer";
import authReducer from "./redux/AuthReducer";
import languageReducer from "./redux/LanguageReducer";
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
/// <summary>
/// Author : 
/// </summary>
const store = createStore(combineReducers({
    authState: authReducer,
    appState: appReducer,
    languageState: languageReducer,
}), composeWithDevTools(applyMiddleware(thunk)));

export default store;