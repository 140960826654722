import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { ApiKey, ApiUrl, WebUrl, Role } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import {
  stringIsNullOrEmpty,
  isObjectEmpty,
  numberWithCurrencyFormat,
} from "../../util/Util";
import StyledSelect from "../../components/custom/StyledSelect";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { useForm, Controller } from "react-hook-form";
import { Nav, NavItem, NavLink } from "reactstrap";
import { createMultiPartFormBody } from "../../util/Util";
import SweetAlert from "react-bootstrap-sweetalert";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";
import { Spinner } from "react-bootstrap";

const UnclaimLoyaltyPoints = () => {
  let _history = useHistory();
  const { t, i18n } = useTranslation();
  var _dispatch = useDispatch();
  var _userData = useSelector((state) => state.authState.userData);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [monthOptions, setMonthOptions] = useState([]);
  const [data, setData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState("");

  const { handleSubmit, control, watch, setValue } = useForm();
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment());
  const [queryProduct, setQueryProduct] = useState("");
  const [queryStatus, setQueryStatus] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(Date.now());

  const [memberData, setMemberData] = useState({});
  const [unclaimLoyaltyPoints, setUnclaimLoyaltyPoints] = useState("");
  const [loaded, setLoaded] = useState(false);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedMonth)) {
      setSelectedIndex(
        data.bonusDetail.findIndex((bd) => bd.month == selectedMonth)
      );
    }
  }, [selectedMonth]);

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    //_dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
    );
    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      throw responseJson[ApiKey._API_MESSAGE_KEY];
    }
    setMemberData(responseJson[ApiKey._API_DATA_KEY]);
    getUnclaimLoyaltyPoints();
    setLoaded(true);
    //_dispatch(setIdle());
  }

  const getUnclaimLoyaltyPoints = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UNCLAIM_LOYALTY_POINTS
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setUnclaimLoyaltyPoints(data);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  function preSubmit() {
    setShowConfirmation(true);
  }

  /// <summary>
  /// Author: -
  /// </summary>
  async function submitForm() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CLAIM_LOYALTY_POINTS,
      createMultiPartFormBody({})
    );

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
  }

  return (
    <>
      {showConfirmation && (
        <SweetAlert
          custom={true}
          customIcon={require("../../assets/img/sweetalert_warning.png")}
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          cancelBtnCssClass="custom-cancel-btn"
          confirmBtnCssClass="custom-btn"
          confirmBtnStyle={{ boxShadow: "0px 5px 30px #3369ffb3" }}
          title={t("CONFIRM_CLAIM")}
          onConfirm={() => {
            setShowConfirmation(false);
            handleSubmit(submitForm)();
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        ></SweetAlert>
      )}
      <Header
        history={_history}
        url={!window.ReactNativeWebView ? WebUrl._URL_LOYALTY_REDEMPTION : null}
        title={t("GAME_POINTS_DASHBOARD")}
      />
      <div
        id={
          _userData.selectedTheme === 0 ? "loyalty-pts" : "loyalty-pts-modern"
        }
        style={{ paddingBottom: "10px" }}
      >
        <div className="loyalty-redemption-homepage">
          <div className="game-points-container">
            <div className="first">
              <div className="custom-icon-box">
                <img src={require("../../assets/img/v3/points.svg")} alt="game-points" />
              </div>
              <div>
                {unclaimLoyaltyPoints == ""
                  ? t("LOADING") + "... "
                  : " " +
                  numberWithCurrencyFormat(
                    parseFloat(unclaimLoyaltyPoints.totalClaimable),
                    2
                  )}
              </div>
            </div>
            <button
              type="submit"
              className="second"
              disabled={unclaimLoyaltyPoints == "" || unclaimLoyaltyPoints["totalClaimable"] == 0}
              onClick={() => unclaimLoyaltyPoints["totalClaimable"] != 0 && preSubmit()}>
              {t("CLAIM")}
            </button>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "end" }}>
          <Link to={WebUrl._URL_LOYALTY_POINTS_HISTORY}>
            <div className="d-flex justify-content-end align-items-center loyalty-pts-btn">
              <div style={{ marginRight: "5px", fontSize: "12px" }}>
                {" "}
                {t("GAME_POINTS_HISTORY")}{" "}
              </div>
              <Icon
                path={mdiHistory}
                title="User Profile"
                size={1}
                color={_userData.selectedTheme === 0 ? "#002C68" : "#ffffff"}
              />
            </div>
          </Link>
        </div>

        <div className="hr-right mb-3"></div>
        <div className="mb-3">
          {t("CURRENT_SUMMARY")}
        </div>
        {unclaimLoyaltyPoints["data"] && unclaimLoyaltyPoints["data"].length > 0 && loaded ? (
          <>
            {unclaimLoyaltyPoints["data"].map((row, i) => {
              return (
                <>
                  <div className="box-list-with-shadow claim-game-point-item">
                    <div className="game-point-item">
                      <div className="first">
                        <div>
                          {t("TOTAL_GAME_POINTS")} - {numberWithCurrencyFormat(
                            parseFloat(row["totalLoyaltyPoints"]),
                            2,
                            true
                          )}
                        </div>
                        <div className="date-text">
                          {moment(row["loyaltyPointsDate"]).format("YYYY-MM-DD")}
                        </div>
                      </div>
                      {row["claimed"] ? (
                        <div className="green-label-box">
                          {t("CLAIMED")}
                        </div>
                      ) : (
                        <div className="red-label-box">
                          {t("UNCLAIMED")}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          </>
        ) : !loaded ? (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )
        : (
          <></>      
        )}
        {unclaimLoyaltyPoints["data"] && unclaimLoyaltyPoints["data"].length == 0 && (
          <div className="no-data">
            {t("NO_UNCLAIMED_DATA")}
          </div>
        )}
      </div>
    </>
  );
};

export default UnclaimLoyaltyPoints;
