import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine.js";
import moment from "moment";
import DateTime from "react-datetime";
import { useDispatch } from "react-redux";
import {
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage,
} from "../../redux/AppAction";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import { map } from "lodash";
import { stringIsNullOrEmpty } from "../../util/Util";
/// <summary>
/// Author: -
/// </summary>
const ManageReferralCode = (props) => {
  var { t } = useTranslation();
  var _history = useHistory();

  const [referralCodes, setReferralCodes] = useState([]);
  const _dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedReferral, setSelectedReferral] = useState({});
  const [swipeProgress, setSwipeProgress] = useState({});
  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_REFERRAL_CODES
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setReferralCodes(responseJson[ApiKey._API_DATA_KEY]);
    }
    _dispatch(setIdle());
  }

  async function deleteReferral(id) {
    try {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_DEL_REFERRAL + "?id=" + id
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        init();
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
    } catch (errorMessage) {
      _dispatch(showMessage(false, t(errorMessage)));
    } finally {
      _dispatch(setIdle());
    }
  }

  const trailingActions = (codeId) => (
    <TrailingActions>
      <SwipeAction
        
        onClick={() => {
          let tempSelectedReferral = referralCodes.filter(x => x.id === codeId)[0];
          setSelectedReferral(tempSelectedReferral);
          setConfirmDelete(true);
        }}
      >
        <div className="swipe-delete">
          <img
            src={require("../../assets/img/v3/delete.png")}

            alt="icon"
          />
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  useEffect(() => {
    if (swipeProgress[0] > 90) {
      let tempSelectedReferral = referralCodes.filter(x => x.id === swipeProgress[1])[0];
      setSelectedReferral(tempSelectedReferral);
      setConfirmDelete(true);
    }
  }, [swipeProgress]);

  return (
    <div>
      <Header
        history={_history}
        url={WebUrl._URL_MAIN}
        title={t("REFERRAL_CODE")}
      />
      <div className="custom-box-two-col-container referral-buttons">
        <div 
          className="first w-100" 
          onClick={() =>
              _history.push({
                pathname: WebUrl._URL_REFERRAL_CODE_DETAIL,
              })
            }>
          {t("CREATE") + " " + t("REFERRAL_CODE")}
        </div>
        {/* <div className="second">
          {t("Referral Group")}
        </div> */}
      </div>
      <div className="hr-left mt-3 mb-3"></div>
      {/*<div className="" style={{ paddingBottom: "70px" }}>*/}
     
      {/*  {referralCodes.length == 0 && (*/}
      {/*    <div className="card-body">{t("NO_REFERRAL_CODE_YET")}</div>*/}
      {/*  )}*/}
      {/*  {referralCodes.length > 0 && (*/}
      {/*    <ul className="ul-news-list">*/}
      {/*      {referralCodes.map((referralCode, index) => {*/}
      {/*        return (*/}
      {/*          <li key={index}>*/}
      {/*            <i*/}
      {/*              id="delReferral"*/}
      {/*              className="fas fa-trash d-flex align-items-center"*/}
      {/*              style={{ color: "#fff" }}*/}
      {/*              onClick={() => {*/}
      {/*                setSelectedReferral(referralCode);*/}
      {/*                setConfirmDelete(true);*/}
      {/*              }}*/}
      {/*            ></i>*/}
      {/*            */}{/* <div */}{/*
      */}{/*              id="delReferral"*/}{/*
      */}{/*              className="referral-delete-icon"*/}{/*
      */}{/*              onClick={() => {*/}{/*
      */}{/*                setSelectedReferral(referralCode);*/}{/*
      */}{/*                setConfirmDelete(true);*/}{/*
      */}{/*              }}>*/}{/*
      */}{/*              <img*/}{/*
      */}{/*                src={require("../../assets/img/v3/delete.png")}*/}{/*
      */}{/*                alt="icon"*/}{/*
      */}{/*              />*/}{/*
      */}{/*            </div> */}
      {/*            <Link*/}
      {/*              to={{*/}
      {/*                pathname: WebUrl._URL_REFERRAL_CODE_DETAIL,*/}
      {/*                state: { id: referralCode.id },*/}
      {/*              }}*/}
      {/*            >*/}
      {/*              <div*/}
      {/*                className="d-flex align-items-center"*/}
      {/*                style={{*/}
      {/*                  whiteSpace: "nowrap",*/}
      {/*                  overflow: "hidden",*/}
      {/*                  textOverflow: "ellipsis",*/}
      {/*                }}*/}
      {/*              >*/}
      {/*                  {referralCode.code}*/}
      {/*                  {referralCode.remark ? ` - ${referralCode.remark}` : ""}*/}
      {/*              </div>*/}
      {/*              <i className="fas fa-chevron-right"></i>*/}
      {/*            </Link>*/}
      {/*          </li>*/}
      {/*        );*/}
      {/*      })}*/}
      {/*    </ul>*/}
      {/*  )}*/}
      {/*</div>*/}
      {/* <div
        className="content content-full-width"
        style={{ position: "fixed", bottom: "20px", right: "0", width: "100%" }}
      >
        <div className="container">
          <button
            type="button"
            className="btn btn-block btn-brand"
            onClick={() =>
              _history.push({
                pathname: WebUrl._URL_REFERRAL_CODE_DETAIL,
              })
            }
          >
            {t("CREATE")}
          </button>
        </div>
      </div> */}
      <div className="pb-5">
        {referralCodes &&
          referralCodes.length > 0 ?
          map(referralCodes, (item, index) => {
            return (
              <SwipeableList
                fullSwipe={false}
                type={ListType.IOS}
                
              >
                <SwipeableListItem
                  trailingActions={trailingActions(item.id)}
                  onSwipeProgress={progress =>
                    setSwipeProgress(
                      [
                        progress,
                        item.id
                      ])}
                >
                  <div className="referral-item-list">
                    <Link
                      to={{
                        pathname: WebUrl._URL_REFERRAL_CODE_DETAIL,
                        state: { id: item.id },
                      }}
                      className="referral-code-link"
                    >
                        <div className="first">
                          <div>{item.code} {!stringIsNullOrEmpty(item.remark) && `(` + item.remark + `)`}</div>
                        </div>
                        <div className="second">
                          <img
                            src={require("../../assets/img/v3/arrow-right.png")}
                            alt="search"
                          />
                        </div>
                    </Link>
                  </div>
                </SwipeableListItem>
              </SwipeableList>
            );
          })
          :
          <div style={{ textAlign: "center" }}>{t("NO_DATA_FOUND")}</div>}
      </div>
        <SweetAlert
          show={confirmDelete}
          custom={true}
          customIcon={require("../../assets/img/sweetalert_warning.png")}
          title={t("DELETE_REFERRAL", selectedReferral.code) + "?"}
          showCancel
          cancelBtnText={t("CANCEL")}
          confirmBtnText={t("CONFIRM")}
          cancelBtnCssClass="custom-cancel-btn"
          confirmBtnCssClass="custom-btn"
          confirmBtnStyle={{ boxShadow: "0px 5px 30px #3369ffb3" }}
          onConfirm={() => {
            deleteReferral(selectedReferral.id);
            setConfirmDelete(false);
          }}
          onCancel={() => {
            setConfirmDelete(false);
          }}
        />
    </div>
  );
};

export default ManageReferralCode;
