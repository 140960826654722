import React from 'react';
import Select from 'react-select';

/// <summary>
/// Author : -
/// </summary>
const StyledSelect = props => {
    const { options, placeholder = "", value, onChange, ...otherProps } = props;
    const _SELECT_STYLES = {
        menu: (styles, state) => ({
            ...styles,
        }),
        control: (styles, state) => ({
            ...styles,
            borderRadius: '50px',
            boxShadow: '0px 3px 17px 0px rgba(0,0,0,.1)',
            height: '35px',
            fontSize: '.8rem'
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles
            };
        },
        singleValue: styles => ({
            ...styles,
            paddingLeft: '1.2rem',
            fontSize: '14px',
            color: '#A4A4A4',
            fontWeight: 'bold'
        }),
        placeholder: styles => ({
            ...styles,
            fontSize: '14px',
            color:'#A4A4A4',
        })
    }

    return (
        <Select
            options={options} isSearchable={false}
            styles={_SELECT_STYLES}
            placeholder={(options.filter(option => option.value == value)[0] !== undefined) ? (options.filter(option => option.value == value)[0].label) : placeholder}
            value={options.filter(option => option.value == value)}
            onChange={(e) => {
                onChange(e);
            }}
            {...otherProps} />
    )
}

export default StyledSelect;