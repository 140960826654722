import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { stringIsNullOrEmpty } from "../../util/Util";
import Icon from '@mdi/react'
import { mdiEmailOpenOutline, mdiCurrencyUsd } from '@mdi/js'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import Header from "../../components/custom/Header";


/// <summary>
/// Author: -
/// </summary>
const NotificationDetails = () => {
    var { t } = useTranslation();
    var _history = useHistory();
    var _location = useLocation();
    var activeTheme = useSelector(state => state.authState.userData.selectedTheme);
    const _SELECTIONS = {
        "Deposit SMS": "DEPOSIT",
        "Withdrawal SMS": "WITHDRAWAL",
        "Reset Password SMS": "RESET_PASSWORD",
        "Phone Verfication SMS": "PHONE_VERIFICATION",
        "PIN Reset": "RESET_PIN"
    }

    const [announcement, setAnnouncement] = useState({});

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (_location['state'] && !stringIsNullOrEmpty(_location['state']['data'])) {
            var data = JSON.parse(_location['state']['data'])
            setAnnouncement(data);

            if (!data['isRead']) {
                readAnnouncement(data['id']);
            }
        }
        else {
            _history.goBack();
        }
    }, [_location['state']]);

    /// <summary>
    /// Author: -
    /// </summary>
    async function readAnnouncement(id) {
        var responseJson = await ApiEngine.post(`${ApiUrl._API_MARK_ANNOUNCEMENT_AS_READ}?announcementId=${id}`);

        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            _history.goBack();
        }
    }

    return (
        <div id={activeTheme === 0 ? "news-details" : "news-details-modern"}>

            <div>
                <Header history={_history} url={WebUrl._URL_NEWS_BONUS} title={announcement['title']} />
                {/*<Link to={{ pathname: WebUrl._URL_NEWS }}>
                            <button type="button" className="btn btn-icon"><i className="fa fa-arrow-left"></i></button>
                        </Link>*/}
            </div>
            <div className="inbox-icon-cont">
                {(announcement['title'] == "Deposit SMS" || (announcement['title'] == "Withdrawal SMS")) &&
                    <Icon path={mdiCurrencyUsd}
                        color="#FFD928" />
                }
                {(announcement['title'] != "Deposit SMS" && (announcement['title'] != "Withdrawal SMS")) &&
                    <Icon path={mdiEmailOpenOutline}
                        color="#FFD928" />
                }
            </div>
            <div className="container mt-3">
                <div className="news-detail">
                    <h3 style={{ color: "#3369FF", fontSize: "14px" }} className="text-brand">{announcement['title'] == "Deposit SMS" || announcement['title'] == "Withdrawal SMS" ? t(_SELECTIONS[announcement['title']]) : announcement['title']}</h3>
                    <p className="text-brand mb-3" style={{ color: "#898F97", fontSize: "10px" }}>{moment(announcement['date']).format('DD-MM-YYYY HH:mm:ss')}</p>
                    <div className="text-brand" style={{ color: "#898F97",  fontSize: "12px" }} dangerouslySetInnerHTML={{ __html: announcement['description'] }} />
                </div>
            </div>
        </div>
    );
};

export default NotificationDetails;