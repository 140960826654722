import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { WebUrl, ApiUrl, ApiKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { showResponseMessage, setBusy, setIdle, setY, setPageSize } from "../../redux/AppAction";
import { numberWithCurrencyFormat } from "../../util/Util";
import moment from "moment";

/// <summary>
/// Author: -
/// </summary>
const ShareholderDetails = props => {
    var _userData = useSelector((state) => state.authState.userData);
    var _history = useHistory();
    var { t } = useTranslation();
    var _dispatch = useDispatch();
    var _location = useLocation();
    var loginUserSummaryHidden = useSelector(state => state.authState.userData.hideSummary);

    const [downlineData, setDownlineData] = useState({});
    const [products, setProducts] = useState([]);
    const [nickname, setNickname] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loginHideSummary, setLoginHideSummary] = useState(false);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (_location['state']) {
            setLoginHideSummary(_location.state.hideSummary);
            init();
            _dispatch(setPageSize(_location["scrollIndex"]));
        }
        else {
            _history.goBack();
        }
    }, [_location['state']]);

    /// <summary>
    /// Author: -
    /// </summary>
    async function init() {
        _dispatch(setBusy());
        try {
            var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_MEMBER_DETAILS_BY_ID}?memberId=${_location['state']['id']}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setDownlineData(responseJson[ApiKey._API_DATA_KEY]);

            responseJson = await ApiEngine.get(`${ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT}?memberId=${_location['state']['id']}&ignoreAccountProductStatus=true`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setProducts(responseJson[ApiKey._API_DATA_KEY].products);
            setNickname(responseJson[ApiKey._API_DATA_KEY].nickname);
        }
        catch (errorMessage) {
            _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
        }
        finally {
            _dispatch(setIdle());
        }
    }
    
    return (
        <>
            <Header history={_history} url={WebUrl._URL_SHAREHOLDER + "?GAzobE73Vse4nr8WpufA=" + loginHideSummary} title={t('SHAREHOLDERDETAIL')} />
            <div className="container">
                <div className="card mb-4">
                    <div className="card-body">
                        <table className="table table-description text-brand">
                            <tbody>
                                <tr>
                                    <th>{t("USERNAME")}</th>
                                    <td>{downlineData['username']}</td>
                                </tr>
                                <tr>
                                    <th>{t("FULLNAME")}</th>
                                    <td>{downlineData['userFullName']}</td>
                                </tr>
                                <tr>
                                    <th>{t("NICKNAME")}</th>
                                    <td>{downlineData['nickname']}</td>
                                </tr>
                                {
                                    downlineData['isShareHolder'] == 1 && <>
                                            <tr>
                                                <th>{t("ISSHAREHOLDER")}</th>
                                                <td>
                                                    <span className="badge badge-yes d-block mb-1">{t("YES")}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t("SHAREHOLDERDATE")}</th>
                                            <td>{moment(downlineData['shareHolderDate']).format("DD/MM/YYYY") }</td>
                                            </tr>
                                            <tr>
                                            <th>{t("SHAREPERCENT")}</th>
                                            <td>{
                                                downlineData['sharePercent']}%</td>
                                            </tr>
                                            {!loginUserSummaryHidden && <tr>
                                                <th>{t("HIDE_SUMMARY")}</th>
                                                <td>
                                                    {downlineData["hideSummary"] ? <span className="badge badge-yes d-block mb-1">{t("YES")}</span> : <span className="badge d-block badge-no mb-1">{t("NO")}</span>}
                                                </td>
                                            </tr>}
                                    </>
                                }

                                {
                                    downlineData['isShareHolder'] != 1 && <>
                                        <tr>
                                            <th>{t("ISSHAREHOLDER")}</th>
                                            <td>
                                                <span className="badge d-block badge-no mb-1">{t("NO")}</span>
                                            </td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </table>
                        <div className="text-center">
                            {
                                downlineData['isShareHolder'] == 1 && <>
                                    <Link className="brand-link" to={{ pathname: WebUrl._URL_EDIT_SHAREHOLDER, state: { id: downlineData['id'], memberHideSummary: loginHideSummary } }}>
                                        {t("EDITSHAREHOLDER")} <i className="fas fa-chevron-right ml-3"></i>
                                    </Link>
                                </>
                            }
                            {
                                downlineData['isShareHolder'] != 1 && <>
                                    <Link className="brand-link" to={{ pathname: WebUrl._URL_EDIT_SHAREHOLDER, state: { id: downlineData['id'], memberHideSummary: loginHideSummary } }}>
                                        {t("ADDSHAREHOLDER")} <i className="fas fa-chevron-right ml-3"></i>
                                    </Link>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ShareholderDetails;