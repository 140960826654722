import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { map } from "lodash";
import Header from '../../components/custom/Header';
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant.js";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import { showResponseMessage } from "../../redux/AppAction";

/// <summary>
/// Author :
/// </summary>
const CreateReferral = (props) => {
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const _history = useHistory();
  const [product, setProduct] = useState({});
  const [products, setProducts] = useState([]);
  const [valueList, setValueList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [initCheck, setInitCheck] = useState(false);

  const { register, handleSubmit, setValue, errors } = useForm();
  const commissionList = [
    {
      id: 1,
      category: "Connect",
      comm_max: '0.6%',
      bonus_max: '',
      comm: '0.60',
      bonus: '',
      games: [
        {
          name: '93 Connect',
          comm_max: '0.6%',
          bonus_max: '',
          comm: '0.60',
          bonus: '0',
        }
      ]
    },
    {
      id: 2,
      category: "Lottery",
      comm_max: '0.6%',
      bonus_max: '0.6%',
      comm: '0.30',
      bonus: '0.15',
      games: [
        {
          name: 'JJVIP',
          comm_max: '0.6%',
          bonus_max: '0.6%',
          comm: '0.00',
          bonus: '0.10',
        },
        {
          name: 'JJVIP 1',
          comm_max: '0.6%',
          bonus_max: '0.6%',
          comm: '0.30',
          bonus: '0.05',
        },
        {
          name: 'JJGD',
          comm_max: '0.6%',
          bonus_max: '0.6%',
          comm: '0.00',
          bonus: '0.00',
        },
        {
          name: 'JJ4D',
          comm_max: '0.6%',
          bonus_max: '0.6%',
          comm: '0.00',
          bonus: '0.00',
        },
      ]
    },
  ];

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_PRODUCT_RATES);
    let uplineProductRates = [];
    let processedProducts = [];
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      uplineProductRates = responseJson[ApiKey._API_DATA_KEY];
    }
    else {
      _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
    }

    var productResponseJson = await ApiEngine.get(ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT);
    if (productResponseJson[ApiKey._API_SUCCESS_KEY]) {
      productResponseJson[ApiKey._API_DATA_KEY].products.map((product) => {
        let uplineProduct = uplineProductRates.filter(i => i.accountProductId == product.accountProductId)[0];
        processedProducts.push({
          id: product.id,
          productId: product.productId,
          accountProductId: product.accountProductId,
          productName: product.productName + (product.useGroupRate ? " *" : ""),
          assigned: product.assigned,
          bonus: product.bonus,
          commission: product.commission,
          clubFee: product.clubFee,
          categoryName: product.categoryName,
          maxBonus: (uplineProduct != null) ? uplineProduct.bonus : 0,
          maxCommission: (uplineProduct != null) ? uplineProduct.commission : 0,
          maxClubFee: (uplineProduct != null) ? uplineProduct.clubFee : 0,
          useGroupRate: product.useGroupRate
        });
      });
    }
    else {
      throw productResponseJson[ApiKey._API_MESSAGE_KEY];
    }

    setProduct(products['length'] == 0 ? processedProducts[0] : processedProducts.find(x => x['id'] == product['id']));
    setProducts(processedProducts);

    let categoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (categoryJson[ApiKey._API_SUCCESS_KEY]) {
      let categoryData = categoryJson[ApiKey._API_DATA_KEY];
      setCategories(categoryData);
      let tempObj = {};
      let tempHighestMaxBonus = 0.00;
      let tempHighestMaxComm = 0.00;
      let tempHighestMaxClubFee = 0.00;
      categoryData.map((category, index) => {
        if (!tempObj[category.originalTitle]) {
          tempObj[category.originalTitle] = { bonusValues: [], commValues: [], clubFeeValues: [], highestMaxBonus: 0, highestMaxComm: 0, highestMaxClubFee: 0 };
        }
        processedProducts.filter(x => x.categoryName === category.originalTitle).map((product, i) => {
          if (i === 0) { tempHighestMaxBonus = product.maxBonus; tempHighestMaxComm = product.maxCommission; tempHighestMaxClubFee = product.maxClubFee; }
          if (!tempObj[category.originalTitle][product.productName])
            tempObj[category.originalTitle][product.productName] = { bonusValues: [], commValues: [], clubFeeValues: [], };
          if (product.maxBonus > tempHighestMaxBonus) {
            tempHighestMaxBonus = product.maxBonus;
          }
          if (product.maxCommission > tempHighestMaxComm) {
            tempHighestMaxComm = product.maxCommission;
          }
          if (product.maxClubFee > tempHighestMaxClubFee) {
            tempHighestMaxClubFee = product.maxClubFee;
          }
          if (product.maxBonus > 0) {
            for (var i = 0.00; parseFloat(i.toFixed(2)) <= parseFloat(product.maxBonus.toFixed(2)); i += 0.1) {

              i = i * 100;
              i = Math.round(i);
              i = i / 100;
              i = parseFloat(i.toFixed(2));
              if (i === 0.00) {
                tempObj[category.originalTitle][product.productName].bonusValues.push('-');
              }
              tempObj[category.originalTitle][product.productName].bonusValues.push(i);
            };
          }
          if (product.maxCommission > 0) {
            for (var i = 0.00; parseFloat(i.toFixed(2)) <= parseFloat(product.maxCommission.toFixed(2)); i += 0.1) {

              i = i * 100;
              i = Math.round(i);
              i = i / 100;
              i = parseFloat(i.toFixed(2));

              if (i === 0.00) {
                tempObj[category.originalTitle][product.productName].commValues.push('-');
              }
              tempObj[category.originalTitle][product.productName].commValues.push(i);
            };
          }
          if (product.maxClubFee > 0) {
            for (var i = 0.00; parseFloat(i.toFixed(2)) <= parseFloat(product.maxClubFee.toFixed(2)); i += 5.0) {

              i = i * 100;
              i = Math.round(i);
              i = i / 100;
              i = parseFloat(i.toFixed(2));

              if (i === 0.00) {
                tempObj[category.originalTitle][product.productName].clubFeeValues.push('-');
              }
              tempObj[category.originalTitle][product.productName].clubFeeValues.push(i);
            };
          }
        });

        tempObj[category.originalTitle]['highestMaxBonus'] = tempHighestMaxBonus;
        tempObj[category.originalTitle]['highestMaxComm'] = tempHighestMaxComm;
        tempObj[category.originalTitle]['highestMaxClubFee'] = tempHighestMaxClubFee;
        for (var i = 0.00; parseFloat(i.toFixed(2)) <= (parseFloat(tempHighestMaxBonus.toFixed(2))); i += 0.1) {

          i = i * 100;
          i = Math.round(i);
          i = i / 100;
          i = parseFloat(i.toFixed(2));

          if (i === 0.00) {
            tempObj[category.originalTitle].bonusValues.push('-');
          }
          tempObj[category.originalTitle].bonusValues.push(i);
        };
        for (var i = 0.00; parseFloat(i.toFixed(2)) <= (parseFloat(tempHighestMaxComm.toFixed(2))); i += 0.1) {

          i = i * 100;
          i = Math.round(i);
          i = i / 100;
          i = parseFloat(i.toFixed(2));

          if (i === 0.00) {
            tempObj[category.originalTitle].commValues.push('-');
          }
          tempObj[category.originalTitle].commValues.push(i);
        };
        for (var i = 0.00; parseFloat(i.toFixed(2)) <= (parseFloat(tempHighestMaxClubFee.toFixed(2))); i += 5.0) {

          i = i * 100;
          i = Math.round(i);
          i = i / 100;
          i = parseFloat(i.toFixed(2));

          if (i === 0.00) {
            tempObj[category.originalTitle].clubFeeValues.push('-');
          }
          tempObj[category.originalTitle].clubFeeValues.push(i);
        };
      })
      setValueList(tempObj);
    }
    setInitCheck(true);
  }

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_MAIN}
        title={t("CREATE") + " " + t("REFERRAL")}
        hasBackButton={true}
      />
      <h4 className="mb-3">Remarks:</h4>
      <div className="custom-input-container">
        <input
          name="remark"
          ref={register()}
          placeholder={t("REMARK")}
        />
      </div>
      <div className="referral-commission-container mt-4 pb-2">
        {categories &&
          categories.length > 0 &&
          map(categories, (item, index) => {
            return (
              <>
                {/* //////////for category display commission/// */}
                <div className="referral-commission-item">
                  <div className="first">
                    {item.title}
                  </div>
                  <div className="second">
                    <div className="title">
                      Comm<br/>Max: {item.comm_max}
                    </div>
                    <div className="add-minus-box">
                      <div>
                        <img src={require("../../assets/img/v3/minus.png")} alt="minus" />
                      </div>
                      <div>
                        <input type='text' value={item.comm}/>
                      </div>
                      <div>
                        <img src={require("../../assets/img/v3/add.png")} alt="minus" />
                      </div>
                    </div>
                    <div className="btn-max">
                      MAX
                    </div>
                  </div>
                </div>

                {/* /////for bonus of the category///// */}
                {item.bonus && 
                  <div className="referral-commission-item rc-no-bg">
                    <div className="first">
                      {" "}
                    </div>
                    <div className="second">
                      <div className="title">
                        Bonus<br/>Max: {item.bonus_max}
                      </div>
                      <div className="add-minus-box">
                        <div>
                          <img src={require("../../assets/img/v3/minus.png")} alt="minus" />
                        </div>
                        <div>
                          <input type='text' value={item.bonus}/>
                        </div>
                        <div>
                          <img src={require("../../assets/img/v3/add.png")} alt="minus" />
                        </div>
                      </div>
                      <div className="btn-max">
                        MAX
                      </div>
                    </div>
                  </div>
                }

                {/* /////for game list///// */}
                {item.games &&
                  item.games.length &&
                  map(item.games, (item1, index1) => {
                    return (
                      <>
                        {item1.comm && 
                          <div className="referral-commission-item rc-no-bg">
                            <div className="first rc-game-name">
                              {item1.name}
                            </div>
                            <div className="second">
                              <div className="title">
                                Comm<br/>Max: {item1.comm_max}
                              </div>
                              <div className="add-minus-box">
                                <div>
                                  <img src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                </div>
                                <div>
                                  <input type='text' value={item1.comm}/>
                                </div>
                                <div>
                                  <img src={require("../../assets/img/v3/add.png")} alt="minus" />
                                </div>
                              </div>
                              <div className="btn-max">
                                MAX
                              </div>
                            </div>
                          </div>
                        }
                        {item1.bonus && 
                          <div className="referral-commission-item rc-no-bg">
                            <div className="first">
                              {" "}
                            </div>
                            <div className="second">
                              <div className="title">
                                Bonus<br/>Max: {item1.bonus_max}
                              </div>
                              <div className="add-minus-box">
                                <div>
                                  <img src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                </div>
                                <div>
                                  <input type='text' value={item1.bonus}/>
                                </div>
                                <div>
                                  <img src={require("../../assets/img/v3/add.png")} alt="minus" />
                                </div>
                              </div>
                              <div className="btn-max">
                                MAX
                              </div>
                            </div>
                          </div>
                        }
                      </>
                    );
                  })
                }
                {/* /////separator every new category odd and even///// */}
                {index % 2 == 0 ? 
                (
                  <div className="hr-right mt-3 mb-3"></div>
                ):(
                  <div className="hr-left mt-3 mb-3"></div>
                )}
              </>
            );
          })}
      </div>

    </>
  );
};

export default CreateReferral;
