import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Input from "../../components/custom/Input";
import RangeSlider from "../../components/custom/RangeSlider";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine";
import StyledSelect from "../../components/custom/StyledSelect";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { createMultiPartFormBody, isObjectEmpty } from "../../util/Util";
import PlusIcon from "../../assets/img/icon/ionic-ios-add-circle-outline.svg";
import MinusIcon from "../../assets/img/icon/ionic-ios-minus-circle-outline.svg";
import BluePlusIcon from "../../assets/img/icon/Add_Icon.svg";
import BlueMinusIcon from "../../assets/img/icon/Minus_Icon.svg";
import GoldPlusIcon from "../../assets/img/icon/goldPlus.svg";
import GoldMinusIcon from "../../assets/img/icon/goldMinus.svg";

/// <summary>
/// Author: -
/// </summary>
const EditDownline = (props) => {
  var { t } = useTranslation();
  var _history = useHistory();
  var _dispatch = useDispatch();
  var _location = useLocation();
  var _userData = useSelector((state) => state.authState.userData);

  const [member, setMember] = useState([]);
  const [nickname, setNickname] = useState("");
  const [initCheck, setInitCheck] = useState(false);

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [categories, setCategories] = useState([]);
  const [valueList, setValueList] = useState([]);

  const [hasProduct, setHasProduct] = useState(false);
  const { register, handleSubmit, errors, setValue, watch, control, trigger } =
    useForm();
  const {
    register: registerNickname,
    handleSubmit: handleSubmitNickname,
    errors: errorsNickname,
    setValue: setValueNickname,
    watch: watchNickname,
    control: controlNickname,
    trigger: triggerNickname,
  } = useForm();

  const backgroundLightMaxButton = "linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)";
  const backgroundDarkMaxButton = "linear-gradient(0deg, rgba(51, 105, 255, 0.5), rgba(51, 105, 255, 0.5)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)";

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (_location["state"]) {
      init();
    } else {
      _history.goBack();
    }
  }, [_location["state"]]);

  const EditDownlineForm = () => {
    if (initCheck) {
      return (
        <div className="downline-form-container" id="referral-detail-modern">
          {products.length > 0 ? (
            <>
              <table className="edit-referral-table">
                <thead></thead>
                  <tbody>
                  {categories.map((category, index) => {
                    if (
                      products.filter(
                        (x) =>
                          x.categoryName === category.originalTitle &&
                          (x.maxBonus > 0 ||
                            x.maxCommission > 0 ||
                            x.maxClubFee > 0)
                      ).length > 0
                    ) {
                      return (
                        <Fragment key={index}>
                          {products.filter(
                            (x) =>
                              x.categoryName === category.originalTitle &&
                              x.maxBonus > 0
                          ).length > 0 ? 
                          (
                            <>
                              {/* {!products.filter(
                                (x) =>
                                  x.categoryName === category.originalTitle &&
                                  x.maxBonus > 0
                              )[0] && <hr className="edit-referral-hr" />} */}
                              {index % 2 == 0 ? 
                                (
                                  <div className="hr-right mt-3 mb-3"></div>
                                ):(
                                <div className="hr-left mt-3 mb-3"></div>
                                )
                              }
                                <tr className="data-row category" style={{
                                          borderBottomLeftRadius: valueList[
                                              category.originalTitle
                                          ].highestMaxBonus.toFixed(2) > 0 ? "0" : "8px", borderBottomRightRadius: valueList[
                                              category.originalTitle
                                          ].highestMaxBonus.toFixed(2) > 0 ? "0" : "8px",
                                          boxShadow: valueList[
                                              category.originalTitle
                                          ].highestMaxBonus.toFixed(2) > 0 ? "0px 0px 0px rgba(0, 0, 0, 0)" : "0px 4px 7px rgba(0, 0, 0, 0.3)"
                                      }}
                                      >
                                <td className="row-data-1">
                                  <b>{category.title}</b>
                                </td>
                                <td className="row-data-2">
                                  <div className="txt-1">{t("BONUS")}</div>
                                  <div className="txt-2">
                                    {t("MAX") +
                                      ":" +
                                      valueList[
                                        category.originalTitle
                                      ].highestMaxBonus.toFixed(2) +
                                      "%"}
                                  </div>
                                </td>
                                <td className="row-data-3">
                                  <Controller
                                    control={control}
                                    name={"category-bonus-" + category.id}
                                    defaultValue={0}
                                    render={({ onChange, value }) => {
                                      let valueNotLowest =
                                        checkValueForLowest(value);
                                      let valueNotHighest =
                                        checkValueForHighest(
                                          value,
                                          category,
                                          "bonus"
                                        );

                                        if(document.getElementById("category-" + category.id + "-bonus") !== null) {
                                          if (!valueNotHighest) {
                                            document.getElementById("category-" + category.id + "-bonus").style.background = backgroundDarkMaxButton;
                                          }
                                          else {
                                            document.getElementById("category-" + category.id + "-bonus").style.background = backgroundLightMaxButton;
                                          }
                                        }
                                      return (
                                          <>
                                              <div className="add-minus-box">
                                                  <div
                                                      onClick={(e) => {
                                                          if (valueNotLowest === true) {
                                                              value -= 0.1;
                                                              value *= 100;
                                                              value = Math.round(value);
                                                              value /= 100;
                                                              setValue(
                                                                  "category-bonus-" +
                                                                  category.id,
                                                                  value
                                                              );
                                                              handleCatBtn(
                                                                  "bonus",
                                                                  category.id,
                                                                  category.originalTitle,
                                                                  value
                                                              );
                                                          }
                                                      }}
                                                  >
                                                      <img style={{ opacity: valueNotLowest ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                                  </div>
                                                  <select
                                                      className="bonusComm-option-select"
                                                      value={parseFloat(
                                                          value.toFixed(2)
                                                      )}
                                                      placeholder={value.toFixed(2)}
                                                      onChange={(e) => {
                                                          handleSelectCatValue(
                                                              "bonus",
                                                              category.id,
                                                              category.originalTitle,
                                                              e.target.value
                                                          );
                                                      }}
                                                  >
                                                      {valueList[
                                                          category.originalTitle
                                                      ].bonusValues.map(
                                                          (value, index) => {
                                                              return (
                                                                  <option
                                                                      key={index}
                                                                      className="bonusComm-option-value"
                                                                      value={parseFloat(
                                                                          value.toFixed(2)
                                                                      )}
                                                                  >
                                                                      {value.toFixed(2)}
                                                                  </option>
                                                              );
                                                          }
                                                      )}
                                                  </select>
                                                  <div
                                                    onClick={(e) => {
                                                        if (valueNotHighest === true) {
                                                            value += 0.1;
                                                            value *= 100;
                                                            value = Math.round(value);
                                                            value /= 100;
                                                            setValue(
                                                                "category-bonus-" +
                                                                category.id,
                                                                value
                                                            );
                                                            handleCatBtn(
                                                                "bonus",
                                                                category.id,
                                                                category.originalTitle,
                                                                value
                                                            );
                                                        }
                                                    }}
                                                  >
                                                      <img style={{ opacity: valueNotHighest ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="add" />
                                                  </div>
                                              </div>
                                              {errors.editPercent && (
                                                  <div className="invalid-feedback">
                                                      {t(errors.editPercent.message)}
                                                  </div>
                                              )}

                                        </>
                                      );
                                    }}
                                  />
                                </td>
                                <td className="row-data-4">
                                  <div
                                    className="row-data-max"
                                    id={"category-" + category.id + "-bonus"}
                                    onClick={() =>
                                      handleMaxCatBtn(
                                        category.id,
                                        category.originalTitle,
                                        "bonus"
                                      )
                                    }
                                  >
                                    {t("MAX")}
                                  </div>
                                </td>
                              </tr>
                            </>
                          )
                          :
                          (
                            <>
                              {category.originalTitle == "Lottery" ?
                                <div className="hr-right mt-3 mb-3"></div>
                                : null
                              }
                            </>
                          )
                        }
                          {/* {products.filter(
                            (x) =>
                              x.categoryName === category.originalTitle &&
                              x.maxBonus > 0
                          ).length <= 0 && <hr className="edit-referral-hr" />} */}
                        
                        {category.originalTitle != "Lottery"?
                          (index % 2 == 0  ? 
                            (
                              <div className="hr-right mt-3 mb-3"></div>
                            ):(
                              <div className="hr-left mt-3 mb-3"></div>
                            )
                          ):null
                        }

                          {products.filter(
                            (x) =>
                              x.categoryName === category.originalTitle &&
                              x.maxCommission > 0
                          ).length > 0 && (
                            <tr
                              className="data-row category"
                              style={{
                                marginTop:
                                  products.filter(
                                    (x) =>
                                      x.categoryName ===
                                        category.originalTitle && x.maxBonus > 0
                                  ).length > 0
                                    ? "0"
                                          : "25px",
                                  borderTopLeftRadius: valueList[
                                              category.originalTitle
                                          ].highestMaxBonus.toFixed(2) > 0 ? "0" : "8px", borderTopRightRadius: valueList[
                                              category.originalTitle
                                  ].highestMaxBonus.toFixed(2) > 0 ? "0" : "8px",
                                   
                              }}
                            >
                              <td
                                className="row-data-1"
                              >
                                {products.filter(
                                  (x) =>
                                    x.categoryName === category.originalTitle &&
                                    x.maxBonus > 0
                                ).length > 0 ? (
                                  ""
                                ) : (
                                  <b>{category.title}</b>
                                )}
                              </td>
                              <td className="row-data-2">
                                <div className="txt-1">
                                  {t("COMMISSION_SHORT")}
                                </div>
                                <div className="txt-2">
                                  {t("MAX") +
                                    ":" +
                                    valueList[
                                      category.originalTitle
                                    ].highestMaxComm.toFixed(2) +
                                    "%"}
                                </div>
                              </td>
                              <td className="row-data-3">
                                {products.filter(
                                  (x) =>
                                    x.categoryName === category.originalTitle &&
                                    x.maxCommission > 0
                                ).length > 0 && (
                                  <>
                                    <Controller
                                      control={control}
                                      name={
                                        "category-commission-" + category.id
                                      }
                                      defaultValue={0}
                                      render={({ onChange, value }) => {
                                        let valueNotLowest =
                                          checkValueForLowest(value);
                                        let valueNotHighest =
                                          checkValueForHighest(
                                            value,
                                            category,
                                            "commission"
                                          );

                                          if(document.getElementById("category-" + category.id + "-commission") !== null) {
                                            if (!valueNotHighest) {
                                              document.getElementById("category-" + category.id + "-commission").style.background = backgroundDarkMaxButton;
                                            }
                                            else {
                                              document.getElementById("category-" + category.id + "-commission").style.background = backgroundLightMaxButton;
                                            }
                                          }
                                        return (
                                          <>
                                          <div className="add-minus-box">
                                            <div
                                              onClick={(e) => {
                                                if (valueNotLowest === true) {
                                                  value -= 0.1;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                  setValue(
                                                    "category-commission-" +
                                                      category.id,
                                                    parseFloat(value.toFixed(2))
                                                  );
                                                  handleCatBtn(
                                                    "commission",
                                                    category.id,
                                                    category.originalTitle,
                                                    value
                                                  );
                                                }
                                              }}
                                            >
                                              <img style={{ opacity: valueNotLowest ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                            </div>
                                            <select
                                              className="bonusComm-option-select"
                                              value={parseFloat(
                                                value.toFixed(2)
                                              )}
                                              placeholder={value.toFixed(2)}
                                              onChange={(e) => {
                                                handleSelectCatValue(
                                                  "commission",
                                                  category.id,
                                                  category.originalTitle,
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              {valueList[
                                                category.originalTitle
                                              ].commValues.map(
                                                (value, index) => {
                                                  return (
                                                    <option
                                                      key={index}
                                                      className="bonusComm-option-value"
                                                      value={parseFloat(
                                                        value.toFixed(2)
                                                      )}
                                                    >
                                                      {value.toFixed(2)}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                            <div
                                              onClick={(e) => {
                                                if (valueNotHighest === true) {
                                                  value += 0.1;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                  setValue(
                                                    "category-commission-" +
                                                      category.id,
                                                    parseFloat(value.toFixed(2))
                                                  );
                                                  handleCatBtn(
                                                    "commission",
                                                    category.id,
                                                    category.originalTitle,
                                                    value
                                                  );
                                                }
                                              }}
                                            >
                                              <img style={{ opacity: valueNotHighest ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="add" />
                                            </div>
                                            </div>
                                            {errors.editPercent && (
                                              <div className="invalid-feedback">
                                                {t(errors.editPercent.message)}
                                              </div>
                                            )}
                                            
                                          </>
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              </td>
                              <td className="row-data-4">
                                <div
                                  id={"category-" + category.id + "-commission"}
                                  className="row-data-max"
                                  onClick={() =>
                                    handleMaxCatBtn(
                                      category.id,
                                      category.originalTitle,
                                      "commission"
                                    )
                                  }
                                >
                                  {t("MAX")}
                                </div>
                              </td>
                            </tr>
                          )}
                          {category.originalTitle === "Poker" &&
                            products.filter(
                              (x) =>
                                x.categoryName === category.originalTitle &&
                                x.maxClubFee > 0
                            ).length > 0 && (
                              <tr
                                className="data-row category"
                                style={{
                                  marginTop:
                                    products.filter(
                                      (x) =>
                                        x.categoryName ===
                                          category.originalTitle &&
                                        x.maxCommission > 0
                                    ).length > 0
                                      ? "0"
                                      : "25px",
                                }}
                              >
                                <td
                                  className="row-data-1"
                                >
                                  {products.filter(
                                    (x) =>
                                      x.categoryName ===
                                        category.originalTitle &&
                                      x.maxCommission > 0
                                  ).length > 0 ? (
                                    ""
                                  ) : (
                                    <b>{category.title}</b>
                                  )}
                                </td>
                                <td className="row-data-2">
                                  <div className="txt-1">{t("CLUB_FEE")}</div>
                                  <div className="txt-2">
                                    {t("MAX") +
                                      ":" +
                                      valueList[
                                        category.originalTitle
                                      ].highestMaxClubFee.toFixed(2) +
                                      "%"}
                                  </div>
                                </td>
                                <td className="row-data-3">
                                  {products.filter(
                                    (x) =>
                                      x.categoryName ===
                                        category.originalTitle &&
                                      x.maxClubFee > 0
                                  ).length > 0 && (
                                    <>
                                      <Controller
                                        control={control}
                                        name={"category-clubFee-" + category.id}
                                        defaultValue={0}
                                        render={({ onChange, value }) => {
                                          let valueNotLowest =
                                            checkValueForLowest(value);
                                          let valueNotHighest =
                                            checkValueForHighest(
                                              value,
                                              category,
                                              "clubFee"
                                            );

                                            if(document.getElementById("category-" + category.id + "-clubFee") !== null) {
                                              if (!valueNotHighest) {
                                                document.getElementById("category-" + category.id + "-clubFee").style.background = backgroundDarkMaxButton;
                                              }
                                              else {
                                                document.getElementById("category-" + category.id + "-clubFee").style.background = backgroundLightMaxButton;
                                              }
                                            }
                                          return (
                                            <>
                                            <div className="add-minus-box">
                                              <div
                                                onClick={(e) => {
                                                  if (valueNotLowest === true) {
                                                    value -= 5.0;
                                                    value *= 100;
                                                    value = Math.round(value);
                                                    value /= 100;
                                                    setValue(
                                                      "category-clubFee-" +
                                                        category.id,
                                                      parseFloat(
                                                        value.toFixed(2)
                                                      )
                                                    );
                                                    handleCatBtn(
                                                      "clubFee",
                                                      category.id,
                                                      category.originalTitle,
                                                      value
                                                    );
                                                  }
                                                }}
                                              >
                                               <img style={{ opacity: valueNotLowest ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                              </div>
                                              <select
                                                className="bonusComm-option-select"
                                                value={parseFloat(
                                                  value.toFixed(2)
                                                )}
                                                placeholder={value.toFixed(2)}
                                                onChange={(e) => {
                                                  handleSelectCatValue(
                                                    "clubFee",
                                                    category.id,
                                                    category.originalTitle,
                                                    e.target.value
                                                  );
                                                }}
                                              >
                                                {valueList[
                                                  category.originalTitle
                                                ].clubFeeValues.map(
                                                  (value, index) => {
                                                    return (
                                                      <option
                                                        key={index}
                                                        className="bonusComm-option-value"
                                                        value={parseFloat(
                                                          value.toFixed(2)
                                                        )}
                                                      >
                                                        {value.toFixed(2)}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>
                                              <div
                                                onClick={(e) => {
                                                  if (
                                                    valueNotHighest === true
                                                  ) {
                                                    value += 5.0;
                                                    value *= 100;
                                                    value = Math.round(value);
                                                    value /= 100;
                                                    setValue(
                                                      "category-clubFee-" +
                                                        category.id,
                                                      parseFloat(
                                                        value.toFixed(2)
                                                      )
                                                    );
                                                    handleCatBtn(
                                                      "clubFee",
                                                      category.id,
                                                      category.originalTitle,
                                                      value
                                                    );
                                                  }
                                                }}
                                              >
                                                 <img style={{ opacity: valueNotHighest ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="add" />
                                              </div>
                                              </div>
                                              {errors.editPercent && (
                                                <div className="invalid-feedback">
                                                  {t(
                                                    errors.editPercent.message
                                                  )}
                                                </div>
                                              )}
                                            </>
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                                </td>
                                <td className="row-data-4">
                                  <div
                                    id={"category-" + category.id + "-clubFee"}
                                    className="row-data-max"
                                    onClick={() =>
                                      handleMaxCatBtn(
                                        category.id,
                                        category.originalTitle,
                                        "clubFee"
                                      )
                                    }
                                  >
                                    {t("MAX")}
                                  </div>
                                </td>
                              </tr>
                            )}

                          {products.filter(
                            (x) => x.categoryName === category.originalTitle
                          ).length > 0 &&
                            products
                              .filter(
                                (product) =>
                                  product.categoryName ===
                                    category.originalTitle &&
                                  (product.maxBonus > 0 ||
                                    product.maxCommission > 0 ||
                                    product.maxClubFee > 0)
                              )
                              .map((product, i) => {
                                return (
                                  <Fragment key={i}>
                                    {product.maxBonus > 0 && (
                                      <tr
                                        className={
                                          "data-row" +
                                          ((i + 1) % 2 !== 0
                                            ? " bg-color-1"
                                            : "")
                                        }
                                      >
                                        <td className="row-data-1">
                                          <div className="row-data-title">
                                            {product.productName}
                                          </div>
                                        </td>
                                        <td className="row-data-2">
                                          <div className="txt-1">
                                            {t("BONUS")}
                                          </div>
                                          <div className="txt-2">
                                            {t("MAX") +
                                              ":" +
                                              product.maxBonus.toFixed(2) +
                                              "%"}
                                          </div>
                                        </td>
                                        <td className="row-data-3">
                                          <Controller
                                            control={control}
                                            name={"bonus-" + product.productId}
                                            defaultValue={0}
                                            render={({ onChange, value }) => {
                                              let valueNotLowest = checkValueForLowest(value);
                                              let valueNotHighest = checkValueForHighest(value, category, 'bonus');
                                              return (
                                                <>
                                                  <div className="add-minus-box">
                                                      <div
                                                        onClick={(e) => {
                                                          if (
                                                              product.maxBonus > 0 &&
                                                              parseFloat(value) > 0
                                                          ) {
                                                              value -= 0.1;
                                                              document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundLightMaxButton;
                                                              document.getElementById("div-product-" + product.productId + "-bonus").getElementsByTagName("img")[0].style.opacity = "1";

                                                              if(value == product.maxBonus) {
                                                                document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundDarkMaxButton;
                                                                document.getElementById("div-product-" + product.productId + "-bonus").getElementsByTagName("img")[0].style.opacity = "0.4";
                                                              }
                                                              else {
                                                                document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundLightMaxButton;
                                                                document.getElementById("div-product-" + product.productId + "-bonus").getElementsByTagName("img")[0].style.opacity = "1";
                                                              }
                                                              setValue(
                                                                "bonus-" +
                                                                product.productId,
                                                                value
                                                              );
                                                            }
                                                          }}>
                                                          <img style={{ opacity: valueNotLowest ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                                      </div>
                                                      <select
                                                          className="bonusComm-option-select"
                                                          value={parseFloat(
                                                              value.toFixed(2)
                                                          )}
                                                          placeholder={value.toFixed(
                                                              2
                                                          )}
                                                          onChange={(e) => {
                                                              handleSelectValue(
                                                                  "bonus",
                                                                  product.productId,
                                                                  e.target.value,
                                                                  product.maxBonus
                                                              );
                                                          }} >
                                                          {valueList[
                                                              category.originalTitle
                                                          ][
                                                              product.productName
                                                          ].bonusValues.map(
                                                            (value, index) => {
                                                              return (
                                                                <option
                                                                  className="bonusComm-option-value"
                                                                  value={parseFloat(
                                                                      value.toFixed(2)
                                                                  )}
                                                                >
                                                                  {value.toFixed(2)}
                                                                </option>
                                                              );
                                                            }
                                                          )}
                                                      </select>
                                                      <div
                                                        id={"div-product-" + product.productId + "-bonus"}
                                                        onClick={(e) => {
                                                          if (
                                                              product.maxBonus > 0 &&
                                                              parseFloat(value) <
                                                              product.maxBonus
                                                          ) {
                                                              value += 0.1;
                                                              document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundLightMaxButton;
                                                              document.getElementById("div-product-" + product.productId + "-bonus").getElementsByTagName("img")[0].style.opacity = "1";

                                                              if(value == product.maxBonus) {
                                                                document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundDarkMaxButton;
                                                                document.getElementById("div-product-" + product.productId + "-bonus").getElementsByTagName("img")[0].style.opacity = "0.4";
                                                              }
                                                              else {
                                                                document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundLightMaxButton;
                                                                document.getElementById("div-product-" + product.productId + "-bonus").getElementsByTagName("img")[0].style.opacity = "1";
                                                              }

                                                              setValue(
                                                                "bonus-" +
                                                                product.productId,
                                                                value
                                                              );
                                                            }
                                                          }}>
                                                          <img style={{ opacity: valueNotHighest ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="add" />
                                                      </div>
                                                  </div>
                                                
                                                  {errors.editPercent && (
                                                    <div className="invalid-feedback">
                                                      {t(
                                                        errors.editPercent
                                                          .message
                                                      )}
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            }}
                                          />
                                        </td>
                                        <td className="row-data-4">
                                          <div
                                            className="row-data-max"
                                            id={'product-' + product.productId + '-bonus'}
                                            onClick={() =>
                                              handleMaxBtn(
                                                product.productId,
                                                product.maxBonus,
                                                product.maxCommission,
                                                product.maxClubFee,
                                                "bonus"
                                              )
                                            }
                                          >
                                            {t("MAX")}
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {product.maxCommission > 0 && (
                                      <tr
                                        className={
                                          "data-row" +
                                          ((i + 1) % 2 !== 0
                                            ? product.maxBonus > 0
                                              ? product.maxClubFee > 0
                                                ? " bg-color-2"
                                                : " bg-color-3"
                                              : product.maxClubFee > 0
                                              ? " bg-color-1"
                                              : " "
                                            : "")
                                        }
                                      >
                                        <td className="row-data-1">
                                          {product.maxBonus > 0 ? (
                                            ""
                                          ) : (
                                            <div className="row-data-title">
                                              {product.productName}
                                            </div>
                                          )}
                                        </td>
                                        <td className="row-data-2">
                                          <div className="txt-1">
                                            {t("COMMISSION_SHORT")}
                                          </div>
                                          <div className="txt-2">
                                            {t("MAX") +
                                              ":" +
                                              product.maxCommission.toFixed(2) +
                                              "%"}
                                          </div>
                                        </td>
                                        <td className="row-data-3">
                                          {products.filter(x => x.categoryName === category.originalTitle && x.maxCommission > 0).length > 0 && <>
                                            <Controller
                                              control={control}
                                              name={
                                                "commission-" +
                                                product.productId
                                              }
                                              defaultValue={0}
                                              render={({ onChange, value }) => {
                                                let valueNotLowest = checkValueForLowest(value);
                                                let valueNotHighest = checkValueForHighest(value, category, 'commission');
                                                return (
                                                  <>
                                                    <div className="add-minus-box">
                                                      <div
                                                        onClick={(e) => {
                                                          if (
                                                            product.maxCommission >
                                                              0 &&
                                                            parseFloat(value) >
                                                              0
                                                          ) {
                                                            value -= 0.1;

                                                            document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                            document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "1";
                                                          }

                                                          if(value == product.maxCommission) {
                                                            document.getElementById("product-" + product.productId + "-commission").style.background = backgroundDarkMaxButton;
                                                            document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "0.4";
                                                          }
                                                          else {
                                                            document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                            document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "1";
                                                          }

                                                          setValue(
                                                            "commission-" +
                                                              product.productId,
                                                            value
                                                          );
                                                        }}>
                                                        <img style={{ opacity: valueNotLowest ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                                      </div>
                                                      <select
                                                        className="bonusComm-option-select"
                                                        value={parseFloat(
                                                          value.toFixed(2)
                                                        )}
                                                        placeholder={value.toFixed(
                                                          2
                                                        )}
                                                        onChange={(e) => {
                                                          handleSelectValue(
                                                            "commission",
                                                            product.productId,
                                                            e.target.value,
                                                            product.maxCommission
                                                          );
                                                        }} >
                                                       {valueList[ 
                                                          category.originalTitle
                                                        ][
                                                          product.productName
                                                        ].commValues.map(
                                                          (commValues, index) => {
                                                            return (
                                                              <option className="bonusComm-option-value" value={commValues !== '-' ? parseFloat(commValues.toFixed(2)) : '-'}>{commValues !== '-' ? commValues.toFixed(2) : '-'}</option>
                                                            );
                                                          }
                                                        )}
                                                      </select>
                                                      <div
                                                        id={"div-product-" + product.productId + "-commission"}
                                                        onClick={(e) => {
                                                          if (
                                                            product.maxCommission >
                                                              0 &&
                                                            parseFloat(value) <
                                                              product.maxCommission
                                                          ) {
                                                            value += 0.1;

                                                            document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                            document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "1";

                                                          }
                                                          if(value == product.maxCommission) {
                                                            document.getElementById("product-" + product.productId + "-commission").style.background = backgroundDarkMaxButton;
                                                            document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "0.4";
                                                          }
                                                          else {
                                                            document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                            document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "1";
                                                          }

                                                          setValue(
                                                            "commission-" +
                                                              product.productId,
                                                            value
                                                          );
                                                        }}>
                                                        <img style={{ opacity: valueNotHighest ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="add" />
                                                      </div>
                                                    </div>
                                                    {errors.editPercent && <div className="invalid-feedback">{t(errors.editPercent.message)}</div>}
                                                  </>
                                                )
                                              }}
                                            />
                                          </>}
                                        </td>

                                        <td className="row-data-4">
                                          <div
                                            className="row-data-max"
                                            id={'product-' + product.productId + '-commission'}
                                            onClick={() =>
                                              handleMaxBtn(
                                                product.productId,
                                                product.maxBonus,
                                                product.maxCommission,
                                                product.maxClubFee,
                                                "commission"
                                              )
                                            }
                                          >
                                            {t("MAX")}
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {product.productName === "D2 Poker" &&
                                      product.maxClubFee > 0 && (
                                        <tr
                                          className={
                                            "data-row" +
                                            ((i + 1) % 2 !== 0
                                              ? product.maxClubFee > 0 &&
                                                (product.maxBonus > 0 ||
                                                  product.maxCommission > 0)
                                                ? " bg-color-3"
                                                : ""
                                              : "")
                                          }
                                        >
                                          <td className="row-data-1">
                                            {product.maxCommission > 0 ? (
                                              ""
                                            ) : (
                                              <div className="row-data-title">
                                                {product.productName}
                                              </div>
                                            )}
                                          </td>
                                          <td className="row-data-2">
                                            <div className="txt-1">
                                              {t("CLUB_FEE")}
                                            </div>
                                            <div className="txt-2">
                                              {t("MAX") +
                                                ":" +
                                                product.maxClubFee.toFixed(2) +
                                                "%"}
                                            </div>
                                          </td>

                                          <td className="row-data-3">
                                          {products.filter(x => x.categoryName === category.originalTitle && x.maxClubFee > 0).length > 0 && <>
                                            <Controller
                                              control={control}
                                              name={
                                                "clubFee-" +
                                                product.productId
                                              }
                                              defaultValue={0}
                                              render={({ onChange, value }) => {
                                                let valueNotLowest = checkValueForLowest(value);
                                                let valueNotHighest = checkValueForHighest(value, category, 'clubFee');
                                                return (
                                                  <>
                                                    <div className="add-minus-box">
                                                      <div
                                                        onClick={(e) => {
                                                          if (
                                                            product.maxClubFee >
                                                              0 &&
                                                            parseFloat(
                                                              value
                                                            ) > 0
                                                          ) {
                                                            value -= 5.0;

                                                            document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundLightMaxButton;
                                                            document.getElementById("div-product-" + product.productId + "-clubFee").getElementsByTagName("img")[0].style.opacity = "1";
                                                          }

                                                          if(value == product.maxClubFee) {
                                                            document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundDarkMaxButton;
                                                            document.getElementById("div-product-" + product.productId + "-clubFee").getElementsByTagName("img")[0].style.opacity = "0.4";
                                                          }
                                                          else {
                                                            document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundLightMaxButton;
                                                            document.getElementById("div-product-" + product.productId + "-clubFee").getElementsByTagName("img")[0].style.opacity = "1";
                                                          }

                                                          setValue(
                                                            "clubFee-" +
                                                              product.productId,
                                                            value
                                                          );
                                                        }}>
                                                        <img style={{ opacity: valueNotLowest ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                                      </div>
                                                      <select
                                                        className="bonusComm-option-select"
                                                        value={value}
                                                        placeholder={value}
                                                        onChange={(e) => {
                                                          handleSelectValue(
                                                            "clubFee",
                                                            product.productId,
                                                            e.target.value,
                                                            product.maxClubFee
                                                          );
                                                        }} >
                                                        {valueList[
                                                          category
                                                            .originalTitle
                                                        ][
                                                          product.productName
                                                        ].clubFeeValues.map(
                                                          (clubFeeValues, index) => {
                                                            return (
                                                              <option key={index} className="bonusComm-option-value" value={clubFeeValues !== '-' ? parseFloat(clubFeeValues.toFixed(2)) : '-'}>{clubFeeValues !== '-' ? clubFeeValues.toFixed(2) : '-'}</option>
                                                            );
                                                          }
                                                        )}
                                                      </select>
                                                      <div
                                                        id={"div-product-" + product.productId + "-clubFee"}
                                                        onClick={(e) => {
                                                          if (
                                                            product.maxClubFee >
                                                              0 &&
                                                            parseFloat(
                                                              value
                                                            ) <
                                                              product.maxClubFee
                                                          ) {
                                                            value += 5.0;
                                                            setValue(
                                                              "clubFee-" +
                                                                product.productId,
                                                              value
                                                            );
                                                          }
                                                        }}>
                                                        <img style={{ opacity: valueNotHighest ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="add" />
                                                      </div>
                                                    </div>
                                                    {errors.editPercent && <div className="invalid-feedback">{t(errors.editPercent.message)}</div>}
                                                  </>
                                                )
                                              }}
                                            />
                                          </>}
                                        </td>

                                          <td className="row-data-4">
                                            <div
                                              className="row-data-max"
                                              id={'product-' + product.productId + '-clubFee'}
                                              onClick={() =>
                                                handleMaxBtn(
                                                  product.productId,
                                                  product.maxBonus,
                                                  product.maxCommission,
                                                  product.maxClubFee,
                                                  "clubFee"
                                                )
                                              }
                                            >
                                              {t("MAX")}
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                  </Fragment>
                                );
                              })}
                        </Fragment>
                      );
                    }
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <div className="card">
              <div className="card-body text-center">
                {t("NO_ASSIGNED_PRODUCT")}
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    _dispatch(setBusy());
    try {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_GET_MEMBER_UPLINE_PRODUCT_RATES +
          "?memberId=" +
          _location["state"]["id"]
      );
      let uplineProductRates = [];
      let processedProducts = [];
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        uplineProductRates = responseJson[ApiKey._API_DATA_KEY];
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      let productResponseJson = await ApiEngine.get(
        ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT +
          "?memberId=" +
          _location["state"]["id"]
      );
      if (productResponseJson[ApiKey._API_SUCCESS_KEY]) {
        setMember(productResponseJson[ApiKey._API_DATA_KEY].member);
        setNickname(productResponseJson[ApiKey._API_DATA_KEY].member.nickname);
        productResponseJson[ApiKey._API_DATA_KEY].products.map((product) => {
          let uplineProduct = uplineProductRates.filter(
            (i) => i.accountProductId == product.accountProductId
          )[0];
          processedProducts.push({
            id: product.id,
            productId: product.productId,
            accountProductId: product.accountProductId,
            productName:
              product.productName + (product.useGroupRate ? " *" : ""),
            assigned: product.assigned,
            bonus: product.bonus,
            commission: product.commission,
            clubFee: product.clubFee,
            categoryName: product.categoryName,
            maxBonus: uplineProduct != null ? uplineProduct.bonus : 0,
            maxCommission: uplineProduct != null ? uplineProduct.commission : 0,
            maxClubFee: uplineProduct != null ? uplineProduct.clubFee : 0,
            useGroupRate: product.useGroupRate,
          });
        });
      } else {
        throw productResponseJson[ApiKey._API_MESSAGE_KEY];
      }

      setProduct(
        products["length"] == 0
          ? processedProducts[0]
          : processedProducts.find((x) => x["id"] == product["id"])
      );
      setProducts(processedProducts);

      let categoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
      if (categoryJson[ApiKey._API_SUCCESS_KEY]) {
        let categoryData = categoryJson[ApiKey._API_DATA_KEY];
        setCategories(categoryData);
        let tempObj = {};
        let tempHighestMaxBonus = 0.0;
        let tempHighestMaxComm = 0.0;
        let tempHighestMaxClubFee = 0.0;
        categoryData.map((category, index) => {
          if (!tempObj[category.originalTitle]) {
            tempObj[category.originalTitle] = {
              bonusValues: [],
              commValues: [],
              clubFeeValues: [],
              highestMaxBonus: 0,
              highestMaxComm: 0,
              highestMaxClubFee: 0,
            };
          }
          processedProducts
            .filter((x) => x.categoryName === category.originalTitle)
            .map((product, i) => {
              if (i === 0) {
                tempHighestMaxBonus = product.maxBonus;
                tempHighestMaxComm = product.maxCommission;
                tempHighestMaxClubFee = product.maxClubFee;
              }
              if (!tempObj[category.originalTitle][product.productName])
                tempObj[category.originalTitle][product.productName] = {
                  bonusValues: [],
                  commValues: [],
                  clubFeeValues: [],
                };
              if (product.maxBonus > tempHighestMaxBonus) {
                tempHighestMaxBonus = product.maxBonus;
              }
              if (product.maxCommission > tempHighestMaxComm) {
                tempHighestMaxComm = product.maxCommission;
              }
              if (product.maxClubFee > tempHighestMaxClubFee) {
                tempHighestMaxClubFee = product.maxClubFee;
              }
              if (product.maxBonus > 0) {
                for (
                  var i = 0.0;
                  parseFloat(i.toFixed(2)) <=
                  parseFloat(product.maxBonus.toFixed(2));
                  i += 0.1
                ) {
                  i = i * 100;
                  i = Math.round(i);
                  i = i / 100;
                  i = parseFloat(i.toFixed(2));

                  tempObj[category.originalTitle][
                    product.productName
                  ].bonusValues.push(i);
                }
              }
              if (product.maxCommission > 0) {
                for (
                  var i = 0.0;
                  parseFloat(i.toFixed(2)) <=
                  parseFloat(product.maxCommission.toFixed(2));
                  i += 0.1
                ) {
                  i = i * 100;
                  i = Math.round(i);
                  i = i / 100;
                  i = parseFloat(i.toFixed(2));

                  tempObj[category.originalTitle][
                    product.productName
                  ].commValues.push(i);
                }
              }
              if (product.maxClubFee > 0) {
                for (
                  var i = 0.0;
                  parseFloat(i.toFixed(2)) <=
                  parseFloat(product.maxClubFee.toFixed(2));
                  i += 5.0
                ) {
                  i = i * 100;
                  i = Math.round(i);
                  i = i / 100;
                  i = parseFloat(i.toFixed(2));

                  tempObj[category.originalTitle][
                    product.productName
                  ].clubFeeValues.push(i);
                }
              }
            });

          tempObj[category.originalTitle]["highestMaxBonus"] =
            tempHighestMaxBonus;
          tempObj[category.originalTitle]["highestMaxComm"] =
            tempHighestMaxComm;
          tempObj[category.originalTitle]["highestMaxClubFee"] =
            tempHighestMaxClubFee;

          for (
            var i = 0.0;
            parseFloat(i.toFixed(2)) <=
            parseFloat(tempHighestMaxBonus.toFixed(2));
            i += 0.1
          ) {
            i = i * 100;
            i = Math.round(i);
            i = i / 100;
            i = parseFloat(i.toFixed(2));

            tempObj[category.originalTitle].bonusValues.push(i);
          }
          for (
            var i = 0.0;
            parseFloat(i.toFixed(2)) <=
            parseFloat(tempHighestMaxComm.toFixed(2));
            i += 0.1
          ) {
            i = i * 100;
            i = Math.round(i);
            i = i / 100;
            i = parseFloat(i.toFixed(2));

            tempObj[category.originalTitle].commValues.push(i);
          }
          for (
            var i = 0.0;
            parseFloat(i.toFixed(2)) <=
            parseFloat(tempHighestMaxClubFee.toFixed(2));
            i += 5.0
          ) {
            i = i * 100;
            i = Math.round(i);
            i = i / 100;
            i = parseFloat(i.toFixed(2));

            tempObj[category.originalTitle].clubFeeValues.push(i);
          }
        });
        setValueList(tempObj);
      }
    } catch (errorMessage) {
      _dispatch(showResponseMessage(false, errorMessage));
      _history.goBack();
    } finally {
      _dispatch(setIdle());
      setInitCheck(true);
    }
  }
  useEffect(() => {
    if (
      products.filter(
        (x) =>
        (x.maxBonus > 0 ||
          x.maxCommission > 0 ||
          x.maxClubFee > 0)
      ).length > 0
    )
    {
      setHasProduct(false);
    } else
    {
      setHasProduct(true);
    }
  }, [products]);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    products.map((product, index) => {

      setValue("bonus-" + product.productId, product.bonus);
      setValue("commission-" + product.productId, product.commission);
      setValue("clubFee-" + product.productId, product.clubFee);

      if(product.bonus == product.maxBonus){
        if(document.getElementById("product-" + product.productId + "-bonus") !== null) {
          document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundDarkMaxButton;
          document.getElementById("div-product-" + product.productId + "-bonus").getElementsByTagName("img")[0].style.opacity = "0.4";
        }
      } 
      
      if(product.commission == product.maxCommission){
        if(document.getElementById("product-" + product.productId + "-commission") !== null) {
          document.getElementById("product-" + product.productId + "-commission").style.background = backgroundDarkMaxButton;
          document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "0.4";
        }
      }
      
      if(product.clubFee == product.maxClubFee) {
        if(document.getElementById("product-" + product.productId + "-clubFee") !== null) {
          document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundDarkMaxButton;
          document.getElementById("div-product-" + product.productId + "-clubFee").getElementsByTagName("img")[0].style.opacity = "0.4";
        }
      }
    });
  }, [initCheck]);

  /// <summary>
  /// Author: -
  /// </summary>
  async function updateNickname() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_NICKNAME +
        "?memberId=" +
        _location["state"]["id"] +
        "&nickname=" +
        nickname
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );
    _dispatch(setIdle());
  }

  /// <summary>
  /// Author: -
  /// </summary>
  async function onSubmit(data, e) {
    _dispatch(setBusy());
    let params = {
      id: _location["state"]["id"],
    };

    Object.keys(data).map((key, value) => {
      params[key] = data[key];
    });

    let formBody = createMultiPartFormBody(params);
    //let tempformBody = createMultiPartFormBody({
    //    accountProductId: product["accountProductId"],
    //    commission: product['commission'],
    //    bonus: product['bonus']
    //});
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_PRODUCT_RATE,
      formBody
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());
  }

  function checkValueForLowest(value) {
    if (value === 0) {
      return false;
    } else {
      return true;
    }
  }

  function checkValueForHighest(value, category, bonusOrComm) {
    if (bonusOrComm === "bonus") {
      if (value === valueList[category.originalTitle].highestMaxBonus) {
        return false;
      } else {
        return true;
      }
    } else if (bonusOrComm === "commission") {
      if (value === valueList[category.originalTitle].highestMaxComm) {
        return false;
      } else {
        return true;
      }
    } else if (bonusOrComm === "clubFee") {
      if (value === valueList[category.originalTitle].highestMaxClubFee) {
        return false;
      } else {
        return true;
      }
    }
  }

  function handleCatBtn(bonusOrComm, categoryId, categoryTitle, value) {
    value *= 100;
    value = Math.round(value);
    value /= 100;

    products.filter(x => x.categoryName === categoryTitle).map((product, index) => {
        let tempBonusOrComm = bonusOrComm.substring(0, 1).toUpperCase() + bonusOrComm.substring(1, bonusOrComm.length);
        if (value > product["max" + tempBonusOrComm]) {
            setValue(bonusOrComm + '-' + product.productId, product["max" + tempBonusOrComm]);
        }
        else if (value == product["max" + tempBonusOrComm]){
            setValue(bonusOrComm + '-' + product.productId, value);

            if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
              document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;
              document.getElementById("div-product-" + product.productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "0.4";
              }
        }
        else {
            setValue(bonusOrComm + '-' + product.productId, value);

            if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null && document.getElementById("category-" + categoryId + "-" + bonusOrComm) !== null) {
              document.getElementById("product-" + product.productId+ "-" + bonusOrComm).style.background = backgroundLightMaxButton;
              document.getElementById("div-product-" + product.productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "1";
            }
        }
    });
}

  function handleMaxCatBtn(categoryId, categoryTitle, bonusOrComm) {
    let tempHighestBonus = 0.0;
    let tempHighestComm = 0.0;
    let tempHighestMaxClubFee = 0.0;
    products
      .filter((x) => x.categoryName === categoryTitle)
      .map((product, index) => {
        if (document.getElementById("product-" + product.productId + '-' + bonusOrComm) !== null && document.getElementById("category-" + categoryId + "-" + bonusOrComm) !== null ) {
          document.getElementById("product-" + product.productId + '-' + bonusOrComm).style.background = backgroundDarkMaxButton;
          document.getElementById("div-product-" + product.productId + '-' + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "0.4";
        }

        if (bonusOrComm === "bonus") {
          if (product.maxBonus > tempHighestBonus) {
            tempHighestBonus = product.maxBonus;
          }
          if (100.0 > product.maxBonus) {
            setValue("bonus-" + product.productId, product["maxBonus"]);
          } else {
            setValue("bonus-" + product.productId, 100.0);
          }
        } else if (bonusOrComm === "commission") {
          if (product.maxCommission > tempHighestComm) {
            tempHighestComm = product.maxCommission;
          }
          if (100.0 > product.maxCommission) {
            setValue(
              "commission-" + product.productId,
              product["maxCommission"]
            );
          } else {
            setValue("commission-" + product.productId, 100.0);
          }
        } else if (bonusOrComm === "clubFee") {
          if (product.maxClubFee > tempHighestMaxClubFee) {
            tempHighestMaxClubFee = product.maxClubFee;
          }
          if (100.0 > product.maxClubFee) {
            setValue("clubFee-" + product.productId, product["maxClubFee"]);
          } else {
            setValue("clubFee-" + product.productId, 100.0);
          }
        }
      });

    if (bonusOrComm === "bonus") {
      setValue("category-bonus-" + categoryId, tempHighestBonus);
    } else if (bonusOrComm === "commission") {
      setValue("category-commission-" + categoryId, tempHighestComm);
    } else if (bonusOrComm === "clubFee") {
      setValue("category-clubFee-" + categoryId, tempHighestMaxClubFee);
    }
  }

  function handleMaxBtn(productId, maxBonus, maxComm, maxClubFee, bonusOrComm) {
    document.getElementById("product-" + productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;
    document.getElementById("div-product-" + productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "0.4";

    if (bonusOrComm === "bonus") {
      setValue("bonus-" + productId, maxBonus);
    } else if (bonusOrComm === "commission") {
      setValue("commission-" + productId, maxComm);
    } else if (bonusOrComm === "clubFee") {
      setValue("clubFee-" + productId, maxClubFee);
    }
  }

  function handleSelectValue(bonusOrComm, productId, value, maxValue) {
    value *= 100;
    value = Math.round(value);
    value /= 100;

    //let tempValue = parseFloat(value);
    if(value == maxValue){
      document.getElementById("product-" + productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;
    }
    else {
      document.getElementById("product-" + productId + "-" + bonusOrComm).style.background = backgroundLightMaxButton;
    }
    
    setValue("" + bonusOrComm + "-" + productId, value);
  }

  function handleSelectCatValue(bonusOrComm, categoryId, categoryTitle, value) {
    if (value !== '-') {
        value *= 100;
        value = Math.round(value);
        value /= 100;

        

        products.filter(x => x.categoryName === categoryTitle).map((product, index) => {
            let tempBonusOrComm = bonusOrComm.substring(0, 1).toUpperCase() + bonusOrComm.substring(1, bonusOrComm.length);
            if (value > product["max" + tempBonusOrComm]) {
                setValue(bonusOrComm + '-' + product.productId, product["max" + tempBonusOrComm]);

                if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
                    document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;
                    document.getElementById("div-product-" + product.productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "0.4";
                  }

            }
            else if (value == product["max" + tempBonusOrComm]) {
                setValue(bonusOrComm + '-' + product.productId, value);

                if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
                    document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;
                    document.getElementById("div-product-" + product.productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "0.4";
                  }
            }
            else {
                setValue(bonusOrComm + '-' + product.productId, value);

                if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
                    document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundLightMaxButton;
                    document.getElementById("div-product-" + product.productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "1";
                  }
            }
        });
    }
    else {
        products.filter(x => x.categoryName === categoryTitle).map((product, index) => {
            setValue(bonusOrComm + '-' + product.productId, '-');

            if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
                document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundLightMaxButton;
                document.getElementById("div-product-" + product.productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "1";
              }
        });
    }
    setValue('category-' + bonusOrComm + '-' + categoryId, value);
}

  function checkValueForLowest(value) {
    if (value === 0) {
      return false;
    } else {
      return true;
    }
  }

  function checkValueForHighest(value, category, bonusOrComm) {
    if (bonusOrComm === "bonus") {
      if (value === valueList[category.originalTitle].highestMaxBonus) {
        return false;
      } else {
        return true;
      }
    } else if (bonusOrComm === "commission") {
      if (value === valueList[category.originalTitle].highestMaxComm) {
        return false;
      } else {
        return true;
      }
    } else if (bonusOrComm === "clubFee") {
      if (value === valueList[category.originalTitle].highestMaxClubFee) {
        return false;
      } else {
        return true;
      }
    }
  }


  return (
    <>
      <Header
        history={_history}
        url={_location.state && _location.state.url ? _location.state.url : WebUrl._URL_DOWNLINE}
        state={{ id: _location["state"]["id"] }}
        title={t("EDIT_DOWNLINE")}
      />
      <div
        id={
          _userData.selectedTheme === 0
            ? "downline-details"
            : "downline-details-modern"
        }
        style={{ paddingBottom: "30px" }}
      >
        <form
          className="margin-bottom-0"
          onSubmit={handleSubmitNickname(updateNickname)}
        >
          <div>
            <div className="form-group d-flex align-items-center mb-4">
              <label className="font-weight-bold mb-0-imp">
                {t("USERNAME")}:
              </label>
              <div className="pb-0">
                <span className="pl-3">{member["username"]}</span>
              </div>
            </div>
            <div className="form-group m-b-0">
              <label className="font-weight-bold">{t("NICKNAME")}</label>
              <Input
                name="nickname"
                value={nickname}
                onChange={(e) => {
                  setNickname(e.target.value);
                }}
              />
            </div>
            {errorsNickname.nickname && (
              <div className="invalid-feedback">
                {t(errorsNickname.nickname.message)}
              </div>
            )}

            <button type="submit" className="btn custom-btn w-100">
              {t("SAVE") + " " + t("NICKNAME")}
            </button>
          </div>
        </form>
        <span style={{ display: "none" }}>
          <div className="pl-4">{t("ALIAS")}</div>
          <Input placeholder={t("NO_ALIAS")} />
        </span>
        {
          <form>
            {initCheck && (
              <>
                {EditDownlineForm()}
                {!hasProduct &&
                  <div style={{ margin: "1rem 0px" }}>
                    <button
                      type="button"
                      className="btn custom-btn mt-4 mb-5 w-100"
                      onClick={() => handleSubmit(onSubmit)()}
                    >
                      {t("SUBMIT")}
                    </button>
                  </div>
                }
              </>
            )}
          </form>
        }
        {/*products.length > 0 ?
                    <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group m-b-0">
                            <label className="pl-4">{t("PRODUCTS")}</label>
                            <StyledSelect
                                options={products.map(x => { return { label: x['productName'], value: x['id'] } })}
                                value={product['id']}
                                onChange={(data) => {
                                    setProduct(products.find(x => x['id'] == data['value']));
                                }} />
                        </div>
                        <div>
                            <div className="card-body">
                                <RangeSlider
                                    max={product['maxBonus']}
                                    value={product['bonus']}
                                    label={`${t("BONUS_RATE")} (%)`}
                                    onChange={(value) => { product['bonus'] = value; setProduct({ ...product }); }} />
                                <RangeSlider
                                    max={product['maxCommission']}
                                    value={product['commission']}
                                    label={`${t("COMMISSION_RATE")} (%)`}
                                    step={0.1}
                                    onChange={(value) => { product['commission'] = value; setProduct({ ...product }); }} />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-block btn-brand-gradient">{t("SAVE")}</button>
                    </form> :
                    <div className="card">
                        <div className="card-body text-center">
                            {t("NO_ASSIGNED_PRODUCT")}
                        </div>
                    </div>
                */}
      </div>
    </>
  );
};

export default EditDownline;
