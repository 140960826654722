import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { ApiKey, ApiUrl, WebUrl, Status } from "../../util/Constant";
import { stringIsNullOrEmpty } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine"
import ScrollMenu from "react-horizontal-scrolling-menu";
import moment from "moment";
import DatePicker from "react-datepicker";
import OwlCarousel from "react-owl-carousel2";
import 'react-owl-carousel2/src/owl.carousel.css';
import { useSelector } from 'react-redux';
import {
  setBusy,
  setIdle,
  showResponseMessage,
} from "../../redux/AppAction.js";
import { useDispatch } from "react-redux";
import {
  _POOL_NAME,
  _TITLE_COLOR,
  _COLOR,
  _POOL_IMAGE,
  _SECTION_TYPE,
  _IS_5D_6D,
} from "config/game-configs";
import { map } from "lodash";
import Slider from "react-slick";


/// <summary>
/// Author : -
/// </summary>
const PoolResult = () => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();
    const [poolOption, setPoolOption] = useState([]);
    const [selectedPool, setSelectedPool] = useState(null);
    const [poolDate, setPoolDate] = useState(null);
    const [resultData, setResultData] = useState([]);
    const [allResultData, setAllResultData] = useState([]);
    const activeTheme = useSelector(state => state.authState.userData.selectedTheme);

    const resultSlick = {
        slidesToShow: 1,
        dots: true,
        arrows: false,
        swipe: true,
        infinite: true,
        swipeToSlide: true,
        speed: 1000,
        // autoplay: true,
      };

    const DateCustomInput = ({ value, onClick }) => (
        <input
            className="form-control"
            onClick={onClick}
            value={value}
            type="text"
            readOnly={true}
        />
    );

    const _POOL_IMAGE = {
        mg: require("../../assets/img/icon/magnum_logo.png"),
        kd: require("../../assets/img/icon/damacai_logo.png"),
        tt: require("../../assets/img/icon/toto_logo.png"),
        sg: require("../../assets/img/icon/singapore_logo.png"),
        sr: require("../../assets/img/icon/bigcash_logo.png"),
        sn: require("../../assets/img/icon/stc_logo.png"),
        sb: require("../../assets/img/icon/sabah_logo.png"),
        gd: require("../../assets/img/icon/gd_logo.png")
    };

    useEffect(() => {
        init();
        }, [poolDate]);

        async function init() {
        _dispatch(setBusy());
        try {
            await getPoolResult();
        } finally {
            _dispatch(setIdle());
        }
        _dispatch(setIdle());
        }

        const [poolResult, setPoolResult] = useState([]);
        var _dispatch = useDispatch();

        async function getPoolResult() {
        var apiUrl = ApiUrl._API_GET_POOL_RESULT;

        if (poolDate != null) {
            apiUrl += "?date=" + moment(poolDate).format("YYYY-MM-DD");
        }

        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];

            if (data.length > 0) {
            if (poolDate == null)
            setPoolDate(moment(data[0]["drawDateString"], "DD/MM/YYYY").toDate());
            setPoolResult(data);
            } else {
            setPoolResult([]);
            }
        }
    }

    useEffect(() => {
    const tempArray = map(poolResult, (item) => {
        return {
        ...item,
        name:
            item.poolKey != "kd"
            ? item.p_4d_1st != ""
                ? _POOL_NAME[item.poolKey] + " 4D"
                : item.p_5d_1st != ""
                ? _POOL_NAME[item.poolKey] + " 5D"
                : _POOL_NAME[item.poolKey] + " 6D"
            : _POOL_NAME[item.poolKey],
        titleColor: _TITLE_COLOR[item.poolKey],
        color: _COLOR[item.poolKey],
        icon: _POOL_IMAGE[item.poolKey],
        type: item.is5D6D
            ? _IS_5D_6D[item.poolKey]
            : _SECTION_TYPE[item.poolKey],
        };
    });
    console.log(tempArray);
    setResultData(tempArray);
    setAllResultData(tempArray);
    }, [poolResult]);
    useEffect(() => {
        if (selectedPool == null || moment(poolDate, 'DD/MM/YYYY').format("DD/MM/YYYY") != selectedPool["drawDateString"]) {
            init();
        }
    }, [poolDate]);

    const _OPTIONS = {
        items: 6,
    };

    return (
        <>
            <Header history={_history} url={WebUrl._URL_MAIN} title='4D Results' />
            <div className="pool-result">
                <div className="font14 mb-3">Latest 4D Result(Malaysia)</div>
                <div style={{ marginBottom: "20px", position: "relative" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button type="button" className="btn btn-icon" style={{ color: "#002e6c", position: "absolute", top: "5px", left: "16px", zIndex: "1" }} onClick={() => {
                            setPoolDate(new Date(poolDate.setDate(poolDate.getDate() - 1)))
                        }}><i className="fa fa-chevron-left"></i></button>
                    </div>
                    <div className="pool-calendar" style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                        <DatePicker customInput={<DateCustomInput />} dateFormat="dd/MM/yyyy" placeholder={t("SELECT_DATE")} selected={poolDate} onChange={date => { setPoolDate(date); }} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button type="button" className="btn btn-icon" style={{ color: "#002e6c", position: "absolute", top: "5px", right: "16px" }} onClick={() => setPoolDate(new Date(poolDate.setDate(poolDate.getDate() + 1)))}><i className="fa fa-chevron-right"></i></button>
                    </div>
                </div>
            </div>
            <div className="hr-right mt-3 mb-3"></div>
            <div id="result4d">
                <Slider {...resultSlick} className="result-slick">
                    {resultData &&
                    resultData.length &&
                        map(resultData, (item, index) => {
                            return (
                                <div key={index}>
                                    <div
                                        className="color-wrapper result-wrapper"
                                        style={{
                                        backgroundColor: item.color,
                                        }}
                                    >
                                        <div className="d-flex justify-content-between resultCell result-card-header">
                                            <div
                                                className="pool-name font-bold"
                                                style={{
                                                color: item.titleColor,
                                                }}
                                            >
                                                {item.name}
                                            </div>
                                            <div className="pool-name">
                                                <img
                                                style={{
                                                    width: 44,
                                                    height: 30,
                                                }}
                                                className="img-100"
                                                src={item.icon}
                                                alt="4D"
                                                />
                                            </div>
                                        </div>
                                        <div className="white-background card-body p-0 result-card-body">
                                            <div
                                                className="d-flex justify-content-between draw-date-no-section white-background"
                                                style={{
                                                borderBottom: "2px solid " + `${item.color}`,
                                                }}
                                            >
                                                <div className="prize-type text-nowrap font-12 font-bold  date-type">
                                                {t("DRAW")} : {item.drawDateString}
                                                <span className="text-uppercase">
                                                    {" "}
                                                    {"("}
                                                    {moment(item.drawDateString, "DD/MM/YYYY").format("YYYY-MM-DD")}
                                                    {")"}
                                                </span>
                                                </div>
                                            </div>

                                            {item.type === "section-3" && (
                                                <div className="table-responsive">
                                                <table className="table font-16 font-bold text-uppercase mb-0">
                                                    <thead>
                                                    <tr>
                                                        <th
                                                        colSpan="5"
                                                        style={{
                                                            borderBottom: "1px solid " + `${item.color}`,
                                                        }}
                                                        >
                                                        <div className="top-box">
                                                            <span className="prize-type text-nowrap">
                                                                    {t("1ST")}&nbsp;
                                                            </span>
                                                            <span className="prize-type-num">{item.p_4d_1st}</span>
                                                        </div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th
                                                        colSpan="5"
                                                        style={{
                                                            borderBottom: "1px solid " + `${item.color}`,
                                                        }}
                                                        >
                                                            <div className="top-box">
                                                                <span className="prize-type text-nowrap">
                                                                    {t("2ND")}&nbsp;
                                                                </span>
                                                                <span className="prize-type-num">{item.p_4d_2nd}</span>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th
                                                        colSpan="5"
                                                        style={{
                                                            borderBottom: "1px solid " + `${item.color}`,
                                                        }}
                                                        >
                                                            <div className="top-box">
                                                                <span className="prize-type text-nowrap">
                                                                    {t("3RD")}&nbsp;
                                                                </span>
                                                                <span className="prize-type-num">{item.p_4d_3rd}</span>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <th
                                                        colSpan="5"
                                                        className="resultCell"
                                                        style={{
                                                            background: item.color,
                                                        }}
                                                        >
                                                        <div
                                                            className="prize-label"
                                                            style={{
                                                            color: item.titleColor,
                                                            }}
                                                        >
                                                            {t("SPECIAL")}
                                                        </div>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <td className="resultCell">{item.p_starter0}</td>
                                                        <td className="resultCell">{item.p_starter1}</td>
                                                        <td className="resultCell">{item.p_starter2}</td>
                                                        <td className="resultCell">{item.p_starter3}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="resultCell">{item.p_starter4}</td>
                                                        <td className="resultCell">{item.p_starter5}</td>
                                                        <td className="resultCell">{item.p_starter6}</td>
                                                        <td className="resultCell">{item.p_starter7}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="resultCell">{item.p_starter8}</td>
                                                        <td className="resultCell">{item.p_starter9}</td>
                                                        <td className="resultCell">{item.p_starter10}</td>
                                                        <td className="resultCell">{item.p_starter11}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="resultCell"></td>
                                                        <td className="resultCell">{item.p_starter12}</td>
                                                        <td className="resultCell"></td>
                                                        <td className="resultCell"></td>
                                                    </tr>

                                                    <tr>
                                                        <th
                                                        colSpan="5"
                                                        className="resultCell"
                                                        style={{
                                                            background: item.color,
                                                        }}
                                                        >
                                                        <div
                                                            className="prize-label"
                                                            style={{
                                                            color: item.titleColor,
                                                            }}
                                                        >
                                                            {t("CONSOLATION")}
                                                        </div>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <td className="resultCell">{item.p_consolation0}</td>
                                                        <td className="resultCell">{item.p_consolation1}</td>
                                                        <td className="resultCell">{item.p_consolation2}</td>
                                                        <td className="resultCell">{item.p_consolation3}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="resultCell">{item.p_consolation4}</td>
                                                        <td className="resultCell">{item.p_consolation5}</td>
                                                        <td className="resultCell">{item.p_consolation6}</td>
                                                        <td className="resultCell">{item.p_consolation7}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="resultCell"></td>
                                                        <td className="resultCell">{item.p_consolation8}</td>
                                                        <td className="resultCell">{item.p_consolation9}</td>
                                                        <td className="resultCell p-b-40"></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            )}

                                            {item.type === "section-2" && (
                                                <table className="table font-16 font-bold text-uppercase mb-0">
                                                <thead>
                                                    <tr>
                                                    <th
                                                        colSpan="2"
                                                        style={{
                                                        borderBottom: "1px solid " + `${item.color}`,
                                                        }}
                                                    >
                                                        <span className="prize-type text-nowrap">{t("1ST")}&nbsp;</span>
                                                        <span className="prize-type-num">{item.p_5d_1st}</span>
                                                    </th>

                                                    <th
                                                        colSpan="2"
                                                        style={{
                                                        borderBottom: "1px solid " + `${item.color}`,
                                                        }}
                                                    >
                                                        <span className="prize-type text-nowrap">{t("4TH")}&nbsp;</span>
                                                        <span className="prize-type-num">{item.p_5d_4th}</span>
                                                    </th>
                                                    </tr>
                                                    <tr>
                                                    <th
                                                        colSpan="2"
                                                        style={{
                                                        borderBottom: "1px solid " + `${item.color}`,
                                                        }}
                                                    >
                                                        <span className="prize-type text-nowrap">{t("2ND")}&nbsp;</span>
                                                        <span className="prize-type-num">{item.p_5d_2nd}</span>
                                                    </th>
                                                    <th
                                                        colSpan="2"
                                                        style={{
                                                        borderBottom: "1px solid " + `${item.color}`,
                                                        }}
                                                    >
                                                        <span className="prize-type text-nowrap">{t("5TH")}&nbsp;</span>
                                                        <span className="prize-type-num">{item.p_5d_5th}</span>
                                                    </th>
                                                    </tr>
                                                    <tr>
                                                    <th
                                                        colSpan="2"
                                                        style={{
                                                        borderBottom: "1px solid " + `${item.color}`,
                                                        }}
                                                    >
                                                        <span className="prize-type text-nowrap">{t("3RD")}&nbsp;</span>
                                                        <span className="prize-type-num">{item.p_5d_3rd}</span>
                                                    </th>
                                                    <th
                                                        colSpan="2"
                                                        style={{
                                                        borderBottom: "1px solid " + `${item.color}`,
                                                        }}
                                                    >
                                                        <span className="prize-type text-nowrap">{t("6TH")}&nbsp;</span>
                                                        <span className="prize-type-num">{item.p_5d_6th}</span>
                                                    </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                    <th
                                                        colSpan="6"
                                                        className="resultCell"
                                                        style={{
                                                        background: item.color,
                                                        }}
                                                    >
                                                        <div
                                                        className="prize-label"
                                                        style={{
                                                            color: item.titleColor,
                                                        }}
                                                        >
                                                        6D
                                                        </div>
                                                    </th>
                                                    </tr>

                                                    <tr>
                                                    <th>
                                                        <span className="prize-type text-nowrap">{t("1ST")}&nbsp;</span>
                                                    </th>
                                                    <td colSpan="6">
                                                        <span className="prize-type-num">{item.p_6d_1st}</span>
                                                    </td>
                                                    </tr>
                                                    <tr>
                                                    <th>
                                                        <span className="prize-type text-nowrap">{t("2ND")}&nbsp;</span>
                                                    </th>
                                                    <td className="text-left">
                                                        <span className="prize-type-num">{item.p_6d_2nd_a}</span>
                                                    </td>
                                                    <td>
                                                        <span className="prize-type-num text-lowercase font-17">
                                                        {t("OR")}
                                                        </span>
                                                    </td>
                                                    <td className="text-right">
                                                        <span className="prize-type-num">{item.p_6d_2nd_b}</span>
                                                    </td>
                                                    </tr>
                                                    <tr>
                                                    <th>
                                                        <span className="prize-type text-nowrap">{t("3RD")}&nbsp;</span>
                                                    </th>
                                                    <td className="text-left">
                                                        <span className="prize-type-num">{item.p_6d_3rd_a}</span>
                                                    </td>
                                                    <td>
                                                        <span className="prize-type-num text-lowercase font-17">
                                                        {t("OR")}
                                                        </span>
                                                    </td>
                                                    <td className="text-right">
                                                        <span className="prize-type-num">{item.p_6d_3rd_b}</span>
                                                    </td>
                                                    </tr>
                                                    <tr>
                                                    <th>
                                                        <span className="prize-type text-nowrap">{t("4TH")}&nbsp;</span>
                                                    </th>
                                                    <td className="text-left">
                                                        <span className="prize-type-num">{item.p_6d_4th_a}</span>
                                                    </td>
                                                    <td>
                                                        <span className="prize-type-num text-lowercase font-17">
                                                        {t("OR")}
                                                        </span>
                                                    </td>
                                                    <td className="text-right">
                                                        <span className="prize-type-num">{item.p_6d_4th_b}</span>
                                                    </td>
                                                    </tr>
                                                    <tr>
                                                    <th>
                                                        <span className="prize-type text-nowrap">{t("5TH")}&nbsp;</span>
                                                    </th>
                                                    <td className="text-left">
                                                        <span className="prize-type-num">{item.p_6d_5th_a}</span>
                                                    </td>
                                                    <td>
                                                        <span className="prize-type-num text-lowercase font-17">
                                                        {t("OR")}
                                                        </span>
                                                    </td>
                                                    <td className="text-right">
                                                        <span className="prize-type-num">{item.p_6d_5th_b}</span>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            )}

                                            {item.type === "section-1" && (
                                                <table className="table font-16 font-bold text-uppercase mb-0">
                                                <thead></thead>
                                                <tbody>
                                                    <tr>
                                                    <th>
                                                        <span className="prize-type text-nowrap">{t("1ST")}&nbsp;</span>
                                                    </th>
                                                    <td colSpan="6">
                                                        <span className="prize-type-num">{item.p_6d_1st}</span>
                                                    </td>
                                                    </tr>
                                                    <tr>
                                                    <th>
                                                        <span className="prize-type text-nowrap">{t("2ND")}&nbsp;</span>
                                                    </th>
                                                    <td className="text-left">
                                                        <span className="prize-type-num letter-spacing-num">
                                                        {item.p_6d_2nd_a}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span
                                                        className="prize-type-num text-lowercase font-17"
                                                        style={{
                                                            color: item.color,
                                                        }}
                                                        >
                                                        {t("OR")}
                                                        </span>
                                                    </td>
                                                    <td className="text-right">
                                                        <span className="prize-type-num letter-spacing-num">
                                                        {item.p_6d_2nd_b}
                                                        </span>
                                                    </td>
                                                    </tr>
                                                    <tr>
                                                    <th>
                                                        <span className="prize-type text-nowrap">{t("3RD")}&nbsp;</span>
                                                    </th>
                                                    <td className="text-left">
                                                        <span className="prize-type-num letter-spacing-num">
                                                        {item.p_6d_3rd_a}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span
                                                        className="prize-type-num text-lowercase font-17"
                                                        style={{
                                                            color: item.color,
                                                        }}
                                                        >
                                                        {t("OR")}
                                                        </span>
                                                    </td>
                                                    <td className="text-right">
                                                        <span className="prize-type-num letter-spacing-num">
                                                        {item.p_6d_3rd_b}
                                                        </span>
                                                    </td>
                                                    </tr>
                                                    <tr>
                                                    <th>
                                                        <span className="prize-type text-nowrap">{t("4TH")}&nbsp;</span>
                                                    </th>
                                                    <td className="text-left">
                                                        <span className="prize-type-num letter-spacing-num">
                                                        {item.p_6d_4th_a}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span
                                                        className="prize-type-num text-lowercase font-17"
                                                        style={{
                                                            color: item.color,
                                                        }}
                                                        >
                                                        {t("OR")}
                                                        </span>
                                                    </td>
                                                    <td className="text-right">
                                                        <span className="prize-type-num letter-spacing-num">
                                                        {item.p_6d_4th_b}
                                                        </span>
                                                    </td>
                                                    </tr>
                                                    <tr>
                                                    <th>
                                                        <span className="prize-type text-nowrap">{t("5TH")}&nbsp;</span>
                                                    </th>
                                                    <td className="text-left">
                                                        <span className="prize-type-num letter-spacing-num">
                                                        {item.p_6d_5th_a}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span
                                                        className="prize-type-num text-lowercase font-17"
                                                        style={{
                                                            color: item.color,
                                                        }}
                                                        >
                                                        {t("OR")}
                                                        </span>
                                                    </td>
                                                    <td className="text-right">
                                                        <span className="prize-type-num letter-spacing-num">
                                                        {item.p_6d_5th_b}
                                                        </span>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            )}
                                            </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </Slider>
            </div>
            {/* <div className="pool-result" style={{ paddingBottom: '30px' }}>
                <div style={{ marginBottom: "20px", position: "relative" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button type="button" className="btn btn-icon" style={{ color: "#002e6c", position: "absolute", top: "5px", left: "16px", zIndex: "1" }} onClick={() => {
                            setPoolDate(new Date(poolDate.setDate(poolDate.getDate() - 1)))
                        }}><i className="fa fa-chevron-left"></i></button>
                    </div>
                    <div className="pool-calendar" style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                        <DatePicker customInput={<DateCustomInput />} dateFormat="dd/MM/yyyy" placeholder={t("SELECT_DATE")} selected={poolDate} onChange={date => { setPoolDate(date); }} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button type="button" className="btn btn-icon" style={{ color: "#002e6c", position: "absolute", top: "5px", right: "16px" }} onClick={() => setPoolDate(new Date(poolDate.setDate(poolDate.getDate() + 1)))}><i className="fa fa-chevron-right"></i></button>
                    </div>
                </div>
                {selectedPool != null && moment(selectedPool["drawDateString"], "DD/MM/YYYY").format('DD/MM/YYYY') !== "01/01/0001" ? <>
                <div className="form-group">
                    {poolOption.length > 0 &&
                        <OwlCarousel options={_OPTIONS}>
                            {
                                poolOption.map(el => {
                                    const { poolKey, is5D6D } = el;

                                    var style = {};

                                    if (selectedPool != null && poolKey == selectedPool["poolKey"] && is5D6D == selectedPool["is5D6D"]) {
                                        style = { "border": "2px solid #FFC159", "height": "40px", "borderRadius": "12px" };
                                    } else {
                                        style = { "border": "2px solid transparent", "height": "40px", "borderRadius": "12px" };
                                    }

                                    return (
                                        <div
                                            className="owl-item-pool"
                                            onClick={() => { setSelectedPool(poolOption.filter(i => i.poolKey == poolKey && (is5D6D == Status._ENABLED.toString()) == i.is5D6D)[0]) }}

                                            key={poolKey + "," + (is5D6D ? Status._ENABLED : Status._DISABLED)}
                                        >
                                            <img src={_POOL_IMAGE[poolKey]} alt="" className="media-object" style={style} />
                                        </div>
                                    );
                                })
                            }
                        </OwlCarousel>
                    }
                </div>
                <div className="hr-right mt-3 mb-3"></div>
                    {selectedPool == null &&
                        <div style={{ textAlign: "center", color: activeTheme === 0 ? "#002e6c" : "white" }}>{t("NO_DATA_FOUND")}</div>
                    }
                    {selectedPool != null &&
                        <div className={"card-package card-result mb-3" + (activeTheme === 0 ? " pool-result" : " pool-result-modern")}>
                            <div className="card-header resultCell bg-transparent">
                                <div className="pool-name">{selectedPool["poolName"]}</div>
                                <div style={{ margin: "1px", fontSize: "14px" }}>{moment(selectedPool["drawDateString"], "DD/MM/YYYY").format('ddd, DD/MM/YYYY')}</div>
                            </div>
                            <div className="card-body p-0">
                                {
                                    selectedPool["is5D6D"] && !stringIsNullOrEmpty(selectedPool["p_5d_1st"]) &&
                                    <table className="table" style={{ width: "100%", textAlign: "center" }}>
                                        <tbody>
                                            <tr>
                                                <th colSpan="4" className="resultCell">5D</th>
                                            </tr>
                                            <tr>
                                                <th className="resultCell">{t("1ST")}</th>
                                                <td className="resultCell">{selectedPool["p_5d_1st"]}</td>
                                                <th className="resultCell">{t("4TH")}</th>
                                                <td className="resultCell">{selectedPool["p_5d_4th"]}</td>
                                            </tr>
                                            <tr>
                                                <th className="resultCell">{t("2ND")}</th>
                                                <td className="resultCell">{selectedPool["p_5d_2nd"]}</td>
                                                <th className="resultCell">{t("5TH")}</th>
                                                <td className="resultCell">{selectedPool["p_5d_5th"]}</td>
                                            </tr>
                                            <tr>
                                                <th className="resultCell">{t("3RD")}</th>
                                                <td className="resultCell">{selectedPool["p_5d_3rd"]}</td>
                                                <th className="resultCell">{t("6TH")}</th>
                                                <td className="resultCell">{selectedPool["p_5d_6th"]}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }
                                {
                                    selectedPool["is5D6D"] && !stringIsNullOrEmpty(selectedPool["p_6d_1st"]) &&
                                    <table className="table" style={{ width: "100%", textAlign: "center" }}>
                                        <tbody>
                                            <tr>
                                                <th className="resultCell" colSpan="4">6D</th>
                                            </tr>
                                            <tr>
                                                <th className="resultCell">{t("1ST")}</th>
                                                <td className="resultCell" colSpan="3">{selectedPool["p_6d_1st"]}</td>
                                            </tr>
                                            <tr>
                                                <th className="resultCell">{t("2ND")}</th>
                                                <td className="resultCell">{selectedPool["p_6d_2nd_a"]}</td>
                                                <td className="resultCell">{t("OR")}</td>
                                                <td className="resultCell">{selectedPool["p_6d_2nd_b"]}</td>
                                            </tr>
                                            <tr>
                                                <th className="resultCell">{t("3RD")}</th>
                                                <td className="resultCell">{selectedPool["p_6d_3rd_a"]}</td>
                                                <td className="resultCell">{t("OR")}</td>
                                                <td className="resultCell">{selectedPool["p_6d_3rd_b"]}</td>
                                            </tr>
                                            <tr>
                                                <th className="resultCell">{t("4TH")}</th>
                                                <td className="resultCell">{selectedPool["p_6d_4th_a"]}</td>
                                                <td className="resultCell">{t("OR")}</td>
                                                <td className="resultCell">{selectedPool["p_6d_4th_b"]}</td>
                                            </tr>
                                            <tr>
                                                <th className="resultCell">{t("5TH")}</th>
                                                <td className="resultCell">{selectedPool["p_6d_5th_a"]}</td>
                                                <td className="resultCell">{t("OR")}</td>
                                                <td className="resultCell">{selectedPool["p_6d_5th_b"]}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }
                                {
                                    !selectedPool["is5D6D"] &&
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th colSpan="5" className="resultCell">
                                                    <span className="prize-type text-nowrap">{t("1ST")}</span><span className="prize-type-num">{selectedPool["p_4d_1st"]}</span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th colSpan="5" className="resultCell">
                                                    <span className="prize-type text-nowrap">{t("2ND")}</span><span className="prize-type-num">{selectedPool["p_4d_2nd"]}</span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th colSpan="5" className="resultCell p-b-20">
                                                    <span className="prize-type text-nowrap">{t("3RD")}</span><span className="prize-type-num">{selectedPool["p_4d_3rd"]}</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th colSpan="5" className="resultCell"><div className='prize-label'>{t("SPECIAL")}</div></th>
                                            </tr>
                                            <tr>
                                                <td className="resultCell">{selectedPool["p_starter0"]}</td>
                                                <td className="resultCell">{selectedPool["p_starter1"]}</td>
                                                <td className="resultCell">{selectedPool["p_starter2"]}</td>
                                                <td className="resultCell">{selectedPool["p_starter3"]}</td>
                                                <td className="resultCell">{selectedPool["p_starter4"]}</td>
                                            </tr>
                                            <tr>
                                                <td className="resultCell">{selectedPool["p_starter5"]}</td>
                                                <td className="resultCell">{selectedPool["p_starter6"]}</td>
                                                <td className="resultCell">{selectedPool["p_starter7"]}</td>
                                                <td className="resultCell">{selectedPool["p_starter8"]}</td>
                                                <td className="resultCell">{selectedPool["p_starter9"]}</td>
                                            </tr>
                                            <tr>
                                                <td className="resultCell"></td>
                                                <td className="resultCell">{selectedPool["p_starter10"]}</td>
                                                <td className="resultCell">{selectedPool["p_starter11"]}</td>
                                                <td className="resultCell">{selectedPool["p_starter12"]}</td>
                                                <td className="resultCell p-b-40"></td>
                                            </tr>
                                            <tr>
                                                <th colSpan="5" className="resultCell"><div className='prize-label'>{t("CONSOLATION")}</div></th>
                                            </tr>
                                            <tr>
                                                <td className="resultCell">{selectedPool["p_consolation0"]}</td>
                                                <td className="resultCell">{selectedPool["p_consolation1"]}</td>
                                                <td className="resultCell">{selectedPool["p_consolation2"]}</td>
                                                <td className="resultCell">{selectedPool["p_consolation3"]}</td>
                                                <td className="resultCell">{selectedPool["p_consolation4"]}</td>
                                            </tr>
                                            <tr>
                                                <td className="resultCell">{selectedPool["p_consolation5"]}</td>
                                                <td className="resultCell">{selectedPool["p_consolation6"]}</td>
                                                <td className="resultCell">{selectedPool["p_consolation7"]}</td>
                                                <td className="resultCell">{selectedPool["p_consolation8"]}</td>
                                                <td className="resultCell">{selectedPool["p_consolation9"]}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    }</> : <div style={{ textAlign: "center", color: activeTheme === 0 ? "black" : "white" }}>{t("NO_DATA_FOUND")}</div>}
            </div> */}
        </>
    );
};

export default PoolResult;