import { forEach, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { useSelector, useDispatch } from "react-redux";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import ApiEngine from "../../util/ApiEngine.js";
import { ApiKey, ApiUrl, WebUrl, LanguageKey, TransactionType, PanelType, LanguageOption, SessionKey } from "../../util/Constant";
import { createFormBody, createMultiPartFormBody, numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import { Modal, ModalBody } from "reactstrap";
import moment from "moment";
import { actions } from "react-table";
import 'moment/locale/en-gb';
import parse from 'react-html-parser';

/// <summary>
/// Author: -
/// </summary>
const DailyCheckIn = (props) => {
  var _dispatch = useDispatch();
  const { t } = useTranslation();
  const _history = useHistory();
  const [memberPoint, setMemberPoint] = useState(-1);
  const [isCheckInModel, setIsCheckInModel] = useState(false);
  const [dailyCheckInData, setDailyCheckInData] = useState([]);
  const [checkInPoint, setCheckInPoint] = useState(0);
  const [totalCheckInDay, setTotalCheckInDay] = useState(0);
  const [content, setContent] = useState("");
  const userId = useSelector(
    (state) => state.authState.userData.userId
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const [checkInRow, setCheckInRow] = useState(0);
  const [todayDetail, setTodayDetail] = useState([]);

  const [claimed, setClaimed] = useState(false);
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [companyTimeZone, setCompanyTimeZone] = useState(0);

  useEffect(() => {
    init();
    //getMemberLoyaltyPoints();
    //getDailyCheckInData();
    //moment.locale('en-gb');
  }, []);

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_DAILY_CHECK_IN_STATUS);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let statusActive = responseJson[ApiKey._API_DATA_KEY] === true;
      setShowCheckIn(statusActive);
      if (statusActive) {
        getMemberLoyaltyPoints();
        getDailyCheckInData();
        moment.locale('en-gb');
      }
      else {
        _dispatch(showResponseMessage(false, t("DAILY_CHECK_IN_INACTIVE")));
        setTimeout(() => window.location.replace(WebUrl._URL_MAIN), 2000);
      }
    }
  }

  const getMemberLoyaltyPoints = async () => {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_LOYALTY_POINTS_BY_ID + "?id=" + userId);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMemberPoint(responseJson[ApiKey._API_DATA_KEY]);
    }
    _dispatch(setIdle());
  }

  useEffect(() => {
    getDailyCheckInData();
  }, [(LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0] !== undefined) ? (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0].languageId) : 7]);

  const getDailyCheckInData = async () => {
    _dispatch(setBusy());
    const dailyArr = [];
    const daysOfWeek = [];
    var language = (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0] !== undefined) ? (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0].languageId) : 7;
    let responseJsonData = await ApiEngine.get(ApiUrl._API_CHECK_MEMBER_DAILY_CHEKC_IN + "?LanguageId=" + language);
    if (responseJsonData[ApiKey._API_SUCCESS_KEY]) {

      let data = responseJsonData[ApiKey._API_DATA_KEY];

      const dailyRate = data["dataPoints"]["details"];
      const dailyRecord = responseJsonData[ApiKey._API_DATA_KEY]["latestRecord"];
      const timeZone = responseJsonData[ApiKey._API_DATA_KEY]["timeZone"];
      setCompanyTimeZone(timeZone);
      const customFirstDay = moment.utc().add(timeZone, 'hours').day(dailyRate[0].days).day();
      let today = moment.utc().add(timeZone, 'hours'); // Current date
      let currentDayOfWeek = today.day();
      let dayDifference = (currentDayOfWeek - customFirstDay + 7) % 7;
      let dateOfFirstDay = moment.utc().add(timeZone, 'hours').add(-dayDifference, 'days');

      //const today = new Date(); // Current date
      //const currentDayOfWeek = today.getDay();
      //const dayDifference = (currentDayOfWeek - customFirstDay + 7) % 7;

      // Set the date to the first day of the week
      //today.setDate(today.getDate() - dayDifference);

      // Generate an array of dates for the week
      const weekDates = [];
      //const dateFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };

      for (let i = 0; i < 7; i++) {
        let currentDate = dateOfFirstDay.clone().add(i, 'days');
        const formattedDate = `${currentDate.format("DD")}-${currentDate.format("MM")}-${currentDate.format("YYYY")}`;
        //const currentDate = new Date(today);
        //currentDate.setDate(today.getDate() + i);

        //const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;

        weekDates.push(formattedDate);
      }

      for (var i = 0; i < 7; i++) {
        dailyArr.push({
          id: dailyRate[i].id,
          days: dailyRate[i].days,
          points: dailyRate[i].points,
          credit: dailyRate[i].credit,
          isCredit: dailyRate[i].isCredit,
          maxDay: dailyRate[i].maxDay,
          minTurnover: dailyRate[i].minTurnover,
          date: moment(weekDates[i])._i,
          //date: weekDates[i],
          isCheckIn: false
        })
      }

      for (var x = 0; x < dailyRecord.length; x++) {
        const recordDate = moment(dailyRecord[x].checkIn).format('DD-MM-YYYY');
        //const recordDate = moment(dailyRecord[x].checkIn).format('DD-MM-YYYY');
        dailyArr.filter((i) => i.date == recordDate).map(day => {
          day.isCheckIn = true;
        })
      }

      setDailyCheckInData(dailyArr);
      setContent(dailyRate[0]["content"]);
      setClaimed(data["isTodayCheckIn"]);
      if (!stringIsNullOrEmpty(responseJsonData[ApiKey._API_DATA_KEY]["totalCheckIn"])) {
        if (moment(responseJsonData[ApiKey._API_DATA_KEY]["totalCheckIn"][0]["date"]).format("YYYY-MM-DD") == moment.utc().add(timeZone, 'hours').format("YYYY-MM-DD") || moment(responseJsonData[ApiKey._API_DATA_KEY]["totalCheckIn"][0]["date"]).format("YYYY-MM-DD") == moment.utc().add(timeZone, 'hours').add(-1, 'days').format("YYYY-MM-DD")) {
          setTotalCheckInDay(responseJsonData[ApiKey._API_DATA_KEY]["totalCheckIn"][0]["count"])
        } else {
          setTotalCheckInDay(0);
        }
      }
      setCheckInRow(dailyArr.filter((i) => i.isCheckIn).length);

      setTodayDetail(dailyArr.filter((i) => i.days == moment.utc().add(timeZone, 'hours').format('dddd'))[0]);
    }
    _dispatch(setIdle());
  }

  async function handleSubmit() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(ApiUrl._API_CREATED_CHECK_IN);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setIsCheckInModel(true);
      getDailyCheckInData();
      getMemberLoyaltyPoints();
      if (todayDetail.isCredit) {
        setCheckInPoint(todayDetail !== undefined ? todayDetail.credit : '0');
      } else {
        setCheckInPoint(todayDetail !== undefined ? todayDetail.points : '0');
      }
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  }

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_MAIN}
        title={t("DAILY_CHECK_IN")}
      />
      <div className="container" style={{ padding: "0" }}>
        {showCheckIn && <div>
          <div>
            <div className="daily-check-in">
              <div className="daily-coin" onClick={() => _history.push(WebUrl._URL_LOYALTY_REDEMPTION)}>
                <div className="daily-coin-div">
                  <img src={require('../../assets/img/v3/jam_coin-f.svg')} />
                  {memberPoint == -1
                    ? t("LOADING") + "... "
                    : " " +
                    numberWithCurrencyFormat(
                      parseFloat(memberPoint),
                      2
                    )}
                  <img src={require('../../assets/img/v3/arrow-right-blue.png')} />
                </div>
              </div>
              <div className="daily-coin">
                <div className="daily-coin-div">
                  <img src={require('../../assets/img/v3/steak.svg')} />
                  {totalCheckInDay == 0 ? 0 : totalCheckInDay} {t("DAY_STREAK")}
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div className="check-in-big-div">
            <div>
              <div style={{ display: 'flex', gridColumnGap: '5px', alignItems: 'center' }}>
                {t("CHECK_IN")}
                <div style={{ fontSize: '16px', color: 'rgba(51, 105, 255, 1)' }}>{checkInRow == 0 ? 0 : checkInRow}</div>
                {t("DAYS_IN_A_ROW")}
              </div>
              <div className="daily-check-in-day">
                {dailyCheckInData &&
                  dailyCheckInData.length > 0 &&
                  map(dailyCheckInData, (item, index) => {
                    return (
                      <div key={index}>
                        {
                          item.isCheckIn ?
                            <div className={item.days == moment.utc().add(companyTimeZone, 'hours').format('dddd') ? "gallery-check-in-today" : "gallery-check-in"}>

                              {item.isCredit ?
                                <>
                                  <div className="redicon redicon-text">
                                    {t("CHECK_IN_CREDIT", { value: item.credit })}
                                  </div>
                                  <div>
                                    <img src={require('../../assets/img/v3/Checked-in.svg')} />
                                  </div>
                                </>
                                :
                                <>
                                  <div className="redicon redicon-text">
                                    {t("CHECK_IN_POINT", { value: item.points })}
                                  </div>
                                  <div>
                                    <img src={require('../../assets/img/v3/Checked-in.svg')} />
                                  </div>
                                </>
                              }

                              {item.days == moment.utc().add(companyTimeZone, 'hours').format('dddd') ?
                                <div className="desc-div">
                                  {t("TODAY")}
                                </div>
                                :
                                <div className="desc-div">
                                  {t(item.days)}
                                </div>
                              }
                            </div>
                            :
                            <div className={item.days == moment.utc().add(companyTimeZone, 'hours').format('dddd') ? "gallery-today" : "gallery"}>
                              {item.isCredit ?
                                <>

                                  <div className="redicon redicon-text">
                                    {t("CHECK_IN_CREDIT", { value: item.credit })}
                                  </div>
                                  <img className="img-checkin" src={require('../../assets/img/v3/icon-credit.svg')} />
                                </>
                                :
                                <>

                                  <div className="redicon redicon-text">
                                    {t("CHECK_IN_POINT", { value: item.points })}
                                  </div>
                                  <img className="img-checkin" src={require('../../assets/img/v3/jam_coin-f.svg')} />
                                </>}

                              {item.days == moment.utc().add(companyTimeZone, 'hours').format('dddd') ?
                                <div className="desc-div">
                                  {t("TODAY")}
                                </div>
                                :
                                <div className="desc-div">
                                  {t(item.days)}
                                </div>
                              }
                            </div>
                        }
                      </div>
                    )
                  })
                }

              </div>
              <div className="check-in-button">
                {claimed ?
                  <button type="button" className="check-in-button">
                    <div className="check-in-text-disable" onClick={() => _history.push(WebUrl._URL_PROMOTION)}>
                      {t("EARN_MORE_COINS")}
                    </div>
                  </button>
                  :
                  <>
                    <button type="button" className="check-in-button" onClick={() => handleSubmit()}>
                      <div className="check-in-text" >
                        {todayDetail.isCredit ?
                          <>
                            {t("CHECK_IN_GET_CREDIT", { value: todayDetail !== undefined ? todayDetail.credit : '0' })}
                          </>
                          :
                          <>
                            {t("CHECK_IN_GET_POINT", { value: todayDetail !== undefined ? todayDetail.points : '0' })}
                          </>
                        }


                      </div>
                    </button>
                  </>
                }

              </div>
            </div>
          </div>
          <div className="hr-left mb-3 mt-3"></div>
          <div className="check-in-content-div">
            <div>
              <div style={{ padding: "10px 0px 0px 10px" }}>
                {parse(content)}
              </div>
            </div>
          </div>
        </div>}
      </div>
      <Modal
        fade={true}
        contentClassName="modal"
        id="success-modal"
        isOpen={isCheckInModel}
        centered
        toggle={() => {
          setIsCheckInModel(false);
        }}
      >
        <ModalBody>

          <div className="edit-vt-data" style={{ padding: '20px' }}>
            <div className="text-white text-center" style={{ fontSize: "24px" }}>{t("CONGRATULATIONS")}</div>
            <div>
              <div className="video-data" style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                <div><img style={{ width: '60px' }} src={require("../../assets/img/v3/jam_coin-f.svg")} ></img></div>
              </div>
              <div className="video-data">
                {
                  todayDetail.isCredit ?
                    <div style={{ fontSize: "12px" }}>{t("YOU_HAVE_CREDIT_RECEIVED", { value: checkInPoint })}</div>
                    :
                    <div style={{ fontSize: "12px" }}>{t("YOU_HAVE_POINTS_RECEIVED", { value: checkInPoint })}</div>
                }

              </div>
              <div className="video-data">
                <div style={{ fontSize: "12px" }}>{t("CHECK_IN_FOR_DAY")}</div>
              </div>
              <button className="btn custom-btn w-100 mt-4" onClick={() => setIsCheckInModel(false)}>{t("EARN_MORE_COINS")}</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DailyCheckIn;
