import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Input from "../../components/custom/Input";
import { setBusy, setIdle, showResponseMessage, setDownlineSearchText, setDownlineQuery, setPageSize, setY } from "../../redux/AppAction.js";
import ApiEngine from "../../util/ApiEngine.js";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { map } from "lodash";
import Accordion from "react-bootstrap/Accordion";
import moment from 'moment';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { createMultiPartFormBody, numberWithCurrencyFormat } from "../../util/Util";
import SweetAlert from "react-bootstrap-sweetalert";

/// <summary>
/// Author: -
/// </summary>
const VipEvent = props => {
  const { t, i18n } = useTranslation();
  var _history = useHistory();
  const [activeDropdown, setActiveDropdown] = useState(-1);
  const [displayDataIndex, setDisplayDataIndex] = useState(-1);
  const [claimData, setClaimData] = useState([]);
  const [claimDataDone, setClaimDataDone] = useState(false);
  let _userData = useSelector(state => state.authState.userData);
  let _savedData = useSelector(state => state.appState.savedText);
  let _savedQuery = useSelector(state => state.appState.savedQuery);
  let _savedPageSize = useSelector(state => state.appState.pageSize);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const hideSummary = params.get("GAzobE73Vse4nr8WpufA");

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_SHAREHOLDER_UNCLAIM);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setClaimData(data["data"]);
    }
    setClaimDataDone(true);
  }
  return (
    <>
      <div className="hr-right mt-3 mb-3"></div>
      <div className="h3-with-arrow mb-3">
        <h3 className="mb-0">{t("UNCLAIMED")}</h3>
        <div onClick={() => _history.push({ pathname: WebUrl._URL_SHAREHOLDER_CLAIM, state: { hideSummary: hideSummary } })}>
          <img src={require("../../assets/img/v3/link.png")} alt="link" />
        </div>
      </div>
      <div>
        <Accordion>
          {claimData &&
            claimDataDone &&
            claimData.length > 0 ?
            claimData.map((item, index) => {
              return (
                <Accordion.Item key={index} eventKey={index} className="claim-commission-item">
                  <Accordion.Header className="comm-item">
                    <div className="first">
                      <div>{t("MY_SALES") + " - " + numberWithCurrencyFormat(item.netProfit, 2, true)}</div>
                      <div className="date-text mt-1">{item.date}</div>
                    </div>
                    <div className="red-label-box ">
                      {t("UNCLAIMED")}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="comm-data-drop">
                      <div>{t("DOWNLINE_COMM") + " - " + numberWithCurrencyFormat(item.downlineSales, 2, true)}</div>
                      <div>{t("MY_COMM") + " - " + numberWithCurrencyFormat(item.ownSales, 2, true)}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )
            })
            :
            <div>{t("NO_UNCLAIMED_DATA")}</div>
          }
        </Accordion>
      </div>
      <div className="hr-left mt-3 mb-3"></div>
      <div className="h3-with-arrow mb-3">
        <h3 className="mb-0">{t("CURRENT_SHAREHOLDER_DOWNLINES")}</h3>
        <div onClick={() => _history.push({ pathname: WebUrl._URL_SHAREHOLDER_DOWNLINE, state: { hideSummary: hideSummary } })}>
          <img src={require("../../assets/img/v3/link.png")} alt="link" />
        </div>
      </div>
      <Accordion className="vip-event-accordion">
        <DynamicLoadTable pageSize={_savedPageSize} apiQuery={_savedQuery} searchEnabled={true} autoscrollEnabled={true} render={(downline, i) => {
          if (downline.isShareHolder === true) {
            return (
              <>
                <Accordion.Item
                  key={i}
                  eventKey={i}
                >
                  <Accordion.Header
                    onClick={() => {
                      if (displayDataIndex === i) {
                        setDisplayDataIndex(-1);
                      }
                      else {
                        setDisplayDataIndex(i);
                      }
                    }}>
                    <div className="text-left">
                      {downline['username']} <br />
                      <div className="font11 mt-1">{downline['phoneNumber']}</div>
                    </div>
                    <div className="hide-summary-box">
                      {downline['hideSummary'] == true &&
                        <div><img src={require("../../assets/img/v3/hide-summary.png")} alt="hide" /></div>
                      }
                      <i className={"fas fa-chevron-" + (displayDataIndex === i ? "down" : "right")}></i>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="vip-event-downline-data">
                      <div className="shareholder-data shareholder-date">
                        <div className="data-left">{t("SHAREHOLDER_DATE")}</div>
                        <div className="data-right">{moment(downline.shareHolderDate).format("YYYY-MM-DD")}</div>
                      </div>
                      <div className="shareholder-data shareholder-percent">
                        <div className="data-left">{t("SHAREHOLDER_PERCENT")}</div>
                        <div className="data-right">{downline.sharePercent}%</div>
                      </div>
                      <div className="edit-sh-btn">
                        {t("EDIT_SHAREHOLDER_DETAIL")}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </>
            );
          }
        }} />
      </Accordion>
    </>
  );

}

export default VipEvent;