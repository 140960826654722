import React from 'react';
import { Redirect } from 'react-router';
import { WebUrl } from "../util/Constant";

import Login from '../pages/Login';
import Register from './../pages/Register';

import Downline from '../pages/home/Downline';
import DownlineDetails from '../pages/home/DownlineDetails';
import EditDownline from '../pages/home/EditDownline';
import Settings from '../pages/home/Settings';
import Dashboard from './../pages/home/Dashboard';
import DashboardV2 from './../pages/home/DashboardV2';
import Kpi from '../pages/home/Kpi';
import News from '../pages/home/News';
import VideoTutorial from '../pages/home/VideoTutorial';
import NewsDetails from '../pages/home/NewsDetails';

import Transaction from './../pages/transaction/Transaction';
import Withdrawal from '../pages/transaction/Withdrawal';
import WithdrawalRecord from './../pages/transaction/WithdrawalRecord';
// import Deposit from '../pages/transaction/DepositMobile';
import Deposit from '../pages/transaction/Deposit';
import DepositRecord from './../pages/transaction/DepositRecord';

import PoolResult from "./../pages/game/PoolResult";
import GameLobby from "./../pages/game/GameLobby";
import UnderMaintenance from "./../pages/game/UnderMaintenance";

import ReportMenu from './../pages/report/ReportMenu';
import WalletHistory from "./../pages/report/WalletHistory";
import WalletHistoryMobile from "./../pages/report/WalletHistoryMobile";
import CommissionHistory from "./../pages/report/CommissionHistory";
import BonusHistory from "./../pages/report/BonusHistory";
import InternaTransferHistory from "./../pages/report/InternaTransferHistory";
import RolloverHistory from "./../pages/report/RolloverHistory";
import TransactionHistory from "./../pages/report/TransactionHistory";
import GameHistory from "./../pages/report/GameHistory";
import GameDetails from '../pages/report/GameDetails';

import ManageReferralCode from "./../pages/member/ManageReferralCode";
import ReferralCodeDetail from "./../pages/member/ReferralCodeDetail";
import ForgetPin from "./../pages/member/ForgetPin";

import DownlineGroupDetail from '../pages/member/DownlineGroupDetail';
import MemberProductRateDetail from '../pages/member/MemberProductRateDetail';
import UnclaimCommissionHistory from '../pages/member/UnclaimCommissionHistory';

import Maintenance from "./../pages/Maintenance";
import ContactUs from './../pages/home/ContactForm';
import LiveChatPage from './../pages/home/LiveChat';

import Shareholder from '../pages/shareholder/Shareholder';
import ShareholderDownline from '../pages/shareholder/Downline';
import ShareholderClaim from '../pages/shareholder/Claim';
import ShareholderDetails from '../pages/shareholder/ShareholderDetails';
import EditShareholder from '../pages/shareholder/EditShareholder';
import TnC from '../pages/home/TnC';
import RewardsTnC from '../pages/home/RewardsTnC';
import ClaimRecord from '../pages/shareholder/ClaimRecord';
import SummaryDownlineSales from '../pages/shareholder/SummaryDownlineSales';
import DownlineProductRateDetail from '../pages/member/DownlineProductRateDetail';

import Rewards from '../pages/home/Rewards';
import RewardsHistory from '../pages/report/RewardsHistory';
import RewardsDetail from '../pages/report/RewardsDetail';
import GamePage from '../pages/home/GamePage';
import CamoHomePage from '../pages/home/CamoHomePage';
import CamoRegister from '../pages/home/CamoRegister';
import WalletTransferHistory from '../pages/report/WalletTransferHistory';
import About from "../pages/home/About";
import HelpCentre from "../pages/setting/HelpCentre";
import HelpCentreCategory from "../pages/setting/HelpCentreCategory";
import HelpCentreSubCategory from "../pages/setting/HelpCentreSubCategory";
import HelpCentreFaq from "../pages/setting/HelpCentreFaq";
import Transfer from "../pages/transaction/Transfer";
import ProviderGameLobby from "../pages/game/ProviderGameLobby";
import MainWallet from '../pages/home/MainWallet';
import ReloadHistory from '../pages/report/ReloadHistory';
import Notification from '../pages/home/Notification';
import Referral from '../pages/member/Referral';
import CreateReferral from '../pages/member/CreateReferral';
import EditReferral from '../pages/member/EditReferral';
import ReferralGroup from '../pages/member/ReferralGroup';
import ReferralGroupDetails from '../pages/member/ReferralGroupDetails';
import Commission from '../pages/member/Commission';
import LoyaltyRedemption from "../pages/home/LoyaltyRedemption";
import LoyaltyRedemptionHistory from "../pages/home/LoyaltyRedemptionHistory";
import LoyaltyRedemptionTnC from "../pages/home/LoyaltyRedemptionTnC";
import UnclaimLoyaltyPoints from "../pages/member/UnclaimLoyaltyPoints";
import LoyaltyPointsHistory from "../pages/report/LoyaltyPointsHistory";
import ProductCommRate from "../pages/member/ProductCommRate";
import NotificationDetails from '../pages/home/NotificationDetails';
import LoyaltyVoucherDetails from "../pages/home/LoyaltyVoucherDetails";
import DepositTutorial from '../pages/transaction/DepositTutorial';

//DalyCheckIn
import DailyCheckIn from '../pages/home/DailyCheckIn';

import Promotion from "../pages/home/Promotion";
import PromotionDetail from "../pages/home/PromotionDetail";


/// <summary>
/// Author : 
/// </summary>
export const _LOGIN_ROUTE =
{
  path: WebUrl._URL_MAIN,
  exact: false,
  authRequired: false,
  component: () => <Login />
}

export const _ROUTES = [
  {
    path: "/",
    exact: true,
    authRequired: false
  },
  {
    path: "/login",
    exact: true,
    authRequired: false,
    component: () => <Redirect to={WebUrl._URL_MAIN} />,
  },
  {
    path: WebUrl._URL_UNDER_MAINTENANCE,
    exact: true,
    title: 'Under Maintenance',
    authRequired: false,
    component: () => <UnderMaintenance />
  },
  {
    path: WebUrl._URL_MAIN,
    exact: true,
    authRequired: false,
    component: () => <Login />
  },
  {
    path: WebUrl._URL_DASHBOARD,
    exact: true,
    title: 'Dashboard',
    authRequired: true,
    component: () => <Redirect to={WebUrl._URL_LOGIN} />
  },
  {
    path: WebUrl._URL_DASHBOARD_V2,
    exact: true,
    title: 'DashboardV2',
    authRequired: true,
    component: () => <Redirect to={WebUrl._URL_LOGIN} />
  },
  {
    path: WebUrl._URL_SETTINGS,
    exact: true,
    title: 'Settings',
    authRequired: true,
    component: () => <Settings />
  },
  {
    path: WebUrl._URL_REGISTER,
    exact: true,
    title: 'Register',
    authRequired: false,
    component: () => <Redirect to={WebUrl._URL_LOGIN} />
  },
  {
    path: WebUrl._URL_DOWNLINE,
    exact: true,
    title: 'Downline',
    authRequired: true,
    component: () => <Downline />
  },
  {
    path: WebUrl._URL_DOWNLINE_DETAILS,
    exact: true,
    title: 'Downline Details',
    authRequired: true,
    component: () => <DownlineDetails />
  },
  {
    path: WebUrl._URL_EDIT_DOWNLINE,
    exact: true,
    title: 'Edit Downline',
    authRequired: true,
    component: () => <EditDownline />
  },
  {
    path: WebUrl._URL_REPORTS,
    exact: true,
    title: 'Reports',
    authRequired: true,
    component: () => <ReportMenu />
  },
  {
    path: WebUrl._URL_TRANSACTION,
    exact: true,
    title: 'Transaction',
    authRequired: true,
    component: () => <Transaction reload={false} />
  },
  {
    path: WebUrl._URL_RELOAD_TRANSACTION,
    exact: true,
    title: 'Reload',
    authRequired: true,
    component: () => <Transaction reload={true} />
  },
  {
    path: WebUrl._URL_WITHDRAWAL_RECORD,
    exact: true,
    title: 'Withdrawal Record',
    authRequired: true,
    component: () => <WithdrawalRecord />
  },
  {
    path: WebUrl._URL_DEPOSIT_RECORD,
    exact: true,
    title: 'Deposit Record',
    authRequired: true,
    component: () => <DepositRecord />
  },
  {
    path: WebUrl._URL_WALLET_HISTORY,
    exact: true,
    title: 'Wallet History',
    authRequired: true,
    component: () => <WalletHistory />
  },
  {
    path: WebUrl._URL_WALLET_HISTORY_MOBILE,
    exact: true,
    title: 'Wallet History',
    authRequired: true,
    component: () => <WalletHistoryMobile />
  },
  {
    path: WebUrl._URL_TRANSACTION_HISTORY,
    exact: true,
    title: 'Transaction History',
    authRequired: true,
    component: () => <TransactionHistory />
  },
  {
    path: WebUrl._URL_BONUS_HISTORY,
    exact: true,
    title: 'Bonus History',
    authRequired: true,
    component: () => <BonusHistory />
  },
  {
    path: WebUrl._URL_INTERAL_TRASNFER_HISTORY,
    exact: true,
    title: 'Internal Transfer History',
    authRequired: true,
    component: () => <InternaTransferHistory />
  },
  {
    path: WebUrl._URL_WALLET_TRANSFER_HISTORY,
    exact: true,
    title: 'Wallet Transfer History',
    authRequired: true,
    component: () => <WalletTransferHistory />
  },
  {
    path: WebUrl._URL_COMMISSION_HISTORY,
    exact: true,
    title: 'Commission History',
    authRequired: true,
    component: () => <CommissionHistory />
  },
  {
    path: WebUrl._URL_ROLLOVER_HISTORY,
    exact: true,
    title: 'Rollover History',
    authRequired: true,
    component: () => <RolloverHistory />
  },
  {
    path: WebUrl._URL_GAME_HISTORY,
    exact: true,
    title: 'Game History',
    authRequired: true,
    component: () => <GameHistory />
  },
  {
    path: WebUrl._URL_GAME_RESULT,
    exact: true,
    title: 'Result',
    authRequired: true,
    component: () => <PoolResult />
  },
  {
    path: WebUrl._URL_MANAGE_REFERRAL_CODES,
    exact: true,
    title: 'Referral Codes',
    authRequired: true,
    component: () => <ManageReferralCode />
  },
  {
    path: WebUrl._URL_REFERRAL_CODE_DETAIL,
    exact: true,
    title: 'Referral Code Detail',
    authRequired: true,
    component: () => <ReferralCodeDetail />
  },
  {
    path: WebUrl._URL_FORGET_PIN,
    exact: true,
    title: 'Forget PIN',
    authRequired: true,
    component: () => <ForgetPin />
  },
  {
    path: WebUrl._URL_GAME_LOBBY,
    exact: true,
    title: 'Game Lobby',
    authRequired: true,
    component: () => <GameLobby />
  },
  {
    path: WebUrl._URL_KPI,
    exact: true,
    title: 'KPI',
    authRequired: true,
    component: () => <Kpi />
  },
  {
    path: WebUrl._URL_SHAREHOLDER,
    exact: true,
    title: 'Shareholder',
    authRequired: true,
    component: () => <Shareholder />
  },
  {
    path: WebUrl._URL_SHAREHOLDER_DETAIL,
    exact: true,
    title: 'Shareholder Detail',
    authRequired: true,
    component: () => <ShareholderDetails />
  },
  {
    path: WebUrl._URL_EDIT_SHAREHOLDER,
    exact: true,
    title: 'Edit Shareholder',
    authRequired: true,
    component: () => <EditShareholder />
  },
  {
    path: WebUrl._URL_NEWS,
    exact: true,
    title: 'News',
    authRequired: true,
    component: () => <News />
  },
  {
    path: WebUrl._URL_NEWS_DETAILS,
    exact: true,
    title: 'Detail',
    authRequired: true,
    component: () => <NewsDetails />
  },
  {
    path: WebUrl._URL_MAINTENANCE,
    exact: true,
    title: 'Maintenance',
    component: () => <Maintenance />
  },
  {
    path: WebUrl._URL_GAME_DETAILS,
    exact: true,
    title: 'Game Detail',
    authRequired: true,
    component: () => <GameDetails />
  },
  {
    path: WebUrl._URL_CONTACT_US,
    exact: true,
    title: 'Contact Us',
    authRequired: false,
    component: () => <ContactUs />
  },
  {
    path: WebUrl._URL_LIVE_CHAT,
    exact: true,
    title: 'Live Chat',
    component: () => <LiveChatPage />
  },
  {
    path: WebUrl._URL_CLAIM_RECORD,
    exact: true,
    title: 'Claim History',
    component: () => <ClaimRecord />
  },
  {
    path: WebUrl._URL_DOWNLINE_GROUP_DETAIL,
    exact: true,
    title: 'Group Details',
    component: () => <DownlineGroupDetail />
  },
  {
    path: WebUrl._URL_UNCLAIM_COMMISSION_HISTORY,
    exact: true,
    title: 'Unclaim Commission History',
    component: () => <UnclaimCommissionHistory />
  },
  {
    path: WebUrl._URL_TNC,
    exact: true,
    title: 'Terms & Conditions',
    component: () => <TnC />
  },
  {
    path: WebUrl._URL_VIDEO_TUTORIAL,
    exact: true,
    title: 'Video Tutorial',
    authRequired: true,
    component: () => <VideoTutorial />
  },
  {
    path: WebUrl._URL_WITHDRAWAL,
    exact: true,
    title: 'Withdrawal',
    authRequired: true,
    component: () => <Withdrawal />
  },
  {
    path: WebUrl._URL_DEPOSIT,
    exact: true,
    title: 'Deposit',
    authRequired: true,
    component: () => <Deposit />
  },
  {
    path: WebUrl._URL_SUMMARY_DOWNLINE_SALES,
    exact: true,
    title: 'Summary Downline',
    authRequired: true,
    component: () => <SummaryDownlineSales />
  },
  {
    path: WebUrl._URL_DOWNLINE_PRODUCT_RATE_DETAIL,
    exact: true,
    title: 'Downline Rates',
    authRequired: true,
    component: () => <DownlineProductRateDetail />
  },
  {
    path: WebUrl._URL_REWARDS,
    exact: true,
    title: 'Rewards',
    authRequired: true,
    component: () => <Rewards />
  },
  {
    path: WebUrl._URL_REWARDS_HISTORY,
    exact: true,
    title: 'Rewards History',
    authRequired: true,
    component: () => <RewardsHistory />
  },
  {
    path: WebUrl._URL_REWARDS_DETAIL,
    exact: true,
    title: 'Rewards Detail',
    authRequired: true,
    component: () => <RewardsDetail />
  },
  {
    path: WebUrl._URL_GAME_PAGE,
    exact: true,
    title: 'Game',
    authRequired: true,
    component: () => <GamePage />
  },
  {
    path: WebUrl._URL_CAMO_HOMEPAGE,
    exact: true,
    title: 'Home Page',
    authRequired: true,
    component: () => <CamoHomePage />
  },
  {
    path: WebUrl._URL_CAMO_REGISTER,
    exact: true,
    title: 'Register',
    authRequired: true,
    component: () => <CamoRegister />
  },
  {
    path: WebUrl._URL_REWARDS_TNC,
    exact: true,
    title: 'Rewards Terms & Conditions',
    authRequired: true,
    component: () => <RewardsTnC />
  },
  {
    path: WebUrl._URL_ABOUT,
    exact: true,
    title: "About",
    authRequired: false,
    component: () => <About />,
  },
  {
    path: WebUrl._URL_HELP_CENTRE,
    exact: true,
    title: "Help Centre",
    authRequired: false,
    component: () => <HelpCentre />,
  },
  {
    path: WebUrl._URL_HELP_CENTRE_CATEGORY,
    exact: true,
    title: "Help Centre Category",
    authRequired: false,
    component: () => <HelpCentreCategory />,
  },
  {
    path: WebUrl._URL_HELP_CENTRE_SUB_CATEGORY,
    exact: true,
    title: "Help Centre Sub Category",
    authRequired: false,
    component: () => <HelpCentreSubCategory />,
  },
  {
    path: WebUrl._URL_HELP_CENTRE_FAQ,
    exact: true,
    title: "Help Centre Faq",
    authRequired: false,
    component: () => <HelpCentreFaq />,
  },
  {
    path: WebUrl._URL_TRANSFER,
    exact: true,
    title: "Transfer",
    authRequired: false,
    component: () => <Transfer />,
  },
  {
    path: WebUrl._URL_PROVIDER_GAME_LOBBY,
    exact: true,
    title: "Provider Lobby",
    authRequired: false,
    component: () => <ProviderGameLobby />,
  },
  {
    path: WebUrl._URL_MAIN_WALLET,
    exact: true,
    title: "Main Wallet",
    authRequired: false,
    component: () => <MainWallet />,
  },
  {
    path: WebUrl._URL_RELOAD_HISTORY,
    exact: true,
    title: "Reload History",
    authRequired: false,
    component: () => <ReloadHistory />,
  },
  {
    path: WebUrl._URL_NOTIFICATION,
    exact: true,
    title: "Notification",
    authRequired: false,
    component: () => <Notification />,
  },
  {
    path: WebUrl._URL_NOTIFICATION_DETAIL,
    exact: true,
    title: "Notification Detail",
    authRequired: false,
    component: () => <NotificationDetails />,
  },
  {
    path: WebUrl._URL_REFERRAL,
    exact: true,
    title: "Referral",
    authRequired: false,
    component: () => <Referral />,
  },
  {
    path: WebUrl._URL_CREATE_REFERRAL,
    exact: true,
    title: "Create Referral",
    authRequired: false,
    component: () => <CreateReferral />,
  },
  {
    path: WebUrl._URL_EDIT_REFERRAL,
    exact: true,
    title: "Edit Referral",
    authRequired: false,
    component: () => <EditReferral />,
  },
  {
    path: WebUrl._URL_REFERRAL_GROUP,
    exact: true,
    title: "Referral Group",
    authRequired: false,
    component: () => <ReferralGroup />,
  },
  {
    path: WebUrl._URL_REFERRAL_GROUP_DETAILS,
    exact: true,
    title: "Referral Group Details",
    authRequired: false,
    component: () => <ReferralGroupDetails />,
  },
  {
    path: WebUrl._URL_COMMISSION,
    exact: true,
    title: "Commission",
    authRequired: false,
    component: () => <Commission />,
  },
  {
    path: WebUrl._URL_LOYALTY_REDEMPTION,
    exact: true,
    title: "Loyalty Redemption",
    authRequired: true,
    component: () => <LoyaltyRedemption />,
  },
  {
    path: WebUrl._URL_LOYALTY_REDEMPTION_HISTORY,
    exact: true,
    title: "Loyalty Redemption History",
    authRequired: true,
    component: () => <LoyaltyRedemptionHistory />,
  },
  {
    path: WebUrl._URL_LOYALTY_REDEMPTION_TNC,
    exact: true,
    title: "Loyalty Redemption TnC",
    authRequired: true,
    component: () => <LoyaltyRedemptionTnC />,
  },
  {
    path: WebUrl._URL_UNCLAIM_LOYALTY_POINTS,
    exact: true,
    title: "Unclaim Loyalty Points",
    authRequired: true,
    component: () => <UnclaimLoyaltyPoints />,
  },
  {
    path: WebUrl._URL_LOYALTY_POINTS_HISTORY,
    exact: true,
    title: "Loyalty Points History",
    authRequired: true,
    component: () => <LoyaltyPointsHistory />,
  },
  {
    path: WebUrl._URL_PRODUCT_COMM_RATE,
    exact: true,
    title: "Product Comm Rate",
    authRequired: true,
    component: () => <ProductCommRate />,
  },
  {
    path: WebUrl._URL_SHAREHOLDER_DOWNLINE,
    exact: true,
    title: "Downline",
    authRequired: true,
    component: () => <ShareholderDownline />,
  },
  {
    path: WebUrl._URL_SHAREHOLDER_CLAIM,
    exact: true,
    title: "Claim",
    authRequired: true,
    component: () => <ShareholderClaim />,
  },
  {
    path: WebUrl._URL_LOYALTY_VOUCHER_DETAILS,
    exact: true,
    title: "Voucher Details",
    authRequired: true,
    component: () => <LoyaltyVoucherDetails />,
  },
  {
    path: WebUrl._URL_DEPOSIT_TUTORIAL,
    exact: true,
    title: "Deposit Tutorial",
    authRequired: true,
    component: () => <DepositTutorial />,
  },
  {
    path: WebUrl._URL_DAILY_CHECK_IN,
    exact: true,
    title: "Daily Check In",
    authRequired: true,
    component: () => <DailyCheckIn />,
  },
  {
    path: WebUrl._URL_PROMOTION,
    exact: true,
    title: "Promotion Lobby",
    component: () => <Promotion />,
  },
  {
    path: WebUrl._URL_PROMOTION_DETAIL,
    exact: true,
    title: "Promotion Detail",
    component: () => <PromotionDetail />,
  },
  {
    path: WebUrl._URL_MEMBER_PRODUCT_RATE_DETAIL,
    exact: true,
    title: "Product Rate %",
    component: () => <MemberProductRateDetail />,
  }
];