import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { formatNumber } from '../../util/Util';

const BottomSuccessModal = props => {
    const {title, amount, deductedAmount, bonus, date, isOpen, transactionNumber ,setIsOpen, ...rest} = props;
    const { t } = useTranslation();

    useEffect(() => {
    }, []);

    return (
        <Modal fade={false} contentClassName={"modal-brand modal-bottom modal-reload-success" + (props.contentClassName ? " " + props.contentClassName : "")} isOpen={isOpen} toggle={() => { setIsOpen(false) }} centered>
            <ModalBody>
                <div className="text-center">
                    <strong className="reload-title text-yellow mb-3 d-block">{t(title)}</strong>
                    {(typeof (deductedAmount) !== "undefined" && deductedAmount !== 0 && !isNaN(bonus) && bonus !== 0) ? <div className="text-brand text-bo">{t("REQUESTED", { amount: formatNumber(amount, 2) })}</div> : <div className="text-brand text-bo">{t("PAID_DEPOSIT", {amount: formatNumber(amount, 2) })}</div>}
                    {(typeof (deductedAmount) !== "undefined" && deductedAmount !== 0 && !isNaN(bonus) && bonus !== 0) && <div className="text-brand text-bo">{t("PAID", { amount: formatNumber(deductedAmount, 2), bonus: formatNumber(bonus, 2) })}</div>}
                </div>
                <table className="table text-brand table-reload-detail">
                    <tr>
                        <th>{t("DATE")}</th>
                        <th style={{ textAlign: "right" }}>{date}</th>
                    </tr>
                    <tr>
                        <th>{t("REFERENCE_NUMBER")}</th>
                        <th style={{ textAlign: "right" }}>{transactionNumber}</th>
                    </tr>
                </table>
                <button className="btn btn-block btn-brand-gradient" onClick={() => { setIsOpen(false) }}>{t("DONE")}</button>
            </ModalBody>
        </Modal>
    );
};

export default BottomSuccessModal;