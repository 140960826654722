import { WebUrl } from "./../util/Constant.js";


//4D result//
const _POOL_NAME = {
  mg: "Magnum",
  kd: "Da Ma Cai 1 + 3D",
  tt: "Sport TOTO",
  sg: "Singapore Pool ",
  sr: "Sarawak 88 ",
  sn: "SANDAKAN",
  sb: "Sabah 88",
  gd: "GRAND DRAGON",
};

const _TITLE_COLOR = {
  mg: "#000000",
  kd: "#FFFFFF",
  tt: "#FFFFFF",
  sg: "#FFFFFF",
  sr: "#FFFFFF",
  sn: "#000000",
  sb: "#FFFFFF",
  gd: "#FFFFFF",
};

const _COLOR = {
  mg: "#FFC100",
  kd: "#000080",
  tt: "#AD0006", //4D AND 5D AND 6D
  sg: "#0378BB",
  sr: "#008000",
  sn: "#F8C100",
  sb: "#3333FF", // currently no color
  gd: "#A1030E", //4D AND 5D AND 6D
};

const _POOL_IMAGE = {
  mg: require("../assets/img/v3/4dresult-icon/magnum_logo.svg"),
  kd: require("../assets/img/v3/4dresult-icon/damacai_logo.svg"),
  tt: require("../assets/img/v3/4dresult-icon/toto_logo.svg"),
  sg: require("../assets/img/v3/4dresult-icon/singapore_logo.svg"),
  sr: require("../assets/img/v3/4dresult-icon/bigcash_logo.svg"),
  sn: require("../assets/img/v3/4dresult-icon/stc_logo.svg"),
  sb: require("../assets/img/v3/4dresult-icon/sabah_logo.svg"),
  gd: require("../assets/img/v3/4dresult-icon/gd_logo.svg"),
};

const _SECTION_TYPE = {
  mg: "section-3",
  kd: "section-3",
  tt: "section-3", //4D AND 5D AND 6D
  sg: "section-3",
  sr: "section-3",
  sn: "section-3",
  sb: "section-3", // currently no color
  gd: "section-3", //4D AND 5D AND 6D
};

const _IS_5D_6D = {
  tt: "section-2",
  gd: "section-1",
};

//End 4D result//

export {
  _POOL_NAME,
  _TITLE_COLOR,
  _COLOR,
  _POOL_IMAGE,
  _SECTION_TYPE,
  _IS_5D_6D,
};
