import React from 'react';
import { PageSettings } from './config/page-settings.js';
import Content from './components/content/content.jsx';
import Footer from './components/footer/footer.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from "react-redux";
import { updateLoginUser, resetLogin, performLogout, checkIsLoggedIn } from "./redux/AuthAction.js";
import classNames from 'classnames';
import { isObjectEmpty, stringIsNullOrEmpty } from './util/Util.js';
import { withTranslation } from 'react-i18next';

LoadingOverlay.propTypes = undefined
class App extends React.Component {
    constructor(props) {
        super(props);

        this.handleSetPageContent = (value) => {
            this.setState(state => ({
                pageContent: value
            }));
        }
        this.handleSetPageContentClass = (value) => {
            this.setState(state => ({
                pageContentClass: value
            }));
        }
        this.handleSetPageContentFullHeight = (value) => {
            this.setState(state => ({
                pageContentFullHeight: value
            }));
        }
        this.handleSetPageContentFullWidth = (value) => {
            this.setState(state => ({
                pageContentFullWidth: value
            }));
        }
        this.handleSetPageContentInverseMode = (value) => {
            this.setState(state => ({
                pageContentInverseMode: value
            }));
        }
        this.handleSetPageFooter = (value) => {
            this.setState(state => ({
                pageFooter: value
            }));
        }
        this.handleSetBodyWhiteBg = (value) => {
            if (value === true) {
                document.body.classList.add('bg-white');
            } else {
                document.body.classList.remove('bg-white');
            }
        }

        this.state = {
            pageContent: true,
            pageContentClass: '',
            pageContentFullHeight: false,
            pageContentFullWidth: true,
            pageContentInverseMode: false,
            handleSetPageContent: this.handleSetPageContent,
            handleSetPageContentClass: this.handleSetPageContentClass,
            handleSetPageContentFullHeight: this.handleSetPageContentFullHeight,
            handleSetPageContentFullWidth: this.handleSetPageContentFullWidth,
            handleSetPageContentInverseMode: this.handleSetPageContentInverseMode,

            pageFooter: false,
            handleSetPageFooter: this.handleSetPageFooter,
            error: null,
            errorInfo: null,

            handleSetBodyWhiteBg: this.handleSetBodyWhiteBg,
            handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,

            interval1: null,
            // countryCode: '', // comment first, use for detect ip redirect to country
            // receivedCountry: '' // comment first, use for detect ip redirect to country
        };
    }

    /// <summary>
    /// Author: -
    /// </summary>

    // comment first, use for detect ip redirect to country
    // componentWillMount() {
    //     axios.get('https://ipapi.co/json/').then((response) => {
    //         let data = response.data;

    //         this.setState({
    //             countryCode: data.country_code,
    //         });

    //         if (window.ReactNativeWebView) {
    //             window.ReactNativeWebView.postMessage(
    //               JSON.stringify({
    //                 action: "countryLocation",
    //                 countryCode: data.country_code
    //               })
    //             );
    //         }
    //     }).catch((error) => {
    //         console.log(error);
    //     });
    // }
    
    componentDidMount() {
        clearInterval(this.state.interval1);
        this.state.interval1 = null;
        this.props.checkIsLoggedIn();
        this.state.interval1 = setInterval(() => this.props.checkIsLoggedIn(), 30000);

        // comment first, use for detect ip redirect to country
        // document.addEventListener("message", message => {
        // this.setState({
        //     receivedCountry: message.data,
        // });
    };

    componentWillUnmount() {
        clearInterval(this.state.interval1);
        this.state.interval1 = null;
    }

    /// <summary>
    /// Author: -
    /// </summary>
    componentDidUpdate(prevProps) {
        if (this.props.languageState.languageId != prevProps.languageState.languageId) {
            this.props.i18n.changeLanguage(this.props.languageState.languageId);
        }

        if (this.state.interval1 == null) {
            this.state.interval1 = setInterval(() => this.props.checkIsLoggedIn(), 30000);
        }
    }

    /// <summary>
    /// Author: -
    /// </summary>
    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        clearInterval(this.state.interval1);
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div className="error" style={{ "padding": "20px" }}>
                    <img src={require("./assets/img/error.png")} style={{ "maxWidth": "100px", "objectFit": "contain", "marginLeft": "auto", "marginRight": "auto" }} />
                    <h3>Something went wrong, please contact admin for help</h3>
                    <div className="m-b-30">
                        {window.navigator.userAgent}
                    </div>
                </div>
            );
        }
        else {
            return  (
                <PageSettings.Provider value={this.state}>
                    {
                        !window.ReactNativeWebView ?
                            <LoadingOverlay
                                active={this.props.appState.isBusy || !this.props.authState.loginStateInitialized}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        zIndex: '10000',
                                        position: 'fixed',
                                    })
                                }}
                                className={classNames('loading-overlay')}
                            >
                                <img src={require('../src/assets/img/loading_dice.gif')} style={{ width: "85px", height: "85px" }} />
                                <p style={{ fontSize: "20px" }}>{this.props.appState.message + '...'}</p>
                            </LoadingOverlay>
                        :
                        <></>
                    }
                    
                    {
                        !isObjectEmpty(this.props.appState.alert) && <div className="sweet-alert-container"><SweetAlert {...this.props.appState.alert}>
                            {!stringIsNullOrEmpty(this.props.appState.alert.content) && (this.props.appState.alert.html ? this.props.appState.alert.content : this.props.appState.alert.content.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br /></span>
                            }))}
                        </SweetAlert>
                        </div>
                    }
                    {
                        this.props.authState.loginStateInitialized &&
                        <div className={classNames(
                            'fade page-sidebar-fixed show page-container app-v3', {
                            'page-content-full-height': this.state.pageContentFullHeight,
                        })}>
                            <div onClick={() => {
                                if (this.state.pageSidebarToggled) {
                                    this.toggleMobileSidebar();
                                }
                            }}>
                                {this.state.pageContent && (<Content />)}
                                {this.state.pageFooter && (<Footer />)}
                            </div>
                        </div>
                    }
                </PageSettings.Provider>
            )
        }
    }
}

const mapStateToProps = state => ({
    ...state,
});

export default
    connect(
        mapStateToProps,
        {
            updateLoginUser: updateLoginUser,
            resetLogin: resetLogin,
            performLogout: performLogout,
            checkIsLoggedIn: checkIsLoggedIn
        }
    )(withTranslation()(App));