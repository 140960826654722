import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage, setBusy, setIdle } from "../../redux/AppAction.js";
import { ApiKey, ApiUrl, WebUrl, Role } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import { stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat, numberWithCurrencyFormat2 } from "../../util/Util";
import StyledSelect from "../../components/custom/StyledSelect";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { useForm, Controller } from "react-hook-form";
import { Nav, NavItem, NavLink } from "reactstrap";
import { createMultiPartFormBody } from "../../util/Util";
import SweetAlert from 'react-bootstrap-sweetalert';
import Icon from '@mdi/react';
import { mdiHistory } from '@mdi/js';
import { updateUserData } from "../../redux/AuthAction";

const ShareholderClaim = (props) => {
  let _history = useHistory();
  const { t, i18n } = useTranslation();
  var _dispatch = useDispatch();
  var _location = useLocation();
  var activeTheme = useSelector(state => state.authState.userData.selectedTheme);
  let _userData = useSelector(state => state.authState.userData);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [monthOptions, setMonthOptions] = useState([]);
  const [data, setData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState('');

  const { handleSubmit, control, watch, setValue } = useForm();
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment());
  const [queryProduct, setQueryProduct] = useState('');
  const [queryStatus, setQueryStatus] = useState('');
  const [searchTrigger, setSearchTrigger] = useState(Date.now());
  const [activeDropdown, setActiveDropdown] = useState(-1);
  const [hideSummary, setHideSummary] = useState(false);
    const rightMenuList = [
        {
            title: "CLAIM_HISTORY",
            url: WebUrl._URL_CLAIM_RECORD,
            from: window.location.pathname
        }
    ];

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    _dispatch(updateUserData());
    init();
  }, []);

  useEffect(() => {
    if (_location.state && _location.state.hideSummary) {
      setHideSummary(_location.state.hideSummary);
    }
  }, [_location.state]);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedMonth)) {
      setSelectedIndex(data.bonusDetail.findIndex(bd => bd.month == selectedMonth));
    }
  }, [selectedMonth]);

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_SHAREHOLDER_UNCLAIM);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setData(responseJson[ApiKey._API_DATA_KEY]);
    }
    else {
      _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
    }
    _dispatch(setIdle());
  }


  /// <summary>
  /// Author : -
  /// </summary>
  function preSubmit() {
    setShowConfirmation(true);
  }

  /// <summary>
  /// Author: -
  /// </summary>
  async function submitForm() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(ApiUrl._API_CLAIM_SHAREHOLDER, createMultiPartFormBody({
    }));

    _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    _dispatch(setIdle());

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          _dispatch(updateUserData());
      init();
    }

  }

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_SHAREHOLDER + "?GAzobE73Vse4nr8WpufA=" + _userData.hideSummary}
        title={t("CLAIM")}
        rightMenuList={rightMenuList}
        hasRightMenu={true}
      />
      {showConfirmation && (
        <SweetAlert
          custom={true}
          customIcon={require("../../assets/img/sweetalert_warning.png")}
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          cancelBtnCssClass="custom-cancel-btn"
          confirmBtnCssClass="custom-btn"
          confirmBtnStyle={{ boxShadow: "0px 5px 30px #3369ffb3" }}
          title={t("CONFIRM_CLAIM")}
          onConfirm={() => {
            setShowConfirmation(false);
            handleSubmit(submitForm)();
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        >
        </SweetAlert>
      )}
      <div className="claim-commission-container">
        <div className="first">
          <div className="custom-icon-box">
            <img src={require("../../assets/img/v3/commission.svg")} alt="commission" />
          </div>
          <div>
            GEM {data["totalclaimable"] ? numberWithCurrencyFormat2(parseFloat(data["totalclaimable"]), 2, true) : "0.00"}
          </div>
        </div>
        <button
          type="submit"
          className="second"
          disabled={_userData.blockClaim === true || data["totalclaimable"] === 0}
          onClick={() => (_userData.blockClaim === false && data["totalclaimable"] != 0 && numberWithCurrencyFormat2(parseFloat(data["totalclaimable"]), 2, true) != "0.00") && preSubmit()}>
          {t("CLAIM")}
        </button>
      </div>
      <div className="hr-right mt-3 mb-3"></div>
      {data["data"] && data["data"].length > 0 && <h4 className="mb-4">{t("CURRENT_SUMMARY")}</h4>}
      <div>
        {data["data"] && data["data"].length > 0 &&
          data["data"].map((row, i) => {
            return (
              <>
                <div
                  className={"box-list-with-shadow claim-commission-item " + (activeDropdown == i ? 'active' : '')}
                  onClick={() => {
                    if (activeDropdown === -1) {
                      setActiveDropdown(i);
                    }
                    else if (activeDropdown === i) {
                      setActiveDropdown(-1);
                    }
                    else {
                      setActiveDropdown(i);
                    }
                  }}
                >
                  <div className="comm-item">
                    <div className="first">
                                <div className="comm-amount">
                        {t("CLAIMABLE")} : {numberWithCurrencyFormat(parseFloat(row["netProfit"]), 2, true)}
                      </div>
                      <div className="date-text">
                        {moment(row["date"], "DD/MM/YYYY").format("YYYY-MM-DD")}
                      </div>
                    </div>
                    {/* {row["claimed"] ? (
                                        <div className="green-label-box">
                                            {t("CLAIMED")}
                                        </div>
                                        ) : ( */}
                    <div className="red-label-box">
                      {t("UNCLAIMED")}
                    </div>
                    {/* )} */}
                  </div>
                  <div className={"comm-details " + (activeDropdown == i ? 'd-block' : '')}>
                    <div>
                      {t("DOWNLINE_SALES")} - {numberWithCurrencyFormat(parseFloat(row["downlineSales"]), 2, true)}
                    </div>
                    <div>
                      {t("OWN_SALES")} - {numberWithCurrencyFormat(parseFloat(row["ownSales"]), 2, true)}
                    </div>
                  </div>
                </div>
              </>
            )
          })
        }
        {data["data"] && data["data"].length == 0 || data["data"] == null &&
          <div className="no-data">
            {t("NO_UNCLAIMED_DATA")}
          </div>
        }
      </div>
      {/* <div id={activeTheme === 0 ? "shareholder-claim" : "shareholder-claim-modern"} className="container mt-3" style={{ paddingBottom: "10px" }}>
                <Link to={{ pathname: WebUrl._URL_CLAIM_RECORD, state: { hideSummary: props.hideSummary } }}>
                    <div className="d-flex justify-content-end align-items-center claim-history-btn" style={{ color: "#002C68", marginRight: "8px", fontWeight: "bold", marginBottom: "10px" }}>
                        <div style={{ marginRight: "5px", fontSize: "12px" }}> {t("CLAIM_HISTORY")} </div>
                        <Icon path={mdiHistory}
                            title="User Profile"
                            size={1}
                            color={activeTheme === 0 ? "#002C68" : "white"} />
                    </div>
                </Link>
                <div className="card kpi-header-widget mb-2">
                    <div className="card-body">
                        <div className="user-detail">
                            <div>{data["username"]}</div>
                            <div>{data["phone"]}</div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>

                <div className="claim-title title-md text-yellow">{t("CURRENT_BALANCE")}</div>
                <div className="downline-stat-wrapper">
                    <div>
                        <div className="card-brand text-center text-bold">GEM { data["totalclaimable"] ? numberWithCurrencyFormat(parseFloat(data["totalclaimable"]), 3, true) : "0.000"}</div>
                    </div>
                    <div>
                        <button type="submit" className="btn mt-2 btn-block btn-brand-gradient mb-4" disabled={data["totalclaimable"] == 0} onClick={() => data["totalclaimable"] != 0 && preSubmit()}>{t("CLAIM")}</button>
                    </div>
                </div>
                <div className="claim-title title-md text-yellow">{t("CURRENT_SUMMARY")}</div>
                {data["data"] && data["data"].length > 0 && 
                    <div className="card card-package">
                        <div className="card-body p-0">
                            <div className="card-table table-responsive">
                                <table className="table table-kpi-summary">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "left" }}>{t("DATE")}</th>
                                            <th style={{ textAlign: "right" }}>{t("MY_SALES")}</th>
                                            <th style={{ textAlign: "right" }}>{t("DOWNLINE_SALES")}</th>
                                            <th style={{ textAlign: "right" }}>{t("CLAIMABLE")}</th>
                                            <th style={{ textAlign: "right" }}>{t("STATUS")}</th>
                                        </tr>
                                    </thead>
                                <tbody>
                                    {
                                        data["data"].map((row, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td style={{ textAlign: "left" }}>{row["date"]}</td>
                                                    <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(row["ownSales"]), 3, true)}</td>
                                                    <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(row["downlineSales"]), 3, true)}</td>
                                                    <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(row["netProfit"]), 3, true)}</td>
                                                    <td style={{ textAlign: "right" }}><span className="badge badge-no" style={{ fontSize: "8px" }}>{t("UNCLAIMED")}</span></td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                } */}
      {/* { data["data"] && data["data"].length == 0 &&
                    <div className="card card-package">
                        <div className="card-body p-0">
                            <div className="card-table table-responsive">
                                <table className="table table-kpi-summary">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "left" }}>{t("DATE")}</th>
                                            <th style={{ textAlign: "right" }}>{t("MY_SALES")}</th>
                                            <th style={{ textAlign: "right" }}>{t("DOWNLINE_SALES")}</th>
                                            <th style={{ textAlign: "right" }}>{t("CLAIMABLE")}</th>
                                            <th style={{ textAlign: "right" }}>{t("STATUS")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                            <td colSpan="5" style={{ textAlign: "left" }}>{t("NO_UNCLAIMED_DATA")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                } */}
      {/* </div> */}
    </>
  );
};

export default ShareholderClaim;